import React, { useState, useEffect } from "react";

const GridImage = (props) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await props.runPredictions(props.cell);
        setValue(result);
        updatePrediction(result);
        // console.log("result", result);
      } catch (error) {
        console.error("Error fetching prediction:", error);
        setValue("Error"); // Set a default value or handle the error case
      } finally {
      }
    };

    fetchData();
  }, [props.cell]);

  function updatePrediction(newValue) {
    const { id, rowIndex, columnIndex } = props;
    props.setSearchDict((prev) => {
      const copy = { ...prev };
      if (copy[id] && copy[id].itemsPredictArray) {
        // Create a new array to trigger a reference change
        copy[id].itemsPredictArray = [...copy[id].itemsPredictArray];
        copy[id].itemsPredictArray[rowIndex][columnIndex] = newValue;
      }
      return copy;
    });
  }

  //   console.log("value", value, "loading", loading);

  return (
    <div className="flex h-full w-full cursor-pointer">
      <img
        className="object-cover"
        src={props.cell}
        alt={value.replace(/\.\w+$/, "")}
        onClick={() => {
          props.setSelectedItem(value.replace(/\.\w+$/, ""));
        }}
      />
    </div>
  );
};

export default GridImage;
