import React, { useState, useEffect } from "react";
import PriceChart from "./PriceChart";
import { isLocalHost } from "../API/utils";

const ItemDetail = (props) => {
  const [itemDetail, setItemDetail] = useState({
    avg24hPrice: "",
    icon: null,
    historicalPrices: [],
  });

  useEffect(() => {
    // console.log("props", props);
    if (!props.searchDict[props.id].itemsDict) {
      // console.log("itemsDict not found");
      return;
    }

    if (Object.keys(props.searchDict[props.id].itemsDict).length === 0) {
      // console.log("itemsDict is empty");
      return;
    }
    if (!props.selectedItem) {
      isLocalHost() && console.log("selectedItem is empty");
      return;
    }
    if (!props.searchDict[props.id].itemsDict[props.selectedItem]) {
      isLocalHost() &&
        console.log(
          "selectedItem not found in itemsDict. selectedItem :",
          props.selectedItem
        );
      isLocalHost() &&
        console.log("itemsDict", props.searchDict[props.id].itemsDict);
      return;
    }

    setItemDetail(props.searchDict[props.id].itemsDict[props.selectedItem]);
    // console.log("itemDetail", itemDetail);
  }, [props.id, props.searchDict, props.selectedItem]);

  // The rest of your component logic
  // console.log("itemDetail", itemDetail);
  return (
    <div className="flex flex-col items-center bg-background h-full border border-secondary font-bender">
      <div className="flex flex-row p-5">
        <img
          className="w-24 h-24 object-cover mb-4"
          src={itemDetail.icon || "public/Empty_1x1.png"}
          alt={props.selectedItem}
        />
        <div className="flex flex-col justify-center ml-4">
          <h1 className="text-2xl font-bold text-primary">
            {props.selectedItem}
          </h1>
          <h2 className="text-lg my-2 text-secondary">
            ₽ {itemDetail.avg24hPrice?.toLocaleString()}
          </h2>
        </div>
      </div>

      <PriceChart data={itemDetail.historicalPrices} />
    </div>
  );
};

export default ItemDetail;
