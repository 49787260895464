import React, { useEffect } from "react";

const ImagecellGenerator = (props) => {
  const originalImage = props.searchDict[props.id].originalImage;
  // const canvasRef = useRef(null);
  const init = (img, xSpaces, ySpaces) => {
    // const canvas = canvasRef.current;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    let imgW = img.width;
    let imgH = img.height;
    const spaceWidth = 1;

    const totalSpacesWidth = (xSpaces - 1) * spaceWidth;
    const pieceWidth = (imgW - totalSpacesWidth) / xSpaces;
    const totalSpacesHeight = (ySpaces - 1) * spaceWidth;
    const pieceHeight = (imgH - totalSpacesHeight) / ySpaces;

    canvas.width = imgW;
    canvas.height = imgH;

    let currentX = 0;
    let currentY = 0;
    const tempImageArray = [];

    for (let j = 0; j < ySpaces; j++) {
      const tempImageRow = [];

      for (let i = 0; i < xSpaces; i++) {
        // Create a temporary canvas for the current cell
        const tempCanvas = document.createElement("canvas");
        const tempCtx = tempCanvas.getContext("2d");

        // Set the temporary canvas size to match the piece size
        tempCanvas.width = pieceWidth;
        tempCanvas.height = pieceHeight;
        //draw the preview
        ctx.drawImage(
          img,
          currentX,
          currentY,
          pieceWidth,
          pieceHeight,
          currentX, // Destination X on the temporary canvas
          currentY, // Destination Y on the temporary canvas
          pieceWidth,
          pieceHeight
        );
        // Draw the piece on the temporary canvas for saving
        tempCtx.drawImage(
          img,
          currentX,
          currentY,
          pieceWidth,
          pieceHeight,
          0, // Destination X on the temporary canvas
          0, // Destination Y on the temporary canvas
          pieceWidth,
          pieceHeight
        );

        // Get the data URL from the temporary canvas
        const cellDataURL = tempCanvas.toDataURL("image/png");

        // Save the data URL in the tempImageRow array
        tempImageRow.push(cellDataURL);

        // Update the current Y coordinate for the next row
        // console.log("i", i, "j", j);
        currentX += pieceWidth + spaceWidth;
      }

      // console.log("pushing image row");
      tempImageArray.push(tempImageRow);
      currentX = 0;
      currentY += pieceHeight + spaceWidth;
    }
    const updatedDict = {
      ...props.searchDict,
      [props.id]: {
        ...props.searchDict[props.id],
        itemsImageArray: tempImageArray,
        itemsPredictArray: tempImageArray.map((row) =>
          row.map(() => ({ value: null }))
        ),
        itemsDict: {},
      },
    };
    props.setSearchDict((prev) => ({ ...prev, ...updatedDict }));
  };

  useEffect(() => {
    let horizontalCells;
    let verticalCells;
    if (originalImage.width < 100 || originalImage.height < 100) {
      horizontalCells = 1;
      verticalCells = 1;
    } else {
      horizontalCells = Math.ceil(originalImage.width / 64);
      verticalCells = Math.ceil(originalImage.height / 64);
    }

    init(originalImage, horizontalCells, verticalCells);
  }, [originalImage]);

  return (
    <div>
      {/* <button onClick={() => downloadCells()}>download</button> */}
      {/* <canvas className="w-full" ref={canvasRef} /> */}
    </div>
  );
};

export default ImagecellGenerator;
