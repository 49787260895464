import React, { useEffect, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import classes from "./constants/model_classes";
import "./App.css";
import Search from "./components/Search";
import ImageInput from "./components/ImageInput";

function App() {
  const [net, setNet] = useState();
  const [searchDict, setSearchDict] = useState([]);
  // const { searchDict } = useSelector((state) => state.search);

  async function runPredictions(imageSource) {
    const img = new Image();
    img.src = imageSource;
    await img.onload;
    const inputTensor = tf.browser
      .fromPixels(img)
      .resizeNearestNeighbor([63, 64]) // Adjust dimensions
      .expandDims();
    // console.log("Input Tensor:", inputTensor);
    // inputTensor.print();
    // //see the image being predicted
    // const canvas = document.createElement("canvas");
    // canvas.width = inputTensor.shape.width;
    // canvas.height = inputTensor.shape.height;
    // await tf.browser.toPixels(inputTensor, canvas);
    // setPredictingImage(canvas.toDataURL());

    const prediction = net.predict(inputTensor);
    const maxIndex = tf.argMax(prediction, 1).dataSync()[0];
    const rawPrediction = classes[maxIndex];
    // console.log("Raw Prediction:", rawPrediction);
    // console.log("Predicted Class Name:", predictedClassName);
    const itemNameRegex = /^([^_]*)/;
    const predictedItemName = rawPrediction.match(itemNameRegex)[0];

    //getting the dimensions & index of the item
    // const dimensionsRegex = /_(\d+x\d+)_(\d+,\d+)$/;
    // const match = rawPrediction.match(dimensionsRegex);

    // if (match) {
    //   const firstNumber = match[1]; // #x#
    //   const secondNumber = match[2]; // (#,#)

    //   console.log("First Number:", firstNumber);
    //   console.log("Second Number:", secondNumber);
    // } else {
    //   console.log("No match found");
    // }
    // console.log("Predicted Item Name:", predictedItemName);
    return predictedItemName;
  }

  useEffect(() => {
    const loadModel = async () => {
      console.log("Loading Model...");
      const model = await tf.loadLayersModel("./model/model.json");
      setNet(model);
      console.log("Loaded model");
      // console.log(img_array);
      // const prediction = model.predict(tf.tensor(img_array));
      // const maxIndex = tf.argMax(prediction, 1).dataSync()[0];

      // console.log("Prediction:", maxIndex);
      // const rawPrediction = classes[maxIndex];
      // console.log("This image most likely belongs to : ", rawPrediction);
    };

    loadModel();
  }, []);

  return (
    <div className="flex flex-col">
      {!net ? (
        <div className="flex items-center flex-col justify-center w-screen h-screen bg-background opacity-80">
          <div className="flex flex-row items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="20"
              height="20"
              className="mx-2"
            >
              <image href="/favicon.svg" width="100%" height="100%" />
            </svg>
            <p className="text-primary font-bender font-bold">
              {" "}
              Tarkov-Scanner{" "}
            </p>
          </div>
          <div className="flex justify-center items-center space-x-1 text-sm text-primary">
            <svg
              fill="none"
              className="w-6 h-6 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>

            <div className="flex items-center text-secondary font-bender">
              Loading ...{" "}
            </div>
          </div>
        </div>
      ) : (
        <div className="p-5">
          <ImageInput
            searchDict={searchDict}
            setSearchDict={setSearchDict}
            readyStatus={net ? true : false}
          />
          <div className="flex flex-col-reverse space-y-3">
            {Object.keys(searchDict).map((searchId) => (
              <div key={`search-${searchId}`} className="my-3">
                <Search
                  id={searchId}
                  runPredictions={runPredictions}
                  searchDict={searchDict}
                  setSearchDict={setSearchDict}
                />
              </div>
            ))}
          </div>
          {Object.keys(searchDict).length === 0 &&
          searchDict.constructor === Object ? null : (
            <button>Summary</button>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
