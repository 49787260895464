var classes = [
  "#FireKlean gun lube_1x1_(1,1)",
  "'Negotiation' room key_1x1_(1,1)",
  "'Obdolbos 2' cocktail injector_1x1_(1,1)",
  "'Obdolbos' cocktail injector_1x1_(1,1)",
  "'Zarya' stun grenade_1x1_(1,1)",
  "12.7x55mm PS12 ammo pack (10 pcs)_1x1_(1,1)",
  "12.7x55mm PS12A ammo pack (10 pcs)_1x1_(1,1)",
  "12.7x55mm PS12B (10 pcs)_1x1_(1,1)",
  "12@70 'Poleva-3' slug ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 'Poleva-6u' slug ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 5.25mm buckshot ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 6.5mm Express buckshot ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 7mm buckshot ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 8.5mm Magnum buckshot ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 AP-20 ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 Copper Sabot Premier HP slug ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 Dual Sabot slug ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 FTX Custom Lite slug ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 Grizzly 40 slug ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 Piranha ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 RIP ammo pack (5 pcs)_1x1_(1,1)",
  "12@70 SuperFormance HP slug ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 flechette ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 lead slug ammo pack (25 pcs)_1x1_(1,1)",
  "12@70 makeshift .50 BMG slug ammo pack (25 pcs)_1x1_(1,1)",
  "20@70 'Poleva-3' slug ammo pack (25 pcs)_1x1_(1,1)",
  "20@70 'Poleva-6u' slug ammo pack (25 pcs)_1x1_(1,1)",
  "20@70 5.6mm buckshot ammo pack (25 pcs)_1x1_(1,1)",
  "20@70 6.2mm buckshot ammo pack (25 pcs)_1x1_(1,1)",
  "20@70 7.3mm buckshot ammo pack (25 pcs)_1x1_(1,1)",
  "20@70 7.5mm buckshot ammo pack (25 pcs)_1x1_(1,1)",
  "20@70 Devastator slug ammo pack (25 pcs)_1x1_(1,1)",
  "20@70 Star slug ammo pack (25 pcs)_1x1_(1,1)",
  "23x75mm Barrikada slug ammo pack (5 pcs)_2x1_(1,1)",
  "23x75mm Barrikada slug ammo pack (5 pcs)_2x1_(2,1)",
  "23x75mm Shrapnel-10 buckshot ammo pack (5 pcs)_2x1_(1,1)",
  "23x75mm Shrapnel-10 buckshot ammo pack (5 pcs)_2x1_(2,1)",
  "23x75mm Shrapnel-25 buckshot ammo pack (5 pcs)_2x1_(1,1)",
  "23x75mm Shrapnel-25 buckshot ammo pack (5 pcs)_2x1_(2,1)",
  "23x75mm Zvezda flashbang round ammo pack (5 pcs)_2x1_(1,1)",
  "23x75mm Zvezda flashbang round ammo pack (5 pcs)_2x1_(2,1)",
  "3-(b-TG) stimulant injector_1x1_(1,1)",
  "4.6x30mm AP SX ammo pack (40 pcs)_1x1_(1,1)",
  "4.6x30mm Action SX ammo pack (40 pcs)_1x1_(1,1)",
  "4.6x30mm FMJ SX ammo pack (40 pcs)_1x1_(1,1)",
  "4.6x30mm JSP SX ammo pack (40 pcs)_1x1_(1,1)",
  "4.6x30mm Subsonic SX ammo pack (40 pcs)_1x1_(1,1)",
  "40mm VOG-25 grenade_1x1_(1,1)",
  "42 Signature Blend English Tea_1x1_(1,1)",
  "5.45x39mm 7N40 ammo pack (120 pcs)_2x1_(1,1)",
  "5.45x39mm 7N40 ammo pack (120 pcs)_2x1_(2,1)",
  "5.45x39mm 7N40 ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm BP gs ammo pack (120 pcs)_2x1_(1,1)",
  "5.45x39mm BP gs ammo pack (120 pcs)_2x1_(2,1)",
  "5.45x39mm BP gs ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm BS gs ammo pack (120 pcs)_2x1_(1,1)",
  "5.45x39mm BS gs ammo pack (120 pcs)_2x1_(2,1)",
  "5.45x39mm BS gs ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm BT gs ammo pack (120 pcs)_2x1_(1,1)",
  "5.45x39mm BT gs ammo pack (120 pcs)_2x1_(2,1)",
  "5.45x39mm BT gs ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm FMJ ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm HP ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm PP gs ammo pack (120 pcs)_2x1_(1,1)",
  "5.45x39mm PP gs ammo pack (120 pcs)_2x1_(2,1)",
  "5.45x39mm PP gs ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm PPBS gs 'Igolnik' ammo pack (120 pcs)_2x1_(1,1)",
  "5.45x39mm PPBS gs 'Igolnik' ammo pack (120 pcs)_2x1_(2,1)",
  "5.45x39mm PPBS gs 'Igolnik' ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm PRS gs ammo pack (120 pcs)_2x1_(1,1)",
  "5.45x39mm PRS gs ammo pack (120 pcs)_2x1_(2,1)",
  "5.45x39mm PRS gs ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm PS gs ammo pack (120 pcs)_2x1_(1,1)",
  "5.45x39mm PS gs ammo pack (120 pcs)_2x1_(2,1)",
  "5.45x39mm PS gs ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm SP ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm T gs ammo pack (120 pcs)_2x1_(1,1)",
  "5.45x39mm T gs ammo pack (120 pcs)_2x1_(2,1)",
  "5.45x39mm T gs ammo pack (30 pcs)_1x1_(1,1)",
  "5.45x39mm US gs ammo pack (120 pcs)_2x1_(1,1)",
  "5.45x39mm US gs ammo pack (120 pcs)_2x1_(2,1)",
  "5.45x39mm US gs ammo pack (30 pcs)_1x1_(1,1)",
  "5.56x45 Colt AR-15 STANAG 30-round magazine_1x2_(1,1)",
  "5.56x45 Colt AR-15 STANAG 30-round magazine_1x2_(1,2)",
  "5.56x45 HK 30 STANAG polymer 30-round magazine_1x2_(1,1)",
  "5.56x45 HK 30 STANAG polymer 30-round magazine_1x2_(1,2)",
  "5.56x45 HK PM Gen.2 STANAG 30-round magazine_1x2_(1,1)",
  "5.56x45 HK PM Gen.2 STANAG 30-round magazine_1x2_(1,2)",
  "5.56x45 HK Steel Maritime STANAG 30-round magazine_1x2_(1,1)",
  "5.56x45 HK Steel Maritime STANAG 30-round magazine_1x2_(1,2)",
  "5.56x45 Magpul PMAG 10 GEN M3 STANAG 10-round magazine_1x1_(1,1)",
  "5.56x45 Magpul PMAG 20 GEN M3 STANAG 20-round magazine_1x1_(1,1)",
  "5.56x45 Magpul PMAG 30 GEN M3 STANAG 30-round magazine (FDE)_1x2_(1,1)",
  "5.56x45 Magpul PMAG 30 GEN M3 STANAG 30-round magazine (FDE)_1x2_(1,2)",
  "5.56x45 Magpul PMAG 30 GEN M3 STANAG 30-round magazine_1x2_(1,1)",
  "5.56x45 Magpul PMAG 30 GEN M3 STANAG 30-round magazine_1x2_(1,2)",
  "5.56x45 Magpul PMAG 30 GEN M3 W STANAG 30-round magazine (FDE)_1x2_(1,1)",
  "5.56x45 Magpul PMAG 30 GEN M3 W STANAG 30-round magazine (FDE)_1x2_(1,2)",
  "5.56x45 Magpul PMAG 30 GEN M3 W STANAG 30-round magazine_1x2_(1,1)",
  "5.56x45 Magpul PMAG 30 GEN M3 W STANAG 30-round magazine_1x2_(1,2)",
  "5.56x45 Magpul PMAG 40 GEN M3 STANAG 40-round magazine (FDE)_1x3_(1,1)",
  "5.56x45 Magpul PMAG 40 GEN M3 STANAG 40-round magazine (FDE)_1x3_(1,2)",
  "5.56x45 Magpul PMAG 40 GEN M3 STANAG 40-round magazine (FDE)_1x3_(1,3)",
  "5.56x45 Magpul PMAG 40 GEN M3 STANAG 40-round magazine_1x3_(1,1)",
  "5.56x45 Magpul PMAG 40 GEN M3 STANAG 40-round magazine_1x3_(1,2)",
  "5.56x45 Magpul PMAG 40 GEN M3 STANAG 40-round magazine_1x3_(1,3)",
  "5.56x45 Magpul PMAG D-60 STANAG 60-round magazine_1x2_(1,1)",
  "5.56x45 Magpul PMAG D-60 STANAG 60-round magazine_1x2_(1,2)",
  "5.56x45 SureFire MAG5-100 STANAG 100-round magazine_1x3_(1,1)",
  "5.56x45 SureFire MAG5-100 STANAG 100-round magazine_1x3_(1,2)",
  "5.56x45 SureFire MAG5-100 STANAG 100-round magazine_1x3_(1,3)",
  "5.56x45 SureFire MAG5-60 STANAG 60-round magazine_1x2_(1,1)",
  "5.56x45 SureFire MAG5-60 STANAG 60-round magazine_1x2_(1,2)",
  "5.56x45 TROY BattleMag STANAG 30-round magazine_1x2_(1,1)",
  "5.56x45 TROY BattleMag STANAG 30-round magazine_1x2_(1,2)",
  "5.56x45mm FMJ ammo pack (100 pcs)_2x1_(1,1)",
  "5.56x45mm FMJ ammo pack (100 pcs)_2x1_(2,1)",
  "5.56x45mm FMJ ammo pack (50 pcs)_1x1_(1,1)",
  "5.56x45mm HP ammo pack (100 pcs)_2x1_(1,1)",
  "5.56x45mm HP ammo pack (100 pcs)_2x1_(2,1)",
  "5.56x45mm HP ammo pack (50 pcs)_1x1_(1,1)",
  "5.56x45mm M855 ammo pack (100 pcs)_2x1_(1,1)",
  "5.56x45mm M855 ammo pack (100 pcs)_2x1_(2,1)",
  "5.56x45mm M855 ammo pack (50 pcs)_1x1_(1,1)",
  "5.56x45mm M855A1 ammo pack (100 pcs)_2x1_(1,1)",
  "5.56x45mm M855A1 ammo pack (100 pcs)_2x1_(2,1)",
  "5.56x45mm M855A1 ammo pack (50 pcs)_1x1_(1,1)",
  "5.56x45mm M856 ammo pack (100 pcs)_2x1_(1,1)",
  "5.56x45mm M856 ammo pack (100 pcs)_2x1_(2,1)",
  "5.56x45mm M856 ammo pack (50 pcs)_1x1_(1,1)",
  "5.56x45mm M856A1 ammo pack (100 pcs)_2x1_(1,1)",
  "5.56x45mm M856A1 ammo pack (100 pcs)_2x1_(2,1)",
  "5.56x45mm M856A1 ammo pack (50 pcs)_1x1_(1,1)",
  "5.56x45mm M995 ammo pack (100 pcs)_2x1_(1,1)",
  "5.56x45mm M995 ammo pack (100 pcs)_2x1_(2,1)",
  "5.56x45mm M995 ammo pack (50 pcs)_1x1_(1,1)",
  "5.56x45mm MK 255 Mod 0 (RRLP) ammo pack (100 pcs)_2x1_(1,1)",
  "5.56x45mm MK 255 Mod 0 (RRLP) ammo pack (100 pcs)_2x1_(2,1)",
  "5.56x45mm MK 255 Mod 0 (RRLP) ammo pack (50 pcs)_1x1_(1,1)",
  "5.56x45mm MK 318 Mod 0 (SOST) ammo pack (100 pcs)_2x1_(1,1)",
  "5.56x45mm MK 318 Mod 0 (SOST) ammo pack (100 pcs)_2x1_(2,1)",
  "5.56x45mm MK 318 Mod 0 (SOST) ammo pack (50 pcs)_1x1_(1,1)",
  "5.56x45mm SSA AP ammo pack (100 pcs)_2x1_(1,1)",
  "5.56x45mm SSA AP ammo pack (100 pcs)_2x1_(2,1)",
  "5.56x45mm SSA AP ammo pack (50 pcs)_1x1_(1,1)",
  "5.56x45mm Warmageddon ammo pack (20 pcs)_1x1_(1,1)",
  "5.7x28mm L191 ammo pack (50 pcs)_1x1_(1,1)",
  "5.7x28mm R37.F ammo pack (50 pcs)_1x1_(1,1)",
  "5.7x28mm R37.X ammo pack (50 pcs)_1x1_(1,1)",
  "5.7x28mm SB193 ammo pack (50 pcs)_1x1_(1,1)",
  "5.7x28mm SS190 ammo pack (50 pcs)_1x1_(1,1)",
  "5.7x28mm SS197SR ammo pack (50 pcs)_1x1_(1,1)",
  "5.7x28mm SS198LF ammo pack (50 pcs)_1x1_(1,1)",
  "6-STEN-140-M military battery_4x2_(1,1)",
  "6-STEN-140-M military battery_4x2_(1,2)",
  "6-STEN-140-M military battery_4x2_(2,1)",
  "6-STEN-140-M military battery_4x2_(2,2)",
  "6-STEN-140-M military battery_4x2_(3,1)",
  "6-STEN-140-M military battery_4x2_(3,2)",
  "6-STEN-140-M military battery_4x2_(4,1)",
  "6-STEN-140-M military battery_4x2_(4,2)",
  "7.62x25mm TT AKBS ammo pack (25 pcs)_1x1_(1,1)",
  "7.62x25mm TT P gl ammo pack (25 pcs)_1x1_(1,1)",
  "7.62x25mm TT PT gzh ammo pack (25 pcs)_1x1_(1,1)",
  "7.62x25mm TT Pst gzh ammo pack (25 pcs)_1x1_(1,1)",
  "7.62x39mm BP gzh ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x39mm FMJ ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x39mm HP ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x39mm MAI AP ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x39mm PP gzh ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x39mm PS gzh ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x39mm SP ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x39mm T-45M1 gzh ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x39mm US gzh ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x51mm BCP FMJ ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x51mm M61 ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x51mm M62 Tracer ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x51mm M80 ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x51mm M993 ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x51mm TCW SP ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x51mm Ultra Nosler ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x54mm R BS ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x54mm R BT gzh ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x54mm R FMJ ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x54mm R HP BT ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x54mm R LPS gzh ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x54mm R PS gzh ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x54mm R SNB gzh ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x54mm R SP BT gzh ammo pack (20 pcs)_1x1_(1,1)",
  "7.62x54mm R T-46M gzh ammo pack (20 pcs)_1x1_(1,1)",
  "9A-91 9x39 20-round magazine_1x2_(1,1)",
  "9A-91 9x39 20-round magazine_1x2_(1,2)",
  "9A-91 9x39 barrel_2x1_(1,1)",
  "9A-91 9x39 barrel_2x1_(2,1)",
  "9A-91 9x39 sound suppressor_2x1_(1,1)",
  "9A-91 9x39 sound suppressor_2x1_(2,1)",
  "9A-91 handguard retainer_1x1_(1,1)",
  "9A-91 handguard_1x1_(1,1)",
  "9A-91 pistol grip_1x1_(1,1)",
  "9A-91 top-folding stock_2x1_(1,1)",
  "9A-91 top-folding stock_2x1_(2,1)",
  "9x18mm PM BZhT gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM BZhT gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM P gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM P gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM PBM gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM PBM gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM PPT gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM PPT gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM PPe gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM PPe gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM PRS gs ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM PRS gs ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM PS gs PPO ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM PS gs PPO ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM PSO gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM PSO gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM PSV ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM PSV ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM Pst gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM Pst gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM RG028 gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM RG028 gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM SP7 gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM SP7 gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PM SP8 gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PM SP8 gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x18mm PMM PstM gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x18mm PMM PstM gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x19mm AP 6.3 ammo pack (50 pcs)_1x1_(1,1)",
  "9x19mm FMJ M882 ammo pack (30 pcs)_1x1_(1,1)",
  "9x19mm Green Tracer ammo pack (50 pcs)_1x1_(1,1)",
  "9x19mm Luger CCI ammo pack (50 pcs)_1x1_(1,1)",
  "9x19mm PBP ammo pack (50 pcs)_1x1_(1,1)",
  "9x19mm PSO gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x19mm Pst gzh ammo pack (16 pcs)_1x1_(1,1)",
  "9x19mm Pst gzh ammo pack (50 pcs)_1x1_(1,1)",
  "9x19mm QuakeMaker ammo pack (50 pcs)_1x1_(1,1)",
  "9x19mm RIP ammo pack (20 pcs)_1x1_(1,1)",
  "9x21mm 7N42 ammo pack (30 pcs)_1x1_(1,1)",
  "9x21mm 7U4 ammo pack (30 pcs)_1x1_(1,1)",
  "9x21mm BT ammo pack (30 pcs)_1x1_(1,1)",
  "9x21mm P gzh ammo pack (30 pcs)_1x1_(1,1)",
  "9x21mm PE gzh ammo pack (30 pcs)_1x1_(1,1)",
  "9x21mm PS gzh ammo pack (30 pcs)_1x1_(1,1)",
  "9x39 SR3M.130 30-round magazine_1x2_(1,1)",
  "9x39 SR3M.130 30-round magazine_1x2_(1,2)",
  "9x39mm BP ammo pack (20 pcs)_1x1_(1,1)",
  "9x39mm BP gs ammo pack (8 pcs)_1x1_(1,1)",
  "9x39mm FMJ ammo pack (20 pcs)_1x1_(1,1)",
  "9x39mm PAB-9 gs ammo pack (20 pcs)_1x1_(1,1)",
  "9x39mm SP-5 gs ammo pack (20 pcs)_1x1_(1,1)",
  "9x39mm SP-6 gs ammo pack (20 pcs)_1x1_(1,1)",
  "9x39mm SPP gs ammo pack (20 pcs)_1x1_(1,1)",
  "9x39mm SPP gs ammo pack (8 pcs)_1x1_(1,1)",
  "A3 Tactical MVF001 KeyMod vertical foregrip (Black)_1x1_(1,1)",
  "AA Battery_1x1_(1,1)",
  "AAC 762-SDN-6 multi-caliber sound suppressor_2x1_(1,1)",
  "AAC 762-SDN-6 multi-caliber sound suppressor_2x1_(2,1)",
  "AAC Illusion 9 9x19 sound suppressor_2x1_(1,1)",
  "AAC Illusion 9 9x19 sound suppressor_2x1_(2,1)",
  "AAC SCAR-SD 51T 7.62x51 flash hider_1x1_(1,1)",
  "AFAK tactical individual first aid kit_1x1_(1,1)",
  "AHF1-M stimulant injector_1x1_(1,1)",
  "AI .338 LM Tactical Muzzle Brake _1x1_(1,1)",
  "AI .338 LM Tactical Sound Moderator_2x1_(1,1)",
  "AI .338 LM Tactical Sound Moderator_2x1_(2,1)",
  "AI AX-50 34mm scope mount_2x1_(1,1)",
  "AI AX-50 34mm scope mount_2x1_(2,1)",
  "AI AXMC .338 LM 10-round magazine_1x2_(1,1)",
  "AI AXMC .338 LM 10-round magazine_1x2_(1,2)",
  "AI AXMC .338 LM 28 inch barrel_4x1_(1,1)",
  "AI AXMC .338 LM 28 inch barrel_4x1_(2,1)",
  "AI AXMC .338 LM 28 inch barrel_4x1_(3,1)",
  "AI AXMC .338 LM 28 inch barrel_4x1_(4,1)",
  "AI AXMC .338 LM bolt assembly_1x1_(1,1)",
  "AI AXMC .338 LM chassis_2x1_(1,1)",
  "AI AXMC .338 LM chassis_2x1_(2,1)",
  "AI AXMC AT X Top Rail _3x1_(1,1)",
  "AI AXMC AT X Top Rail _3x1_(2,1)",
  "AI AXMC AT X Top Rail _3x1_(3,1)",
  "AI AXMC AX KeySlot 16 inch handguard_3x1_(1,1)",
  "AI AXMC AX KeySlot 16 inch handguard_3x1_(2,1)",
  "AI AXMC AX KeySlot 16 inch handguard_3x1_(3,1)",
  "AI AXMC AX buttstock_2x1_(1,1)",
  "AI AXMC AX buttstock_2x1_(2,1)",
  "AI AXMC Adapter Kit medium length rail_1x1_(1,1)",
  "AI AXMC Adapter Kit short length rail_1x1_(1,1)",
  "AI AXMC GTAC AR-type pistol grip adapter_1x1_(1,1)",
  "AI AXMC padded handguard grip_1x1_(1,1)",
  "AI AXMC pistol grip_1x1_(1,1)",
  "AI AXMC thread protection cap_1x1_(1,1)",
  "AI AXMC upper receiver_2x1_(1,1)",
  "AI AXMC upper receiver_2x1_(2,1)",
  "AI-2 medkit_1x1_(1,1)",
  "AK 100-series metal skeletonized stock_2x1_(1,1)",
  "AK 100-series metal skeletonized stock_2x1_(2,1)",
  "AK 100-series polymer handguard_1x1_(1,1)",
  "AK 5.45 Design Aggressor handguard_1x1_(1,1)",
  "AK 7.62x39 30-round magazine (issued '55 or later)_1x2_(1,1)",
  "AK 7.62x39 30-round magazine (issued '55 or later)_1x2_(1,2)",
  "AK 7.62x39 6L10 30-round magazine_1x2_(1,1)",
  "AK 7.62x39 6L10 30-round magazine_1x2_(1,2)",
  "AK 7.62x39 FAB Defense Ultimag 30R 30-round magazine_1x2_(1,1)",
  "AK 7.62x39 FAB Defense Ultimag 30R 30-round magazine_1x2_(1,2)",
  "AK 7.62x39 Magpul PMAG 20 GEN M3 20-round magazine_1x2_(1,1)",
  "AK 7.62x39 Magpul PMAG 20 GEN M3 20-round magazine_1x2_(1,2)",
  "AK 7.62x39 Magpul PMAG 30 GEN M3 30-round magazine (Banana)_1x2_(1,1)",
  "AK 7.62x39 Magpul PMAG 30 GEN M3 30-round magazine (Banana)_1x2_(1,2)",
  "AK 7.62x39 Magpul PMAG 30 GEN M3 30-round magazine_1x2_(1,1)",
  "AK 7.62x39 Magpul PMAG 30 GEN M3 30-round magazine_1x2_(1,2)",
  "AK 7.62x39 Molot Arms 40-round magazine (6P2.Sb-11)_1x3_(1,1)",
  "AK 7.62x39 Molot Arms 40-round magazine (6P2.Sb-11)_1x3_(1,2)",
  "AK 7.62x39 Molot Arms 40-round magazine (6P2.Sb-11)_1x3_(1,3)",
  "AK 7.62x39 Molot Arms 75-round drum magazine_2x2_(1,1)",
  "AK 7.62x39 Molot Arms 75-round drum magazine_2x2_(1,2)",
  "AK 7.62x39 Molot Arms 75-round drum magazine_2x2_(2,1)",
  "AK 7.62x39 Molot Arms 75-round drum magazine_2x2_(2,2)",
  "AK 7.62x39 ProMag AK-A-16 73-round drum magazine_2x2_(1,1)",
  "AK 7.62x39 ProMag AK-A-16 73-round drum magazine_2x2_(1,2)",
  "AK 7.62x39 ProMag AK-A-16 73-round drum magazine_2x2_(2,1)",
  "AK 7.62x39 ProMag AK-A-16 73-round drum magazine_2x2_(2,2)",
  "AK 7.62x39 Taktika Tula muzzle adapter_1x1_(1,1)",
  "AK 7.62x39 US Palm 'AK30' 30-round magazine (Black)_1x2_(1,1)",
  "AK 7.62x39 US Palm 'AK30' 30-round magazine (Black)_1x2_(1,2)",
  "AK 7.62x39 US Palm 'AK30' 30-round magazine (FDE)_1x2_(1,1)",
  "AK 7.62x39 US Palm 'AK30' 30-round magazine (FDE)_1x2_(1,2)",
  "AK 7.62x39 X Products X-47 50-round drum magazine_1x2_(1,1)",
  "AK 7.62x39 X Products X-47 50-round drum magazine_1x2_(1,2)",
  "AK 7.62x39 aluminium 10-round magazine_1x2_(1,1)",
  "AK 7.62x39 aluminium 10-round magazine_1x2_(1,2)",
  "AK 7.62x39 bakelite 40-round magazine_1x3_(1,1)",
  "AK 7.62x39 bakelite 40-round magazine_1x3_(1,2)",
  "AK 7.62x39 bakelite 40-round magazine_1x3_(1,3)",
  "AK 7.62x39 ribbed metal 10-round magazine_1x1_(1,1)",
  "AK AKademia Bastion dust cover_2x1_(1,1)",
  "AK AKademia Bastion dust cover_2x1_(2,1)",
  "AK AKdemia Dominator handguard_1x1_(1,1)",
  "AK Aeroknox Scorpius pistol grip_1x1_(1,1)",
  "AK Alfa Arms ASPID handguard_2x1_(1,1)",
  "AK Alfa Arms ASPID handguard_2x1_(2,1)",
  "AK CAA RS47 handguard_1x1_(1,1)",
  "AK CG101 AR-type pistol grip adapter_1x1_(1,1)",
  "AK CNC Guns OV GP handguard_2x1_(1,1)",
  "AK CNC Guns OV GP handguard_2x1_(2,1)",
  "AK CNC Warrior 5.56x45 muzzle device adapter_1x1_(1,1)",
  "AK CSS knurled charging handle_1x1_(1,1)",
  "AK Custom Arms AGS-74 PRO + Sniper Kit pistol grip_1x1_(1,1)",
  "AK Custom Arms ST-EF 74 skeleton stock extension_1x1_(1,1)",
  "AK FAB Defense AGR-47 pistol grip_1x1_(1,1)",
  "AK FAB Defense PDC dust cover_2x1_(1,1)",
  "AK FAB Defense PDC dust cover_2x1_(2,1)",
  "AK GP-25 accessory kit recoil pad_1x1_(1,1)",
  "AK Hexagon DTKP MK.2 7.62x39 sound suppressor_2x1_(1,1)",
  "AK Hexagon DTKP MK.2 7.62x39 sound suppressor_2x1_(2,1)",
  "AK Hexagon Reactor 5.45x39 muzzle brake_1x1_(1,1)",
  "AK Hexagon tubular handguard (Anodized Red)_2x1_(1,1)",
  "AK Hexagon tubular handguard (Anodized Red)_2x1_(2,1)",
  "AK Hexagon tubular handguard_2x1_(1,1)",
  "AK Hexagon tubular handguard_2x1_(2,1)",
  "AK KGB MG-47 pistol grip (Anodized Red)_1x1_(1,1)",
  "AK KGB MG-47 pistol grip_1x1_(1,1)",
  "AK Kiba Arms VDM CS gas tube_1x1_(1,1)",
  "AK Krebs Custom UFM KeyMod handguard_2x1_(1,1)",
  "AK Krebs Custom UFM KeyMod handguard_2x1_(2,1)",
  "AK Lantac Drakon 7.62x39 muzzle brake_1x1_(1,1)",
  "AK Magpul MOE AKM handguard (Black)_1x1_(1,1)",
  "AK Magpul MOE AKM handguard (FDE)_1x1_(1,1)",
  "AK Magpul MOE AKM handguard (Olive Drab)_1x1_(1,1)",
  "AK Magpul MOE AKM handguard (Plum)_1x1_(1,1)",
  "AK Magpul MOE AKM handguard (Stealth Grey)_1x1_(1,1)",
  "AK Magpul MOE pistol grip (Black)_1x1_(1,1)",
  "AK Magpul Zhukov-U handguard (Black)_2x1_(1,1)",
  "AK Magpul Zhukov-U handguard (Black)_2x1_(2,1)",
  "AK Magpul Zhukov-U handguard (FDE)_2x1_(1,1)",
  "AK Magpul Zhukov-U handguard (FDE)_2x1_(2,1)",
  "AK Magpul Zhukov-U handguard (Plum)_2x1_(1,1)",
  "AK Magpul Zhukov-U handguard (Plum)_2x1_(2,1)",
  "AK Mission First Tactical ENGAGE AK47 pistol grip_1x1_(1,1)",
  "AK PUFGUN SG-M2 pistol grip_1x1_(1,1)",
  "AK RD Enhanced V2 Rear Sight_1x1_(1,1)",
  "AK SRVV 7.62x39 muzzle brake-compensator_1x1_(1,1)",
  "AK Spikes Tactical Dynacomp 7.62x39 muzzle brake-compensator_1x1_(1,1)",
  "AK Strike Industries Enhanced Pistol Grip (Black)_1x1_(1,1)",
  "AK Strike Industries Enhanced Pistol Grip (FDE)_1x1_(1,1)",
  "AK Strike Industries TRAX 1 handguard_1x1_(1,1)",
  "AK Strike Industries TRAX 2 handguard extension_1x1_(1,1)",
  "AK TAPCO SAW-Style pistol grip (Black)_1x1_(1,1)",
  "AK TAPCO SAW-Style pistol grip (FDE)_1x1_(1,1)",
  "AK TDI AKM-L handguard (Anodized Bronze)_1x1_(1,1)",
  "AK TDI AKM-L handguard (Anodized Red)_1x1_(1,1)",
  "AK TDI AKM-L handguard_1x1_(1,1)",
  "AK TDI X47 tactical handguard rail system_2x1_(1,1)",
  "AK TDI X47 tactical handguard rail system_2x1_(2,1)",
  "AK TROY Full Length Rail handguard & gas tube combo_2x1_(1,1)",
  "AK TROY Full Length Rail handguard & gas tube combo_2x1_(2,1)",
  "AK TWS Dog Leg Rail dust cover_2x1_(1,1)",
  "AK TWS Dog Leg Rail dust cover_2x1_(2,1)",
  "AK Taktika Tula TT01 rear sight rail_1x1_(1,1)",
  "AK TangoDown Battle Grip pistol grip (FDE)_1x1_(1,1)",
  "AK TangoDown Battle Grip pistol grip_1x1_(1,1)",
  "AK US Palm pistol grip_1x1_(1,1)",
  "AK UltiMAK M1-B gas tube & handguard_1x1_(1,1)",
  "AK Vector VR-05T 7.62x39 muzzle brake_1x1_(1,1)",
  "AK Venom Tactical Antidote 7.62x39 muzzle brake-compensator_1x1_(1,1)",
  "AK Vezhlivyy Strelok VS-24 + VS-33c handguard & gas tube combo (White)_2x1_(1,1)",
  "AK Vezhlivyy Strelok VS-24 + VS-33c handguard & gas tube combo (White)_2x1_(2,1)",
  "AK Vezhlivyy Strelok VS-24 + VS-33c handguard & gas tube combo_2x1_(1,1)",
  "AK Vezhlivyy Strelok VS-24 + VS-33c handguard & gas tube combo_2x1_(2,1)",
  "AK Vltor CMRD KeyMod handguard_2x1_(1,1)",
  "AK Vltor CMRD KeyMod handguard_2x1_(2,1)",
  "AK Zenit B-10 Handguard_1x1_(1,1)",
  "AK Zenit B-10M handguard with B-19 upper mount_1x1_(1,1)",
  "AK Zenit B-12 Mount_1x1_(1,1)",
  "AK Zenit B-30 handguard with B-31S upper handguard rail_2x1_(1,1)",
  "AK Zenit B-30 handguard with B-31S upper handguard rail_2x1_(2,1)",
  "AK Zenit B-33 dust cover_2x1_(1,1)",
  "AK Zenit B-33 dust cover_2x1_(2,1)",
  "AK Zenit DTK-1 7.62x39@5.45x39 muzzle brake-compensator_1x1_(1,1)",
  "AK Zenit PT-1 'Klassika' stock_2x1_(1,1)",
  "AK Zenit PT-1 'Klassika' stock_2x1_(2,1)",
  "AK Zenit PT-3 'Klassika' stock_2x1_(1,1)",
  "AK Zenit PT-3 'Klassika' stock_2x1_(2,1)",
  "AK Zenit RK-3 pistol grip_1x1_(1,1)",
  "AK Zenit RP-1 charging handle_1x1_(1,1)",
  "AK bakelite pistol grip (6P1 Sb.8V)_1x1_(1,1)",
  "AK bakelite pistol grip (6P4 Sb.9)_1x1_(1,1)",
  "AK bakelite pistol grip_1x1_(1,1)",
  "AK polymer pistol grip (6P1 Sb.8)_1x1_(1,1)",
  "AK-101 5.56x45 6L29 30-round magazine_1x2_(1,1)",
  "AK-101 5.56x45 6L29 30-round magazine_1x2_(1,2)",
  "AK-101 5.56x45 muzzle brake-compensator_1x1_(1,1)",
  "AK-102 5.56x45 muzzle brake-compensator (6P44 0-20)_1x1_(1,1)",
  "AK-103 7.62x39 30-round magazine_1x2_(1,1)",
  "AK-103 7.62x39 30-round magazine_1x2_(1,2)",
  "AK-103 7.62x39 muzzle brake-compensator_1x1_(1,1)",
  "AK-104 7.62x39 muzzle brake-compensator (6P46 0-20)_1x1_(1,1)",
  "AK-105 5.45x39 muzzle brake-compensator (6P44 0-20)_1x1_(1,1)",
  "AK-105 rear sight (6P44 Sb.1-30)_1x1_(1,1)",
  "AK-12 5.45x39 30-round magazine_1x2_(1,1)",
  "AK-12 5.45x39 30-round magazine_1x2_(1,2)",
  "AK-12 5.45x39 early model 30-round magazine_1x2_(1,1)",
  "AK-12 5.45x39 early model 30-round magazine_1x2_(1,2)",
  "AK-12 5.45x39 muzzle brake_1x1_(1,1)",
  "AK-12 5.45x39 sound suppressor_2x1_(1,1)",
  "AK-12 5.45x39 sound suppressor_2x1_(2,1)",
  "AK-12 buffer tube_2x1_(1,1)",
  "AK-12 buffer tube_2x1_(2,1)",
  "AK-12 dust cover_2x1_(1,1)",
  "AK-12 dust cover_2x1_(2,1)",
  "AK-12 gas tube_1x1_(1,1)",
  "AK-12 handguard_1x1_(1,1)",
  "AK-12 pistol grip_1x1_(1,1)",
  "AK-12 rear sight base_1x1_(1,1)",
  "AK-12 rear sight_1x1_(1,1)",
  "AK-12 stock_2x1_(1,1)",
  "AK-12 stock_2x1_(2,1)",
  "AK-545 SAG Mk. 2.1 gas tube_1x1_(1,1)",
  "AK-545 SAG Mk.3 handguard_2x1_(1,1)",
  "AK-545 SAG Mk.3 handguard_2x1_(2,1)",
  "AK-545 SAG buffer tube_2x1_(1,1)",
  "AK-545 SAG buffer tube_2x1_(2,1)",
  "AK-545 SAG railed dust cover_1x1_(1,1)",
  "AK-545 SAG rear sight_1x1_(1,1)",
  "AK-74 'Plum' polymer handguard (6P20 Sb.9)_1x1_(1,1)",
  "AK-74 'Plum' polymer stock (6P20 Sb.7)_2x1_(1,1)",
  "AK-74 'Plum' polymer stock (6P20 Sb.7)_2x1_(2,1)",
  "AK-74 'Saiga 545' 5.45x39 10-round magazine_1x1_(1,1)",
  "AK-74 'Saiga 545' 5.45x39 20-round magazine_1x2_(1,1)",
  "AK-74 'Saiga 545' 5.45x39 20-round magazine_1x2_(1,2)",
  "AK-74 5.45x39 6L18 45-round magazine_1x3_(1,1)",
  "AK-74 5.45x39 6L18 45-round magazine_1x3_(1,2)",
  "AK-74 5.45x39 6L18 45-round magazine_1x3_(1,3)",
  "AK-74 5.45x39 6L20 30-round magazine_1x2_(1,1)",
  "AK-74 5.45x39 6L20 30-round magazine_1x2_(1,2)",
  "AK-74 5.45x39 6L23 'Plum' 30-round magazine_1x2_(1,1)",
  "AK-74 5.45x39 6L23 'Plum' 30-round magazine_1x2_(1,2)",
  "AK-74 5.45x39 6L23 30-round magazine_1x2_(1,1)",
  "AK-74 5.45x39 6L23 30-round magazine_1x2_(1,2)",
  "AK-74 5.45x39 6L26 45-round magazine_1x3_(1,1)",
  "AK-74 5.45x39 6L26 45-round magazine_1x3_(1,2)",
  "AK-74 5.45x39 6L26 45-round magazine_1x3_(1,3)",
  "AK-74 5.45x39 6L31 60-round magazine_1x2_(1,1)",
  "AK-74 5.45x39 6L31 60-round magazine_1x2_(1,2)",
  "AK-74 5.45x39 Magpul PMAG 30 GEN M3 30-round magazine_1x2_(1,1)",
  "AK-74 5.45x39 Magpul PMAG 30 GEN M3 30-round magazine_1x2_(1,2)",
  "AK-74 5.45x39 muzzle brake-compensator (6P20 0-20)_1x1_(1,1)",
  "AK-74 Hexagon 5.45x39 sound suppressor_2x1_(1,1)",
  "AK-74 Hexagon 5.45x39 sound suppressor_2x1_(2,1)",
  "AK-74 Hexagon Wafflemaker 5.45x39 sound suppressor_2x1_(1,1)",
  "AK-74 Hexagon Wafflemaker 5.45x39 sound suppressor_2x1_(2,1)",
  "AK-74 PWS CQB 74 5.45x39 muzzle brake_1x1_(1,1)",
  "AK-74 SRVV MBR Jet 5.45x39 muzzle brake_1x1_(1,1)",
  "AK-74 TGP-A 5.45x39 sound suppressor_2x1_(1,1)",
  "AK-74 TGP-A 5.45x39 sound suppressor_2x1_(2,1)",
  "AK-74 dust cover (6P20 0-1)_2x1_(1,1)",
  "AK-74 dust cover (6P20 0-1)_2x1_(2,1)",
  "AK-74 gas tube (6P20 Sb.1-2)_1x1_(1,1)",
  "AK-74 polymer handguard (6P20 Sb.9)_1x1_(1,1)",
  "AK-74 polymer stock (6P20 Sb.7)_2x1_(1,1)",
  "AK-74 polymer stock (6P20 Sb.7)_2x1_(2,1)",
  "AK-74 rear sight (6P20 Sb.2)_1x1_(1,1)",
  "AK-74 thread type JMac Customs RRD-4C multi-caliber muzzle brake_1x1_(1,1)",
  "AK-74 wooden handguard (6P20 Sb.6)_1x1_(1,1)",
  "AK-74 wooden stock (6P20 Sb.5)_2x1_(1,1)",
  "AK-74 wooden stock (6P20 Sb.5)_2x1_(2,1)",
  "AK-74M 5.45x39 muzzle brake-compensator (6P20 0-20)_1x1_(1,1)",
  "AK-74M CAA AKTS AK74 buffer tube_2x1_(1,1)",
  "AK-74M CAA AKTS AK74 buffer tube_2x1_(2,1)",
  "AK-74M dust cover (6P34 0-1)_2x1_(1,1)",
  "AK-74M dust cover (6P34 0-1)_2x1_(2,1)",
  "AK-74M polymer stock (6P34 Sb.15)_2x1_(1,1)",
  "AK-74M polymer stock (6P34 Sb.15)_2x1_(2,1)",
  "AK-74M rear sight (6P20 Sb.2)_1x1_(1,1)",
  "AK-74M@AK-100 Zenit PT Lock_1x1_(1,1)",
  "AKM 7.62x39 Kiba Arms .308 muzzle device adapter_1x1_(1,1)",
  "AKM 7.62x39 muzzle brake-compensator (6P1 0-14)_1x1_(1,1)",
  "AKM Hexagon 7.62x39 sound suppressor_2x1_(1,1)",
  "AKM Hexagon 7.62x39 sound suppressor_2x1_(2,1)",
  "AKM PBS-1 7.62x39 sound suppressor_2x1_(1,1)",
  "AKM PBS-1 7.62x39 sound suppressor_2x1_(2,1)",
  "AKM Zenit DTK-4M 7.62x39 sound suppressor_2x1_(1,1)",
  "AKM Zenit DTK-4M 7.62x39 sound suppressor_2x1_(2,1)",
  "AKM bakelite pistol grip_1x1_(1,1)",
  "AKM dust cover (6P1 0-1)_2x1_(1,1)",
  "AKM dust cover (6P1 0-1)_2x1_(2,1)",
  "AKM gas tube (6P1 Sb.1-2)_1x1_(1,1)",
  "AKM rear sight (6P1 Sb.2-1)_1x1_(1,1)",
  "AKM thread type JMac Customs RRD-4C 7.62x39 muzzle brake_1x1_(1,1)",
  "AKM wooden handguard (6P1 Sb.6-1)_1x1_(1,1)",
  "AKM wooden pistol grip_1x1_(1,1)",
  "AKM wooden stock (6P1 Sb.5)_2x1_(1,1)",
  "AKM wooden stock (6P1 Sb.5)_2x1_(2,1)",
  "AKM@AK-74 CAA AKTS buffer tube_2x1_(1,1)",
  "AKM@AK-74 CAA AKTS buffer tube_2x1_(2,1)",
  "AKM@AK-74 FAB Defense UAS stock_2x1_(1,1)",
  "AKM@AK-74 FAB Defense UAS stock_2x1_(2,1)",
  "AKM@AK-74 Hera Arms CQR47 pistol grip@buttstock_2x1_(1,1)",
  "AKM@AK-74 Hera Arms CQR47 pistol grip@buttstock_2x1_(2,1)",
  "AKM@AK-74 Hexagon 'Kocherga' stock (Anodized Red)_2x1_(1,1)",
  "AKM@AK-74 Hexagon 'Kocherga' stock (Anodized Red)_2x1_(2,1)",
  "AKM@AK-74 ME4 buffer tube adapter_1x1_(1,1)",
  "AKM@AK-74 Magpul Zhukov-S stock_2x1_(1,1)",
  "AKM@AK-74 Magpul Zhukov-S stock_2x1_(2,1)",
  "AKM@AK-74 ProMag Archangel OPFOR AA47 buttstock_2x1_(1,1)",
  "AKM@AK-74 ProMag Archangel OPFOR AA47 buttstock_2x1_(2,1)",
  "AKM@AK-74 RD AK to M4 buffer tube adapter_1x1_(1,1)",
  "AKM@AK-74 Zenit PT Lock_1x1_(1,1)",
  "AKMB system rear sight_1x1_(1,1)",
  "AKML system 7.62x39 flash hider_1x1_(1,1)",
  "AKMP system front sight device_1x1_(1,1)",
  "AKMP system rear sight device_1x1_(1,1)",
  "AKMS 7.62x39 aluminium 30-round magazine_1x2_(1,1)",
  "AKMS 7.62x39 aluminium 30-round magazine_1x2_(1,2)",
  "AKMS shoulder piece (6P4 Sb.1-19)_2x1_(1,1)",
  "AKMS shoulder piece (6P4 Sb.1-19)_2x1_(2,1)",
  "AKMSN shoulder piece (6P4N Sb.1-19)_2x1_(1,1)",
  "AKMSN shoulder piece (6P4N Sb.1-19)_2x1_(2,1)",
  "AKS-74 metal skeletonized stock (6P21 Sb.5)_2x1_(1,1)",
  "AKS-74 metal skeletonized stock (6P21 Sb.5)_2x1_(2,1)",
  "AKS-74@AKS-74U Zenit PT Lock_1x1_(1,1)",
  "AKS-74U 5.45x39 muzzle brake (6P26 0-20)_1x1_(1,1)",
  "AKS-74U Alfa Arms Goliaf M-LOK handguard_1x1_(1,1)",
  "AKS-74U Alfa Arms Goliaf handguard_1x1_(1,1)",
  "AKS-74U CAA XRSU47SU tactical handguard_2x1_(1,1)",
  "AKS-74U CAA XRSU47SU tactical handguard_2x1_(2,1)",
  "AKS-74U Legal Arsenal Piligrim railed dust cover_2x1_(1,1)",
  "AKS-74U Legal Arsenal Piligrim railed dust cover_2x1_(2,1)",
  "AKS-74U PBS-4 5.45x39 sound suppressor_2x1_(1,1)",
  "AKS-74U PBS-4 5.45x39 sound suppressor_2x1_(2,1)",
  "AKS-74U Zenit B-11 handguard_1x1_(1,1)",
  "AKS-74U Zenit B-18 Mount_1x1_(1,1)",
  "AKS-74U dust cover (6P26 Sb.7)_2x1_(1,1)",
  "AKS-74U dust cover (6P26 Sb.7)_2x1_(2,1)",
  "AKS-74U gas tube (6P26 Sb.1-2)_1x1_(1,1)",
  "AKS-74U metal skeleton stock (6P26 Sb.5)_2x1_(1,1)",
  "AKS-74U metal skeleton stock (6P26 Sb.5)_2x1_(2,1)",
  "AKS-74U wooden handguard (6P26 Sb.6)_1x1_(1,1)",
  "AKS-74UB dust cover_2x1_(1,1)",
  "AKS-74UB dust cover_2x1_(2,1)",
  "AN@PEQ-15 tactical device_1x1_(1,1)",
  "AN@PEQ-2 tactical device_1x1_(1,1)",
  "AN@PVS-14 Dual Dovetail Mount_1x1_(1,1)",
  "AN@PVS-14 Night Vision Monocular_1x1_(1,1)",
  "APB 9x18PM sound suppressor_2x1_(1,1)",
  "APB 9x18PM sound suppressor_2x1_(2,1)",
  "APB detachable wire stock_1x1_(1,1)",
  "APB rear sight_1x1_(1,1)",
  "APS 9x18PM 20-round magazine_1x1_(1,1)",
  "APS bakelite side-pieces_1x1_(1,1)",
  "APS front sight_1x1_(1,1)",
  "APS rear sight_1x1_(1,1)",
  "AR-10 2A Armanent X3 7.62x51 compensator_1x1_(1,1)",
  "AR-10 7.62x51 18 inch barrel_4x1_(1,1)",
  "AR-10 7.62x51 18 inch barrel_4x1_(2,1)",
  "AR-10 7.62x51 18 inch barrel_4x1_(3,1)",
  "AR-10 7.62x51 18 inch barrel_4x1_(4,1)",
  "AR-10 7.62x51 22 inch barrel_5x1_(1,1)",
  "AR-10 7.62x51 22 inch barrel_5x1_(2,1)",
  "AR-10 7.62x51 22 inch barrel_5x1_(3,1)",
  "AR-10 7.62x51 22 inch barrel_5x1_(4,1)",
  "AR-10 7.62x51 22 inch barrel_5x1_(5,1)",
  "AR-10 7.62x51 KAC 10-round steel magazine_1x1_(1,1)",
  "AR-10 7.62x51 KAC 20-round steel magazine_1x2_(1,1)",
  "AR-10 7.62x51 KAC 20-round steel magazine_1x2_(1,2)",
  "AR-10 7.62x51 Lancer L7AWM 20-round magazine_1x2_(1,1)",
  "AR-10 7.62x51 Lancer L7AWM 20-round magazine_1x2_(1,2)",
  "AR-10 7.62x51 Lancer L7AWM 25-round magazine_1x2_(1,1)",
  "AR-10 7.62x51 Lancer L7AWM 25-round magazine_1x2_(1,2)",
  "AR-10 7.62x51 Magpul PMAG 20 SR-LR GEN M3 20-round magazine_1x2_(1,1)",
  "AR-10 7.62x51 Magpul PMAG 20 SR-LR GEN M3 20-round magazine_1x2_(1,2)",
  "AR-10 AAC Blackout 51T 7.62x51 flash hider_1x1_(1,1)",
  "AR-10 CMMG MK3 RML15 15 inch M-LOK handguard_3x1_(1,1)",
  "AR-10 CMMG MK3 RML15 15 inch M-LOK handguard_3x1_(2,1)",
  "AR-10 CMMG MK3 RML15 15 inch M-LOK handguard_3x1_(3,1)",
  "AR-10 CMMG MK3 RML9 9 inch M-LOK handguard_2x1_(1,1)",
  "AR-10 CMMG MK3 RML9 9 inch M-LOK handguard_2x1_(2,1)",
  "AR-10 CMMG SV Brake 7.62x51 muzzle brake_1x1_(1,1)",
  "AR-10 CMMG low profile gas block_1x1_(1,1)",
  "AR-10 Daniel Defense WAVE 7.62x51 muzzle brake_1x1_(1,1)",
  "AR-10 Dead Air Keymount 7.62x51 muzzle brake_1x1_(1,1)",
  "AR-10 Fortis RED Brake 7.62x51 muzzle brake_1x1_(1,1)",
  "AR-10 JP Enterprises Gas System-6_1x1_(1,1)",
  "AR-10 KAC Low Profile Gas Block_1x1_(1,1)",
  "AR-10 KAC QDC 7.62x51 Flash Suppressor Kit_1x1_(1,1)",
  "AR-10 KAC QDC 7.62x51 Muzzle Brake Kit_1x1_(1,1)",
  "AR-10 KAC URX 4 14.5 inch handguard_3x1_(1,1)",
  "AR-10 KAC URX 4 14.5 inch handguard_3x1_(2,1)",
  "AR-10 KAC URX 4 14.5 inch handguard_3x1_(3,1)",
  "AR-10 KAC ambidextrous charging handle_1x1_(1,1)",
  "AR-10 KAC charging handle_1x1_(1,1)",
  "AR-10 Keeno Arms SHREWD 7.62x51 muzzle brake_1x1_(1,1)",
  "AR-10 Lancer LCH7 12.5 inch M-LOK handguard_3x1_(1,1)",
  "AR-10 Lancer LCH7 12.5 inch M-LOK handguard_3x1_(2,1)",
  "AR-10 Lancer LCH7 12.5 inch M-LOK handguard_3x1_(3,1)",
  "AR-10 Lantac Dragon 7.62x51 muzzle brake-compensator_1x1_(1,1)",
  "AR-10 Nordic Components Corvette 7.62x51 compensator_1x1_(1,1)",
  "AR-10 Noveske SWS N6 10.5 inch handguard_2x1_(1,1)",
  "AR-10 Noveske SWS N6 10.5 inch handguard_2x1_(2,1)",
  "AR-10 Noveske SWS N6 Split handguard_2x1_(1,1)",
  "AR-10 Noveske SWS N6 Split handguard_2x1_(2,1)",
  "AR-10 Odin Works ATLAS-7 7.62x51 muzzle brake_1x1_(1,1)",
  "AR-10 Precision Armanent M11 Severe-Duty 7.62x51 muzzle brake_1x1_(1,1)",
  "AR-10 SureFire ProComp 7.62x51 muzzle brake_1x1_(1,1)",
  "AR-10 SureFire Warden 7.62x51 blast regulator_1x1_(1,1)",
  "AR-10 TAA ZK-38 7.62x51 muzzle brake_1x1_(1,1)",
  "AR-10 Thunder Beast Arms 30CB 7.62x51 muzzle brake_1x1_(1,1)",
  "AR-15 5.56x45 18 inch barrel_3x1_(1,1)",
  "AR-15 5.56x45 18 inch barrel_3x1_(2,1)",
  "AR-15 5.56x45 18 inch barrel_3x1_(3,1)",
  "AR-15 5.56x45 20 inch barrel_4x1_(1,1)",
  "AR-15 5.56x45 20 inch barrel_4x1_(2,1)",
  "AR-15 5.56x45 20 inch barrel_4x1_(3,1)",
  "AR-15 5.56x45 20 inch barrel_4x1_(4,1)",
  "AR-15 5.56x45 260mm barrel_2x1_(1,1)",
  "AR-15 5.56x45 260mm barrel_2x1_(2,1)",
  "AR-15 5.56x45 370mm barrel_3x1_(1,1)",
  "AR-15 5.56x45 370mm barrel_3x1_(2,1)",
  "AR-15 5.56x45 370mm barrel_3x1_(3,1)",
  "AR-15 5.56x45 Molot Arms 406mm barrel_3x1_(1,1)",
  "AR-15 5.56x45 Molot Arms 406mm barrel_3x1_(2,1)",
  "AR-15 5.56x45 Molot Arms 406mm barrel_3x1_(3,1)",
  "AR-15 AAC Blackout 51T 5.56x45 flash hider_1x1_(1,1)",
  "AR-15 AB Arms MOD1 handguard_2x1_(1,1)",
  "AR-15 AB Arms MOD1 handguard_2x1_(2,1)",
  "AR-15 AB Arms MOD1 lower handguard_2x1_(1,1)",
  "AR-15 AB Arms MOD1 lower handguard_2x1_(2,1)",
  "AR-15 ADAR 2-15 5.56x45 flash hider_1x1_(1,1)",
  "AR-15 ADAR 2-15 5.56x45 upper receiver_2x1_(1,1)",
  "AR-15 ADAR 2-15 5.56x45 upper receiver_2x1_(2,1)",
  "AR-15 ADAR 2-15 buffer tube_2x1_(1,1)",
  "AR-15 ADAR 2-15 buffer tube_2x1_(2,1)",
  "AR-15 ADAR 2-15 charging handle_1x1_(1,1)",
  "AR-15 ADAR 2-15 wooden handguard_2x1_(1,1)",
  "AR-15 ADAR 2-15 wooden handguard_2x1_(2,1)",
  "AR-15 ADAR 2-15 wooden stock_2x1_(1,1)",
  "AR-15 ADAR 2-15 wooden stock_2x1_(2,1)",
  "AR-15 AWC PSR 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 Aeroknox AX-15 10.5 inch M-LOK handguard_2x1_(1,1)",
  "AR-15 Aeroknox AX-15 10.5 inch M-LOK handguard_2x1_(2,1)",
  "AR-15 Aeroknox AX-15 15.8 inch M-LOK handguard_3x1_(1,1)",
  "AR-15 Aeroknox AX-15 15.8 inch M-LOK handguard_3x1_(2,1)",
  "AR-15 Aeroknox AX-15 15.8 inch M-LOK handguard_3x1_(3,1)",
  "AR-15 Aeroknox AX-15 4 inch M-LOK handguard_1x1_(1,1)",
  "AR-15 Aeroknox AX-15 5.56x45 upper receiver_2x1_(1,1)",
  "AR-15 Aeroknox AX-15 5.56x45 upper receiver_2x1_(2,1)",
  "AR-15 Aeroknox Butterfly 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 Aeroknox Orion pistol grip_1x1_(1,1)",
  "AR-15 Alexander Arms MK10 rifle length handguard_2x1_(1,1)",
  "AR-15 Alexander Arms MK10 rifle length handguard_2x1_(2,1)",
  "AR-15 AlienTech 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 Armacon Baskak stock_2x1_(1,1)",
  "AR-15 Armacon Baskak stock_2x1_(2,1)",
  "AR-15 B5 Systems Precision stock_2x1_(1,1)",
  "AR-15 B5 Systems Precision stock_2x1_(2,1)",
  "AR-15 Badger Ordnance Tactical Charging Handle Latch_1x1_(1,1)",
  "AR-15 Ballistic Advantage Lo-Pro Gas Block_1x1_(1,1)",
  "AR-15 Bulletec ST-6012 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 CMMG RipStock buttstock_2x1_(1,1)",
  "AR-15 CMMG RipStock buttstock_2x1_(2,1)",
  "AR-15 Colt A2 buffer tube_2x1_(1,1)",
  "AR-15 Colt A2 buffer tube_2x1_(2,1)",
  "AR-15 Colt A2 pistol grip_1x1_(1,1)",
  "AR-15 Colt A2 rear sight_1x1_(1,1)",
  "AR-15 Colt Carbine buffer tube_2x1_(1,1)",
  "AR-15 Colt Carbine buffer tube_2x1_(2,1)",
  "AR-15 Colt M4 Carbine Length handguard_2x1_(1,1)",
  "AR-15 Colt M4 Carbine Length handguard_2x1_(2,1)",
  "AR-15 Colt M4 Carbine Length lower handguard_2x1_(1,1)",
  "AR-15 Colt M4 Carbine Length lower handguard_2x1_(2,1)",
  "AR-15 Colt USGI A2 5.56x45 flash hider_1x1_(1,1)",
  "AR-15 Colt charging handle_1x1_(1,1)",
  "AR-15 DLG Tactical DLG-123 pistol grip_1x1_(1,1)",
  "AR-15 Damage Industries ECS pistol grip (FDE)_1x1_(1,1)",
  "AR-15 Daniel Defense Enhanced Collapsible Buttstock (Black)_2x1_(1,1)",
  "AR-15 Daniel Defense Enhanced Collapsible Buttstock (Black)_2x1_(2,1)",
  "AR-15 Daniel Defense Enhanced Collapsible Buttstock (FDE)_2x1_(1,1)",
  "AR-15 Daniel Defense Enhanced Collapsible Buttstock (FDE)_2x1_(2,1)",
  "AR-15 Daniel Defense GRIP-N-RIP charging handle_1x1_(1,1)",
  "AR-15 Daniel Defense MK12 Low Profile Gas Block_1x1_(1,1)",
  "AR-15 Daniel Defense RIS II 12.25 handguard (Coyote Brown)_2x1_(1,1)",
  "AR-15 Daniel Defense RIS II 12.25 handguard (Coyote Brown)_2x1_(2,1)",
  "AR-15 Daniel Defense RIS II 12.25 lower handguard (Coyote Brown)_2x1_(1,1)",
  "AR-15 Daniel Defense RIS II 12.25 lower handguard (Coyote Brown)_2x1_(2,1)",
  "AR-15 Daniel Defense RIS II 9.5 handguard (Black)_2x1_(1,1)",
  "AR-15 Daniel Defense RIS II 9.5 handguard (Black)_2x1_(2,1)",
  "AR-15 Daniel Defense RIS II 9.5 handguard (Coyote Brown)_2x1_(1,1)",
  "AR-15 Daniel Defense RIS II 9.5 handguard (Coyote Brown)_2x1_(2,1)",
  "AR-15 Daniel Defense RIS II 9.5 lower handguard (Black)_2x1_(1,1)",
  "AR-15 Daniel Defense RIS II 9.5 lower handguard (Black)_2x1_(2,1)",
  "AR-15 Daniel Defense RIS II 9.5 lower handguard (Coyote Brown)_2x1_(1,1)",
  "AR-15 Daniel Defense RIS II 9.5 lower handguard (Coyote Brown)_2x1_(2,1)",
  "AR-15 Daniel Defense RIS II FSP 9.5 handguard (Coyote Brown)_2x1_(1,1)",
  "AR-15 Daniel Defense RIS II FSP 9.5 handguard (Coyote Brown)_2x1_(2,1)",
  "AR-15 Daniel Defense WAVE 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 DoubleStar ACE SOCOM Gen.4 stock_2x1_(1,1)",
  "AR-15 DoubleStar ACE SOCOM Gen.4 stock_2x1_(2,1)",
  "AR-15 ERGO F93 PRO stock_2x1_(1,1)",
  "AR-15 ERGO F93 PRO stock_2x1_(2,1)",
  "AR-15 F1 Firearms Skeletonized Style 1 pistol grip_1x1_(1,1)",
  "AR-15 F1 Firearms Skeletonized Style 2 PC pistol grip_1x1_(1,1)",
  "AR-15 F1 Firearms Skeletonized Style 2 pistol grip_1x1_(1,1)",
  "AR-15 FAB Defense GL-CORE buttstock_2x1_(1,1)",
  "AR-15 FAB Defense GL-CORE buttstock_2x1_(2,1)",
  "AR-15 FAB Defense GL-SHOCK buttstock_2x1_(1,1)",
  "AR-15 FAB Defense GL-SHOCK buttstock_2x1_(2,1)",
  "AR-15 FAB Defense GLR-16-S stock_2x1_(1,1)",
  "AR-15 FAB Defense GLR-16-S stock_2x1_(2,1)",
  "AR-15 Ferfrans CQB 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 Geissele ACH charging handle (DDC)_1x1_(1,1)",
  "AR-15 Geissele SMR MK16 13.5 inch M-LOK handguard (DDC)_3x1_(1,1)",
  "AR-15 Geissele SMR MK16 13.5 inch M-LOK handguard (DDC)_3x1_(2,1)",
  "AR-15 Geissele SMR MK16 13.5 inch M-LOK handguard (DDC)_3x1_(3,1)",
  "AR-15 Geissele SMR MK16 9.5 inch M-LOK handguard (DDC)_2x1_(1,1)",
  "AR-15 Geissele SMR MK16 9.5 inch M-LOK handguard (DDC)_2x1_(2,1)",
  "AR-15 Griffin Armament Gate-LOK Hammer 5.56x45 flash hider_1x1_(1,1)",
  "AR-15 Griffin Armament M4SD-K 5.56x45 sound suppressor_2x1_(1,1)",
  "AR-15 Griffin Armament M4SD-K 5.56x45 sound suppressor_2x1_(2,1)",
  "AR-15 HK BLITZ 5.56x45 flash hider_1x1_(1,1)",
  "AR-15 HK Battle Grip Beavertail pistol grip (RAL 8000)_1x1_(1,1)",
  "AR-15 HK Battle Grip Beavertail pistol grip_1x1_(1,1)",
  "AR-15 HK Battle Grip pistol grip_1x1_(1,1)",
  "AR-15 HK E1 buttstock_2x1_(1,1)",
  "AR-15 HK E1 buttstock_2x1_(2,1)",
  "AR-15 HK Ergo PSG-1 style pistol grip_1x1_(1,1)",
  "AR-15 HK Extended Latch charging handle_1x1_(1,1)",
  "AR-15 HK Slim Line buttstock_2x1_(1,1)",
  "AR-15 HK Slim Line buttstock_2x1_(2,1)",
  "AR-15 HK V2 pistol grip_1x1_(1,1)",
  "AR-15 Hanson Carbine 5.56x45 13.7 inch barrel_3x1_(1,1)",
  "AR-15 Hanson Carbine 5.56x45 13.7 inch barrel_3x1_(2,1)",
  "AR-15 Hanson Carbine 5.56x45 13.7 inch barrel_3x1_(3,1)",
  "AR-15 Hanson Carbine 5.56x45 16 inch barrel_3x1_(1,1)",
  "AR-15 Hanson Carbine 5.56x45 16 inch barrel_3x1_(2,1)",
  "AR-15 Hanson Carbine 5.56x45 16 inch barrel_3x1_(3,1)",
  "AR-15 Hera Arms CQR pistol grip@buttstock_2x1_(1,1)",
  "AR-15 Hera Arms CQR pistol grip@buttstock_2x1_(2,1)",
  "AR-15 Hera Arms HG-15 pistol grip_1x1_(1,1)",
  "AR-15 High Standard M4SS Stock_2x1_(1,1)",
  "AR-15 High Standard M4SS Stock_2x1_(2,1)",
  "AR-15 JP Enterprises Gas System-5B_1x1_(1,1)",
  "AR-15 KAC QDC 5.56x45 Flash Suppressor Kit_1x1_(1,1)",
  "AR-15 KAC RIS handguard_2x1_(1,1)",
  "AR-15 KAC RIS handguard_2x1_(2,1)",
  "AR-15 KAC RIS lower handguard_2x1_(1,1)",
  "AR-15 KAC RIS lower handguard_2x1_(2,1)",
  "AR-15 KAC URX 3 8 inch handguard_2x1_(1,1)",
  "AR-15 KAC URX 3 8 inch handguard_2x1_(2,1)",
  "AR-15 KAC URX 3 8 inch lower handguard_2x1_(1,1)",
  "AR-15 KAC URX 3 8 inch lower handguard_2x1_(2,1)",
  "AR-15 KAC URX 3.1 10.75 inch handguard_2x1_(1,1)",
  "AR-15 KAC URX 3.1 10.75 inch handguard_2x1_(2,1)",
  "AR-15 KAC URX 3.1 10.75 inch lower handguard_2x1_(1,1)",
  "AR-15 KAC URX 3.1 10.75 inch lower handguard_2x1_(2,1)",
  "AR-15 KRISS Defiance DS150 stock (Black)_2x1_(1,1)",
  "AR-15 KRISS Defiance DS150 stock (Black)_2x1_(2,1)",
  "AR-15 KRISS Defiance DS150 stock (FDE)_2x1_(1,1)",
  "AR-15 KRISS Defiance DS150 stock (FDE)_2x1_(2,1)",
  "AR-15 LMT SOPMOD stock_2x1_(1,1)",
  "AR-15 LMT SOPMOD stock_2x1_(2,1)",
  "AR-15 Leapers UTG Low Profile A2 front sight_1x1_(1,1)",
  "AR-15 Lone Star Ion Lite handguard_3x1_(1,1)",
  "AR-15 Lone Star Ion Lite handguard_3x1_(2,1)",
  "AR-15 Lone Star Ion Lite handguard_3x1_(3,1)",
  "AR-15 MASP Industries Ambidextrous Battle Charging Handle_1x1_(1,1)",
  "AR-15 MFT BUS stock_2x1_(1,1)",
  "AR-15 MFT BUS stock_2x1_(2,1)",
  "AR-15 Magpul CTR Carbine stock (Black)_2x1_(1,1)",
  "AR-15 Magpul CTR Carbine stock (Black)_2x1_(2,1)",
  "AR-15 Magpul CTR Carbine stock (FDE)_2x1_(1,1)",
  "AR-15 Magpul CTR Carbine stock (FDE)_2x1_(2,1)",
  "AR-15 Magpul MIAD pistol grip (FDE)_1x1_(1,1)",
  "AR-15 Magpul MOE Carbine stock (Black)_2x1_(1,1)",
  "AR-15 Magpul MOE Carbine stock (Black)_2x1_(2,1)",
  "AR-15 Magpul MOE Carbine stock (FDE)_2x1_(1,1)",
  "AR-15 Magpul MOE Carbine stock (FDE)_2x1_(2,1)",
  "AR-15 Magpul MOE Carbine stock (Foliage Green)_2x1_(1,1)",
  "AR-15 Magpul MOE Carbine stock (Foliage Green)_2x1_(2,1)",
  "AR-15 Magpul MOE Carbine stock (Olive Drab)_2x1_(1,1)",
  "AR-15 Magpul MOE Carbine stock (Olive Drab)_2x1_(2,1)",
  "AR-15 Magpul MOE Carbine stock (Stealth Grey)_2x1_(1,1)",
  "AR-15 Magpul MOE Carbine stock (Stealth Grey)_2x1_(2,1)",
  "AR-15 Magpul MOE SL carbine length M-LOK handguard_2x1_(1,1)",
  "AR-15 Magpul MOE SL carbine length M-LOK handguard_2x1_(2,1)",
  "AR-15 Magpul MOE SL medium length M-LOK handguard_2x1_(1,1)",
  "AR-15 Magpul MOE SL medium length M-LOK handguard_2x1_(2,1)",
  "AR-15 Magpul MOE SL-K buttstock (Coyote Tan)_2x1_(1,1)",
  "AR-15 Magpul MOE SL-K buttstock (Coyote Tan)_2x1_(2,1)",
  "AR-15 Magpul MOE pistol grip (Black)_1x1_(1,1)",
  "AR-15 Magpul MOE pistol grip (FDE)_1x1_(1,1)",
  "AR-15 Magpul PRS GEN2 stock (FDE)_2x1_(1,1)",
  "AR-15 Magpul PRS GEN2 stock (FDE)_2x1_(2,1)",
  "AR-15 Magpul PRS GEN3 stock (Black)_2x1_(1,1)",
  "AR-15 Magpul PRS GEN3 stock (Black)_2x1_(2,1)",
  "AR-15 Magpul PRS GEN3 stock (Grey)_2x1_(1,1)",
  "AR-15 Magpul PRS GEN3 stock (Grey)_2x1_(2,1)",
  "AR-15 Magpul UBR GEN2 stock (Black)_2x1_(1,1)",
  "AR-15 Magpul UBR GEN2 stock (Black)_2x1_(2,1)",
  "AR-15 Magpul UBR GEN2 stock (FDE)_2x1_(1,1)",
  "AR-15 Magpul UBR GEN2 stock (FDE)_2x1_(2,1)",
  "AR-15 Naroh Arms GRAL-S pistol grip_1x1_(1,1)",
  "AR-15 Nordic Components Corvette 5.56x45 compensator_1x1_(1,1)",
  "AR-15 Noveske Gen.3 5.56x45 upper receiver_2x1_(1,1)",
  "AR-15 Noveske Gen.3 5.56x45 upper receiver_2x1_(2,1)",
  "AR-15 Noveske KX3 5.56x45 flash hider_1x1_(1,1)",
  "AR-15 PWS CQB 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 Precision Reflex GEN III Delta Carbon handguard_2x1_(1,1)",
  "AR-15 Precision Reflex GEN III Delta Carbon handguard_2x1_(2,1)",
  "AR-15 RTM ATP buffer tube_2x1_(1,1)",
  "AR-15 RTM ATP buffer tube_2x1_(2,1)",
  "AR-15 Radian Weapons Raptor charging handle (Grey)_1x1_(1,1)",
  "AR-15 Radian Weapons Raptor charging handle_1x1_(1,1)",
  "AR-15 Rainier Arms Avalanche MOD2 charging handle_1x1_(1,1)",
  "AR-15 SAI 10 inch QD Rail handguard_2x1_(1,1)",
  "AR-15 SAI 10 inch QD Rail handguard_2x1_(2,1)",
  "AR-15 SAI 14.5 inch QD Rail handguard_3x1_(1,1)",
  "AR-15 SAI 14.5 inch QD Rail handguard_3x1_(2,1)",
  "AR-15 SAI 14.5 inch QD Rail handguard_3x1_(3,1)",
  "AR-15 SAI JailBrake 5.56x45 muzzle device_1x1_(1,1)",
  "AR-15 SB Tactical SBA3 brace_2x1_(1,1)",
  "AR-15 SB Tactical SBA3 brace_2x1_(2,1)",
  "AR-15 SIG M400 Reduced Angle Pistol Grip (Coyote Tan)_1x1_(1,1)",
  "AR-15 STNGR VYPR 10 inch M-LOK handguard_2x1_(1,1)",
  "AR-15 STNGR VYPR 10 inch M-LOK handguard_2x1_(2,1)",
  "AR-15 SilencerCo ASR 5.56x45 flash hider_1x1_(1,1)",
  "AR-15 Soyuz-TM 12 inch M-LOK handguard_2x1_(1,1)",
  "AR-15 Soyuz-TM 12 inch M-LOK handguard_2x1_(2,1)",
  "AR-15 Soyuz-TM 15 inch M-LOK handguard_3x1_(1,1)",
  "AR-15 Soyuz-TM 15 inch M-LOK handguard_3x1_(2,1)",
  "AR-15 Soyuz-TM 15 inch M-LOK handguard_3x1_(3,1)",
  "AR-15 Soyuz-TM 9 inch M-LOK handguard_2x1_(1,1)",
  "AR-15 Soyuz-TM 9 inch M-LOK handguard_2x1_(2,1)",
  "AR-15 Soyuz-TM buffer tube_2x1_(1,1)",
  "AR-15 Soyuz-TM buffer tube_2x1_(2,1)",
  "AR-15 Stark AR Rifle Grip (Black)_1x1_(1,1)",
  "AR-15 Stark AR Rifle Grip (FDE)_1x1_(1,1)",
  "AR-15 Strike Industries Advanced Receiver Extension buffer tube (Anodized Red)_2x1_(1,1)",
  "AR-15 Strike Industries Advanced Receiver Extension buffer tube (Anodized Red)_2x1_(2,1)",
  "AR-15 Strike Industries Advanced Receiver Extension buffer tube_2x1_(1,1)",
  "AR-15 Strike Industries Advanced Receiver Extension buffer tube_2x1_(2,1)",
  "AR-15 Strike Industries Viper Mod 1 stock_2x1_(1,1)",
  "AR-15 Strike Industries Viper Mod 1 stock_2x1_(2,1)",
  "AR-15 Strike Industries Viper PDW stock_2x1_(1,1)",
  "AR-15 Strike Industries Viper PDW stock_2x1_(2,1)",
  "AR-15 Strike Industries Viper carbine length M-LOK handguard (FDE)_2x1_(1,1)",
  "AR-15 Strike Industries Viper carbine length M-LOK handguard (FDE)_2x1_(2,1)",
  "AR-15 Strike Industries Viper carbine length M-LOK handguard_2x1_(1,1)",
  "AR-15 Strike Industries Viper carbine length M-LOK handguard_2x1_(2,1)",
  "AR-15 Strike Industries Viper carbine length M-LOK lower handguard (FDE)_2x1_(1,1)",
  "AR-15 Strike Industries Viper carbine length M-LOK lower handguard (FDE)_2x1_(2,1)",
  "AR-15 Strike Industries Viper carbine length M-LOK lower handguard_2x1_(1,1)",
  "AR-15 Strike Industries Viper carbine length M-LOK lower handguard_2x1_(2,1)",
  "AR-15 SureFire SF3P 5.56x45 Flash hider_1x1_(1,1)",
  "AR-15 SureFire SF4P 5.56x45 flash hider_1x1_(1,1)",
  "AR-15 SureFire WarComp 5.56x45 flash hider_1x1_(1,1)",
  "AR-15 SureFire Warden 5.56x45 blast regulator_1x1_(1,1)",
  "AR-15 TAA ZK-23 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 TROY Claymore 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 TROY M7A1 PDW stock (Black)_2x1_(1,1)",
  "AR-15 TROY M7A1 PDW stock (Black)_2x1_(2,1)",
  "AR-15 TROY M7A1 PDW stock (FDE)_2x1_(1,1)",
  "AR-15 TROY M7A1 PDW stock (FDE)_2x1_(2,1)",
  "AR-15 Tactical Dynamics Hexgrip pistol grip_1x1_(1,1)",
  "AR-15 Tactical Dynamics Skeletonized pistol grip_1x1_(1,1)",
  "AR-15 Thunder Beast Arms 223CB 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 Unique-ARs Wing & Skull 12 inch handguard_2x1_(1,1)",
  "AR-15 Unique-ARs Wing & Skull 12 inch handguard_2x1_(2,1)",
  "AR-15 Vendetta Precision VP-09 Interceptor 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 Vltor EMOD stock_2x1_(1,1)",
  "AR-15 Vltor EMOD stock_2x1_(2,1)",
  "AR-15 Vltor MUR-1S 5.56x45 upper receiver_2x1_(1,1)",
  "AR-15 Vltor MUR-1S 5.56x45 upper receiver_2x1_(2,1)",
  "AR-15 WDR NERO 556 5.56x45 muzzle brake_1x1_(1,1)",
  "AR-15 War Sport LVOA-C handguard (Black)_3x1_(1,1)",
  "AR-15 War Sport LVOA-C handguard (Black)_3x1_(2,1)",
  "AR-15 War Sport LVOA-C handguard (Black)_3x1_(3,1)",
  "AR-15 War Sport LVOA-S handguard (Black)_2x1_(1,1)",
  "AR-15 War Sport LVOA-S handguard (Black)_2x1_(2,1)",
  "AR-15 Windham Weaponry Rail Gas Block_1x1_(1,1)",
  "AR-15 Yankee Hill Phantom 5.56x45 flash hider_1x1_(1,1)",
  "AR-15 rear sight carry handle_1x1_(1,1)",
  "AS VAL 9x39 integral barrel-suppressor_2x1_(1,1)",
  "AS VAL 9x39 integral barrel-suppressor_2x1_(2,1)",
  "AS VAL Rotor 43 pistol grip & buffer tube_2x1_(1,1)",
  "AS VAL Rotor 43 pistol grip & buffer tube_2x1_(2,1)",
  "AS VAL dust cover_2x1_(1,1)",
  "AS VAL dust cover_2x1_(2,1)",
  "AS VAL pistol grip_1x1_(1,1)",
  "AS VAL rear sight_1x1_(1,1)",
  "AS VAL skeleton stock_2x1_(1,1)",
  "AS VAL skeleton stock_2x1_(2,1)",
  "ASh-12 12.7x55 10-round magazine_1x2_(1,1)",
  "ASh-12 12.7x55 10-round magazine_1x2_(1,2)",
  "ASh-12 12.7x55 20-round magazine_1x2_(1,1)",
  "ASh-12 12.7x55 20-round magazine_1x2_(1,2)",
  "ASh-12 12.7x55 muzzle brake-compensator_1x1_(1,1)",
  "ASh-12 12.7x55 sound suppressor_2x1_(1,1)",
  "ASh-12 12.7x55 sound suppressor_2x1_(2,1)",
  "ASh-12 folding front sight_1x1_(1,1)",
  "ASh-12 polymer handguard_1x1_(1,1)",
  "ASh-12 rear sight carry handle_1x1_(1,1)",
  "ASh-12 vertical foregrip_1x1_(1,1)",
  "AVT-40 7.62x54R 15-round magazine_1x2_(1,1)",
  "AVT-40 7.62x54R 15-round magazine_1x2_(1,2)",
  "AVT-40 wooden stock _4x1_(1,1)",
  "AVT-40 wooden stock _4x1_(2,1)",
  "AVT-40 wooden stock _4x1_(3,1)",
  "AVT-40 wooden stock _4x1_(4,1)",
  "AWC PSR .338 LM muzzle brake_1x1_(1,1)",
  "AWC PSR 7.62x51 muzzle brake_1x1_(1,1)",
  "AWC PSR muzzle brake protector_1x1_(1,1)",
  "AWC Thor PSR XL multi-caliber sound suppressor_2x1_(1,1)",
  "AWC Thor PSR XL multi-caliber sound suppressor_2x1_(2,1)",
  "Abandoned factory marked key_1x1_(1,1)",
  "Adrenaline injector_1x1_(1,1)",
  "Advanced current converter_2x2_(1,1)",
  "Advanced current converter_2x2_(1,2)",
  "Advanced current converter_2x2_(2,1)",
  "Advanced current converter_2x2_(2,2)",
  "Aimpoint ACRO P-1 reflex sight_1x1_(1,1)",
  "Aimpoint CompM4 reflex sight_1x1_(1,1)",
  "Aimpoint LRP mount for CompM4 sights_1x1_(1,1)",
  "Aimpoint Micro H-2 Standard Mount_1x1_(1,1)",
  "Aimpoint Micro H-2 reflex sight_1x1_(1,1)",
  "Aimpoint Micro Spacer High_1x1_(1,1)",
  "Aimpoint Micro Standard Mount_1x1_(1,1)",
  "Aimpoint Micro T-1 reflex sight_1x1_(1,1)",
  "Aimpoint PRO reflex sight_1x1_(1,1)",
  "Aimpoint QRP2 mount for CompM4@PRO sights_1x1_(1,1)",
  "Aimpoint Standard Spacer_1x1_(1,1)",
  "Alexander Arms 10 inch rail_1x1_(1,1)",
  "Alexander Arms 3 inch rail_1x1_(1,1)",
  "Alkaline cleaner for heat exchangers_1x2_(1,1)",
  "Alkaline cleaner for heat exchangers_1x2_(1,2)",
  "Alpha Dog Alpha 9 9x19 sound suppressor_2x1_(1,1)",
  "Alpha Dog Alpha 9 9x19 sound suppressor_2x1_(2,1)",
  "Aluminum splint_1x1_(1,1)",
  "Alyonka chocolate bar_1x1_(1,1)",
  "Ammunition case_2x2_(1,1)",
  "Ammunition case_2x2_(1,2)",
  "Ammunition case_2x2_(2,1)",
  "Ammunition case_2x2_(2,2)",
  "Analgin painkillers_1x1_(1,1)",
  "Analog thermometer_1x2_(1,1)",
  "Analog thermometer_1x2_(1,2)",
  "Antique teapot_1x2_(1,1)",
  "Antique teapot_1x2_(1,2)",
  "Antique vase_2x2_(1,1)",
  "Antique vase_2x2_(1,2)",
  "Antique vase_2x2_(2,1)",
  "Antique vase_2x2_(2,2)",
  "Apollo Soyuz cigarettes_1x1_(1,1)",
  "Aquamari water bottle with filter_1x2_(1,1)",
  "Aquamari water bottle with filter_1x2_(1,2)",
  "Aquapeps water purification tablets_1x1_(1,1)",
  "Aramid fiber fabric_2x1_(1,1)",
  "Aramid fiber fabric_2x1_(2,1)",
  "Archive room key_1x1_(1,1)",
  "Armasight N-15 Night Vision Goggles_2x1_(1,1)",
  "Armasight N-15 Night Vision Goggles_2x1_(2,1)",
  "Armasight Vulcan MG 3.5x Bravo night vision scope_2x1_(1,1)",
  "Armasight Vulcan MG 3.5x Bravo night vision scope_2x1_(2,1)",
  "Armasight Vulcan MG scope eyecup_1x1_(1,1)",
  "Armasight Vulcan universal base_1x1_(1,1)",
  "Armasight Zeus universal base_1x1_(1,1)",
  "Armasight Zeus-Pro 640 2-8x50 30Hz thermal scope_2x1_(1,1)",
  "Armasight Zeus-Pro 640 2-8x50 30Hz thermal scope_2x1_(2,1)",
  "Armasight Zeus-Pro scope eyecup_1x1_(1,1)",
  "Army bandage_1x1_(1,1)",
  "Army crackers_1x1_(1,1)",
  "Armytek Predator Pro v3 XHP35 HI flashlight_1x1_(1,1)",
  "Aseptic bandage_1x1_(1,1)",
  "Aspect company office key_1x1_(1,1)",
  "Augmentin antibiotic pills_1x1_(1,1)",
  "Awl_1x1_(1,1)",
  "Axel parrot figurine_1x2_(1,1)",
  "Axel parrot figurine_1x2_(1,2)",
  "Axion Kobra EKP-1S-03 reflex sight (Dovetail)_1x1_(1,1)",
  "Axion Kobra EKP-8-02 reflex sight (Dovetail)_1x1_(1,1)",
  "Axion Kobra EKP-8-18 reflex sight_1x1_(1,1)",
  "Axion Kobra dovetail mount_1x1_(1,1)",
  "Axion Kobra sight shade_1x1_(1,1)",
  "B&T QD NAR mount for Aimpoint ACRO_1x1_(1,1)",
  "BCM GUNFIGHTER MOD 3 vertical foregrip_1x1_(1,1)",
  "BEAR Buddy plush toy_2x2_(1,1)",
  "BEAR Buddy plush toy_2x2_(1,2)",
  "BEAR Buddy plush toy_2x2_(2,1)",
  "BEAR Buddy plush toy_2x2_(2,2)",
  "BEAR operative figurine_1x2_(1,1)",
  "BEAR operative figurine_1x2_(1,2)",
  "Backup hideout key_1x1_(1,1)",
  "BakeEzy cook book_1x2_(1,1)",
  "BakeEzy cook book_1x2_(1,2)",
  "Battered antique book_1x2_(1,1)",
  "Battered antique book_1x2_(1,2)",
  "BelOMO PK-06 reflex sight_1x1_(1,1)",
  "BelOMO PK-AA dovetail reflex sight_1x1_(1,1)",
  "BelOMO PSO-1 4x24 scope_2x1_(1,1)",
  "BelOMO PSO-1 4x24 scope_2x1_(2,1)",
  "BelOMO PSO-1M2 4x24 scope_2x1_(1,1)",
  "BelOMO PSO-1M2 4x24 scope_2x1_(2,1)",
  "BelOMO PSO-1M2-1 4x24 scope_2x1_(1,1)",
  "BelOMO PSO-1M2-1 4x24 scope_2x1_(2,1)",
  "Beluga restaurant director key_1x1_(1,1)",
  "Benelli M3 Ghost Ring rear sight_1x1_(1,1)",
  "Benelli M3 Mesa Tactical Urbino stock_2x2_(1,1)",
  "Benelli M3 Mesa Tactical Urbino stock_2x2_(1,2)",
  "Benelli M3 Mesa Tactical Urbino stock_2x2_(2,1)",
  "Benelli M3 Mesa Tactical Urbino stock_2x2_(2,2)",
  "Benelli M3 Super 90 12ga 5-shell magazine cap_1x1_(1,1)",
  "Benelli M3 Super 90 12ga 500mm barrel_3x1_(1,1)",
  "Benelli M3 Super 90 12ga 500mm barrel_3x1_(2,1)",
  "Benelli M3 Super 90 12ga 500mm barrel_3x1_(3,1)",
  "Benelli M3 Super 90 12ga 7-shell magazine_2x1_(1,1)",
  "Benelli M3 Super 90 12ga 7-shell magazine_2x1_(2,1)",
  "Benelli M3 Super 90 12ga Toni System 11-shell magazine_3x1_(1,1)",
  "Benelli M3 Super 90 12ga Toni System 11-shell magazine_3x1_(2,1)",
  "Benelli M3 Super 90 12ga Toni System 11-shell magazine_3x1_(3,1)",
  "Benelli M3 Super 90 12ga Toni System 13-shell magazine_4x1_(1,1)",
  "Benelli M3 Super 90 12ga Toni System 13-shell magazine_4x1_(2,1)",
  "Benelli M3 Super 90 12ga Toni System 13-shell magazine_4x1_(3,1)",
  "Benelli M3 Super 90 12ga Toni System 13-shell magazine_4x1_(4,1)",
  "Benelli M3 Super 90 12ga Toni System 9-shell magazine_3x1_(1,1)",
  "Benelli M3 Super 90 12ga Toni System 9-shell magazine_3x1_(2,1)",
  "Benelli M3 Super 90 12ga Toni System 9-shell magazine_3x1_(3,1)",
  "Benelli M3 Super 90 charging handle_1x1_(1,1)",
  "Benelli M3 Super 90 upper receiver top rail_1x1_(1,1)",
  "Benelli M3 forend_2x1_(1,1)",
  "Benelli M3 forend_2x1_(2,1)",
  "Benelli M3 telescopic stock pistol grip_1x1_(1,1)",
  "Benelli M3 telescopic stock_2x1_(1,1)",
  "Benelli M3 telescopic stock_2x1_(2,1)",
  "Bolts_1x1_(1,1)",
  "Bottle of 'Norvinskiy Yadreniy' premium kvass (0.6L)_1x2_(1,1)",
  "Bottle of 'Norvinskiy Yadreniy' premium kvass (0.6L)_1x2_(1,2)",
  "Bottle of Dan Jackiel whiskey_1x2_(1,1)",
  "Bottle of Dan Jackiel whiskey_1x2_(1,2)",
  "Bottle of Fierce Hatchling moonshine_1x2_(1,1)",
  "Bottle of Fierce Hatchling moonshine_1x2_(1,2)",
  "Bottle of OLOLO Multivitamins_1x1_(1,1)",
  "Bottle of Pevko Light beer_1x2_(1,1)",
  "Bottle of Pevko Light beer_1x2_(1,2)",
  "Bottle of Tarkovskaya vodka_1x2_(1,1)",
  "Bottle of Tarkovskaya vodka_1x2_(1,2)",
  "Bottle of hydrogen peroxide_1x1_(1,1)",
  "Bottle of saline solution_1x1_(1,1)",
  "Bottle of water (0.6L)_1x2_(1,1)",
  "Bottle of water (0.6L)_1x2_(1,2)",
  "Broken GPhone X smartphone_1x1_(1,1)",
  "Broken GPhone smartphone_1x1_(1,1)",
  "Broken LCD_1x2_(1,1)",
  "Broken LCD_1x2_(1,2)",
  "Bronze lion figurine_3x2_(1,1)",
  "Bronze lion figurine_3x2_(1,2)",
  "Bronze lion figurine_3x2_(2,1)",
  "Bronze lion figurine_3x2_(2,2)",
  "Bronze lion figurine_3x2_(3,1)",
  "Bronze lion figurine_3x2_(3,2)",
  "Bulbex cable cutter_1x2_(1,1)",
  "Bulbex cable cutter_1x2_(1,2)",
  "Bundle of wires_1x1_(1,1)",
  "Burris AR-P.E.P.R. 30mm ring scope mount_2x1_(1,1)",
  "Burris AR-P.E.P.R. 30mm ring scope mount_2x1_(2,1)",
  "Burris FastFire 3 reflex sight_1x1_(1,1)",
  "Burris FastFire Weaver Base_1x1_(1,1)",
  "Burris FullField TAC30 1-4x24 30mm riflescope_2x1_(1,1)",
  "Burris FullField TAC30 1-4x24 30mm riflescope_2x1_(2,1)",
  "CALOK-B hemostatic applicator_1x1_(1,1)",
  "CAT hemostatic tourniquet_1x1_(1,1)",
  "CMMG buffer tube_2x1_(1,1)",
  "CMMG buffer tube_2x1_(2,1)",
  "CMS surgical kit_2x1_(1,1)",
  "CMS surgical kit_2x1_(2,1)",
  "CNC Guns KeyMod 2 inch rail_1x1_(1,1)",
  "CNC Guns KeyMod 4 inch rail_1x1_(1,1)",
  "CNC Guns OV-SV98 M12B stock_2x1_(1,1)",
  "CNC Guns OV-SV98 M12B stock_2x1_(2,1)",
  "CPU fan_1x1_(1,1)",
  "Can of Dr. Lupo's coffee beans_1x1_(1,1)",
  "Can of Hot Rod energy drink_1x1_(1,1)",
  "Can of Ice Green tea_1x1_(1,1)",
  "Can of Majaica coffee beans_1x1_(1,1)",
  "Can of Max Energy energy drink_1x1_(1,1)",
  "Can of RatCola soda_1x1_(1,1)",
  "Can of TarCola soda_1x1_(1,1)",
  "Can of beef stew (Large)_1x1_(1,1)",
  "Can of beef stew (Small)_1x1_(1,1)",
  "Can of condensed milk_1x1_(1,1)",
  "Can of green peas_1x1_(1,1)",
  "Can of herring_1x1_(1,1)",
  "Can of humpback salmon_1x1_(1,1)",
  "Can of pacific saury_1x1_(1,1)",
  "Can of sprats_1x1_(1,1)",
  "Can of squash spread_1x1_(1,1)",
  "Can of thermite_1x1_(1,1)",
  "Can of white salt_1x1_(1,1)",
  "Canister with purified water_2x2_(1,1)",
  "Canister with purified water_2x2_(1,2)",
  "Canister with purified water_2x2_(2,1)",
  "Canister with purified water_2x2_(2,2)",
  "Capacitors_1x1_(1,1)",
  "Car battery_3x2_(1,1)",
  "Car battery_3x2_(1,2)",
  "Car battery_3x2_(2,1)",
  "Car battery_3x2_(2,2)",
  "Car battery_3x2_(3,1)",
  "Car battery_3x2_(3,2)",
  "Car dealership closed section key_1x1_(1,1)",
  "Car dealership director's office room key_1x1_(1,1)",
  "Car first aid kit_2x1_(1,1)",
  "Car first aid kit_2x1_(2,1)",
  "Cargo container mesh door key_1x1_(1,1)",
  "Cat figurine_1x3_(1,1)",
  "Cat figurine_1x3_(1,2)",
  "Cat figurine_1x3_(1,3)",
  "Chain with Prokill medallion_1x1_(1,1)",
  "Chainlet_1x1_(1,1)",
  "Chekannaya 15 apartment key_1x1_(1,1)",
  "Chiappa Rhino .357 6-round cylinder_1x1_(1,1)",
  "Chiappa Rhino 9x19 6-round cylinder_1x1_(1,1)",
  "Chiappa Rhino Red Fiber Optic front sight_1x1_(1,1)",
  "Chiappa Rhino Red Fiber Optic rear sight_1x1_(1,1)",
  "Chiappa Rhino front sight_1x1_(1,1)",
  "Chiappa Rhino plastic pistol grip_1x1_(1,1)",
  "Chiappa Rhino rear sight_1x1_(1,1)",
  "Chiappa Rhino wooden pistol grip_1x1_(1,1)",
  "Christmas tree ornament (Red)_1x1_(1,1)",
  "Christmas tree ornament (Silver)_1x1_(1,1)",
  "Christmas tree ornament (Violet)_1x1_(1,1)",
  "Classic matches_1x1_(1,1)",
  "Clin window cleaner_1x2_(1,1)",
  "Clin window cleaner_1x2_(1,2)",
  "Concordia apartment 34 room key_1x1_(1,1)",
  "Concordia apartment 63 room key_1x1_(1,1)",
  "Concordia apartment 64 key_1x1_(1,1)",
  "Concordia apartment 64 office room key_1x1_(1,1)",
  "Concordia apartment 8 home cinema key_1x1_(1,1)",
  "Concordia apartment 8 room key_1x1_(1,1)",
  "Concordia security room key_1x1_(1,1)",
  "Conference room key_1x1_(1,1)",
  "Construction measuring tape_1x1_(1,1)",
  "Construction site bunkhouse key_1x1_(1,1)",
  "Convenience store storage room key_1x1_(1,1)",
  "Cordura polyamide fabric_2x1_(1,1)",
  "Cordura polyamide fabric_2x1_(2,1)",
  "Corrugated hose_2x1_(1,1)",
  "Corrugated hose_2x1_(2,1)",
  "Cottage back door key_1x1_(1,1)",
  "Cottage safe key_1x1_(1,1)",
  "Crickent lighter_1x1_(1,1)",
  "Cultist figurine_1x2_(1,1)",
  "Cultist figurine_1x2_(1,2)",
  "Custom Guns handguard rail_1x1_(1,1)",
  "Customs plan map_1x1_(1,1)",
  "Cyclon rechargeable battery_2x1_(1,1)",
  "Cyclon rechargeable battery_2x1_(2,1)",
  "D Size battery_1x1_(1,1)",
  "DVD drive_2x1_(1,1)",
  "DVD drive_2x1_(2,1)",
  "DVL-10 7.62x51 10-round magazine_1x1_(1,1)",
  "DVL-10 7.62x51 500mm barrel_3x1_(1,1)",
  "DVL-10 7.62x51 500mm barrel_3x1_(2,1)",
  "DVL-10 7.62x51 500mm barrel_3x1_(3,1)",
  "DVL-10 7.62x51 muzzle device_1x1_(1,1)",
  "DVL-10 Lobaev Arms stock_2x1_(1,1)",
  "DVL-10 Lobaev Arms stock_2x1_(2,1)",
  "DVL-10 M2 7.62x51 660mm barrel_4x1_(1,1)",
  "DVL-10 M2 7.62x51 660mm barrel_4x1_(2,1)",
  "DVL-10 M2 7.62x51 660mm barrel_4x1_(3,1)",
  "DVL-10 M2 7.62x51 660mm barrel_4x1_(4,1)",
  "DVL-10 M2 7.62x51 muzzle brake_1x1_(1,1)",
  "DVL-10 M2 handguard_2x1_(1,1)",
  "DVL-10 M2 handguard_2x1_(2,1)",
  "Damaged hard drive_2x1_(1,1)",
  "Damaged hard drive_2x1_(2,1)",
  "Daniel Defense 25mm accessory ring mount_1x1_(1,1)",
  "Daniel Defense Enhanced M-LOK Vertical Foregrip (Black)_1x1_(1,1)",
  "Daniel Defense Enhanced M-LOK Vertical Foregrip (Coyote Brown)_1x1_(1,1)",
  "Daniel Defense TCS 12mm Buttpad _1x1_(1,1)",
  "Daniel Defense TCS 20mm Buttpad _1x1_(1,1)",
  "Daniel Defense WAVE QD sound suppressor_2x1_(1,1)",
  "Daniel Defense WAVE QD sound suppressor_2x1_(2,1)",
  "Deadlyslob's beard oil_1x1_(1,1)",
  "Ded Moroz figurine_1x2_(1,1)",
  "Ded Moroz figurine_1x2_(1,2)",
  "Delta-Tek Sprut mount for pump-action shotguns_1x1_(1,1)",
  "DeltaPoint Cross Slot Mount base_1x1_(1,1)",
  "Desert Tech 5.56x45 flash hider_1x1_(1,1)",
  "Desert Tech 7.62x51 flash hider_1x1_(1,1)",
  "Diary_1x2_(1,1)",
  "Diary_1x2_(1,2)",
  "Disposable syringe_1x1_(1,1)",
  "Dogtag BEAR_1x1_(1,1)",
  "Dogtag USEC_1x1_(1,1)",
  "Dorm guard desk key_1x1_(1,1)",
  "Dorm room 103 key_1x1_(1,1)",
  "Dorm room 104 key_1x1_(1,1)",
  "Dorm room 105 key_1x1_(1,1)",
  "Dorm room 108 key_1x1_(1,1)",
  "Dorm room 110 key_1x1_(1,1)",
  "Dorm room 114 key_1x1_(1,1)",
  "Dorm room 118 key_1x1_(1,1)",
  "Dorm room 203 key_1x1_(1,1)",
  "Dorm room 204 key_1x1_(1,1)",
  "Dorm room 206 key_1x1_(1,1)",
  "Dorm room 214 key_1x1_(1,1)",
  "Dorm room 218 key_1x1_(1,1)",
  "Dorm room 220 key_1x1_(1,1)",
  "Dorm room 303 key_1x1_(1,1)",
  "Dorm room 306 key_1x1_(1,1)",
  "Dorm room 308 key_1x1_(1,1)",
  "Dorm room 314 marked key_1x1_(1,1)",
  "Dorm room 315 key_1x1_(1,1)",
  "DoubleStar ACE recoil pad_1x1_(1,1)",
  "Dry fuel_1x1_(1,1)",
  "Duct tape_1x1_(1,1)",
  "ELCAN Specter HCO holographic sight_1x1_(1,1)",
  "ELCAN SpecterDR 1x@4x scope (FDE)_2x1_(1,1)",
  "ELCAN SpecterDR 1x@4x scope (FDE)_2x1_(2,1)",
  "ELCAN SpecterDR 1x@4x scope_2x1_(1,1)",
  "ELCAN SpecterDR 1x@4x scope_2x1_(2,1)",
  "EMERCOM medical unit key_1x1_(1,1)",
  "EOTech 553 holographic sight_1x1_(1,1)",
  "EOTech EXPS3 holographic sight (Tan)_1x1_(1,1)",
  "EOTech HHS-1 hybrid sight (Tan)_2x1_(1,1)",
  "EOTech HHS-1 hybrid sight (Tan)_2x1_(2,1)",
  "EOTech HHS-1 hybrid sight_2x1_(1,1)",
  "EOTech HHS-1 hybrid sight_2x1_(2,1)",
  "EOTech Vudu 1-6x24 30mm riflescope_2x1_(1,1)",
  "EOTech Vudu 1-6x24 30mm riflescope_2x1_(2,1)",
  "EOTech XPS3-0 holographic sight_1x1_(1,1)",
  "EOTech XPS3-2 holographic sight_1x1_(1,1)",
  "ETMI-019 shotgun rail mount_1x1_(1,1)",
  "Electric drill_2x2_(1,1)",
  "Electric drill_2x2_(1,2)",
  "Electric drill_2x2_(2,1)",
  "Electric drill_2x2_(2,2)",
  "Electric motor_2x2_(1,1)",
  "Electric motor_2x2_(1,2)",
  "Electric motor_2x2_(2,1)",
  "Electric motor_2x2_(2,2)",
  "Electronic components_1x1_(1,1)",
  "Emelya rye croutons_1x1_(1,1)",
  "Emergency Water Ration_1x1_(1,1)",
  "Empty_1x1_(1,1)",
  "Energy-saving lamp_1x1_(1,1)",
  "Esmarch tourniquet_1x1_(1,1)",
  "Expeditionary fuel tank_2x2_(1,1)",
  "Expeditionary fuel tank_2x2_(1,2)",
  "Expeditionary fuel tank_2x2_(2,1)",
  "Expeditionary fuel tank_2x2_(2,2)",
  "F-1 hand grenade_1x1_(1,1)",
  "FAB Defense buffer tube for AGR-870_2x1_(1,1)",
  "FAB Defense buffer tube for AGR-870_2x1_(2,1)",
  "FAB Defense protection cap for AGR-870_1x1_(1,1)",
  "FAL original handguard_2x1_(1,1)",
  "FAL original handguard_2x1_(2,1)",
  "FAL standard dust cover_2x1_(1,1)",
  "FAL standard dust cover_2x1_(2,1)",
  "FLIR RS-32 2.25-9x 35mm 60Hz thermal riflescope_2x1_(1,1)",
  "FLIR RS-32 2.25-9x 35mm 60Hz thermal riflescope_2x1_(2,1)",
  "FN Five-seveN 5.7x28 20-round magazine_1x1_(1,1)",
  "FN Five-seveN 5.7x28 barrel_1x1_(1,1)",
  "FN Five-seveN 5.7x28 threaded barrel_1x1_(1,1)",
  "FN Five-seveN MK2 RMR mount_1x1_(1,1)",
  "FN Five-seveN MK2 front sight_1x1_(1,1)",
  "FN Five-seveN MK2 pistol slide_2x1_(1,1)",
  "FN Five-seveN MK2 pistol slide_2x1_(2,1)",
  "FN Five-seveN MK2 rear sight_1x1_(1,1)",
  "FN P90 5.7x28 10.5 inch barrel_2x1_(1,1)",
  "FN P90 5.7x28 10.5 inch barrel_2x1_(2,1)",
  "FN P90 5.7x28 16 inch barrel_3x1_(1,1)",
  "FN P90 5.7x28 16 inch barrel_3x1_(2,1)",
  "FN P90 5.7x28 16 inch barrel_3x1_(3,1)",
  "FN P90 5.7x28 50-round magazine_2x1_(1,1)",
  "FN P90 5.7x28 50-round magazine_2x1_(2,1)",
  "FN P90 5.7x28 flash hider_1x1_(1,1)",
  "FN P90 5.7x28 upper receiver_2x1_(1,1)",
  "FN P90 5.7x28 upper receiver_2x1_(2,1)",
  "FN P90 Attenuator 5.7x28 sound suppressor_2x1_(1,1)",
  "FN P90 Attenuator 5.7x28 sound suppressor_2x1_(2,1)",
  "FN P90 Damage Industries buttpad_1x1_(1,1)",
  "FN P90 EFFEN 90 5.7x28 upper receiver_2x1_(1,1)",
  "FN P90 EFFEN 90 5.7x28 upper receiver_2x1_(2,1)",
  "FN P90 EFFEN 90 receiver rail_1x1_(1,1)",
  "FN P90 K&M The Handler charging handle_1x1_(1,1)",
  "FN P90 Ring Sight reflex sight_1x1_(1,1)",
  "FN P90 buttpad_1x1_(1,1)",
  "FN P90 charging handle_1x1_(1,1)",
  "FN P90 stock_3x1_(1,1)",
  "FN P90 stock_3x1_(2,1)",
  "FN P90 stock_3x1_(3,1)",
  "FN P90 upper receiver side rail_1x1_(1,1)",
  "FN P90 upper receiver top rail_1x1_(1,1)",
  "FN PS90 5.7x28 upper receiver_2x1_(1,1)",
  "FN PS90 5.7x28 upper receiver_2x1_(2,1)",
  "FN PS90 stock_3x1_(1,1)",
  "FN PS90 stock_3x1_(2,1)",
  "FN PS90 stock_3x1_(3,1)",
  "FN SCAR Kinetic MREX 6.5 M-LOK rail_2x1_(1,1)",
  "FN SCAR Kinetic MREX 6.5 M-LOK rail_2x1_(2,1)",
  "FN SCAR PWS SRX rail extension_1x1_(1,1)",
  "FN SCAR bottom rail_1x1_(1,1)",
  "FN SCAR charging handle_1x1_(1,1)",
  "FN SCAR cheek rest (FDE)_2x1_(1,1)",
  "FN SCAR cheek rest (FDE)_2x1_(2,1)",
  "FN SCAR cheek rest_2x1_(1,1)",
  "FN SCAR cheek rest_2x1_(2,1)",
  "FN SCAR flip-up front sight_1x1_(1,1)",
  "FN SCAR flip-up rear sight_1x1_(1,1)",
  "FN SCAR folding polymer stock (FDE)_2x1_(1,1)",
  "FN SCAR folding polymer stock (FDE)_2x1_(2,1)",
  "FN SCAR folding polymer stock_2x1_(1,1)",
  "FN SCAR folding polymer stock_2x1_(2,1)",
  "FN SCAR retractable polymer stock (FDE)_2x1_(1,1)",
  "FN SCAR retractable polymer stock (FDE)_2x1_(2,1)",
  "FN SCAR retractable polymer stock_2x1_(1,1)",
  "FN SCAR retractable polymer stock_2x1_(2,1)",
  "FN SCAR rubber buttpad_1x1_(1,1)",
  "FN SCAR side rail_1x1_(1,1)",
  "FN SCAR-H 7.62x51 13 inch barrel_2x1_(1,1)",
  "FN SCAR-H 7.62x51 13 inch barrel_2x1_(2,1)",
  "FN SCAR-H 7.62x51 16 inch barrel_4x1_(1,1)",
  "FN SCAR-H 7.62x51 16 inch barrel_4x1_(2,1)",
  "FN SCAR-H 7.62x51 16 inch barrel_4x1_(3,1)",
  "FN SCAR-H 7.62x51 16 inch barrel_4x1_(4,1)",
  "FN SCAR-H 7.62x51 20 inch barrel_4x1_(1,1)",
  "FN SCAR-H 7.62x51 20 inch barrel_4x1_(2,1)",
  "FN SCAR-H 7.62x51 20 inch barrel_4x1_(3,1)",
  "FN SCAR-H 7.62x51 20 inch barrel_4x1_(4,1)",
  "FN SCAR-H 7.62x51 20-round magazine (FDE)_1x2_(1,1)",
  "FN SCAR-H 7.62x51 20-round magazine (FDE)_1x2_(1,2)",
  "FN SCAR-H 7.62x51 20-round magazine_1x2_(1,1)",
  "FN SCAR-H 7.62x51 20-round magazine_1x2_(1,2)",
  "FN SCAR-H 7.62x51 upper receiver (FDE)_3x1_(1,1)",
  "FN SCAR-H 7.62x51 upper receiver (FDE)_3x1_(2,1)",
  "FN SCAR-H 7.62x51 upper receiver (FDE)_3x1_(3,1)",
  "FN SCAR-H 7.62x51 upper receiver_3x1_(1,1)",
  "FN SCAR-H 7.62x51 upper receiver_3x1_(2,1)",
  "FN SCAR-H 7.62x51 upper receiver_3x1_(3,1)",
  "FN SCAR-L 5.56x45 10 inch barrel_2x1_(1,1)",
  "FN SCAR-L 5.56x45 10 inch barrel_2x1_(2,1)",
  "FN SCAR-L 5.56x45 14 inch barrel_3x1_(1,1)",
  "FN SCAR-L 5.56x45 14 inch barrel_3x1_(2,1)",
  "FN SCAR-L 5.56x45 14 inch barrel_3x1_(3,1)",
  "FN SCAR-L 5.56x45 18 inch barrel_3x1_(1,1)",
  "FN SCAR-L 5.56x45 18 inch barrel_3x1_(2,1)",
  "FN SCAR-L 5.56x45 18 inch barrel_3x1_(3,1)",
  "FN SCAR-L 5.56x45 30-round magazine (FDE)_1x2_(1,1)",
  "FN SCAR-L 5.56x45 30-round magazine (FDE)_1x2_(1,2)",
  "FN SCAR-L 5.56x45 30-round magazine_1x2_(1,1)",
  "FN SCAR-L 5.56x45 30-round magazine_1x2_(1,2)",
  "FN SCAR-L 5.56x45 flash hider_1x1_(1,1)",
  "FN SCAR-L 5.56x45 upper receiver (FDE)_3x1_(1,1)",
  "FN SCAR-L 5.56x45 upper receiver (FDE)_3x1_(2,1)",
  "FN SCAR-L 5.56x45 upper receiver (FDE)_3x1_(3,1)",
  "FN SCAR-L 5.56x45 upper receiver_3x1_(1,1)",
  "FN SCAR-L 5.56x45 upper receiver_3x1_(2,1)",
  "FN SCAR-L 5.56x45 upper receiver_3x1_(3,1)",
  "FP-100 filter absorber_3x3_(1,1)",
  "FP-100 filter absorber_3x3_(1,2)",
  "FP-100 filter absorber_3x3_(1,3)",
  "FP-100 filter absorber_3x3_(2,1)",
  "FP-100 filter absorber_3x3_(2,2)",
  "FP-100 filter absorber_3x3_(2,3)",
  "FP-100 filter absorber_3x3_(3,1)",
  "FP-100 filter absorber_3x3_(3,2)",
  "FP-100 filter absorber_3x3_(3,3)",
  "Factory emergency exit key_1x1_(1,1)",
  "Factory plan map_1x1_(1,1)",
  "Far-forward GPS Signal Amplifier Unit_1x1_(1,1)",
  "Ferfrans CRD 5.56x45 Concussion Reduction Device_1x1_(1,1)",
  "Fierce Blow sledgehammer_2x5_(1,1)",
  "Fierce Blow sledgehammer_2x5_(1,2)",
  "Fierce Blow sledgehammer_2x5_(1,3)",
  "Fierce Blow sledgehammer_2x5_(1,4)",
  "Fierce Blow sledgehammer_2x5_(1,5)",
  "Fierce Blow sledgehammer_2x5_(2,1)",
  "Fierce Blow sledgehammer_2x5_(2,2)",
  "Fierce Blow sledgehammer_2x5_(2,3)",
  "Fierce Blow sledgehammer_2x5_(2,4)",
  "Fierce Blow sledgehammer_2x5_(2,5)",
  "Financial institution office key_1x1_(1,1)",
  "Financial institution small office key_1x1_(1,1)",
  "Flat screwdriver (Long)_1x2_(1,1)",
  "Flat screwdriver (Long)_1x2_(1,2)",
  "Flat screwdriver_1x1_(1,1)",
  "Fleece fabric_2x1_(1,1)",
  "Fleece fabric_2x1_(2,1)",
  "Folding car key_1x1_(1,1)",
  "Fortis Shift tactical foregrip_1x1_(1,1)",
  "Fuel conditioner_1x2_(1,1)",
  "Fuel conditioner_1x2_(1,2)",
  "GBRS Aimpoint Hydra Mount Kit (Black)_2x1_(1,1)",
  "GBRS Aimpoint Hydra Mount Kit (Black)_2x1_(2,1)",
  "GBRS Aimpoint Hydra Mount Kit (FDE)_2x1_(1,1)",
  "GBRS Aimpoint Hydra Mount Kit (FDE)_2x1_(2,1)",
  "GK-02 12ga muzzle brake_1x1_(1,1)",
  "GP coin_1x1_(1,1)",
  "GP-25 'Kostyor' 40mm underbarrel grenade launcher_2x1_(1,1)",
  "GP-25 'Kostyor' 40mm underbarrel grenade launcher_2x1_(2,1)",
  "GPNVG-18 Night Vision goggles_2x1_(1,1)",
  "GPNVG-18 Night Vision goggles_2x1_(2,1)",
  "Gas analyzer_1x2_(1,1)",
  "Gas analyzer_1x2_(1,2)",
  "Gas mask air filter_2x1_(1,1)",
  "Gas mask air filter_2x1_(2,1)",
  "Gas station office key_1x1_(1,1)",
  "Gas station safe key_1x1_(1,1)",
  "Gas station storage room key_1x1_(1,1)",
  "Geiger-Muller counter_1x2_(1,1)",
  "Geiger-Muller counter_1x2_(1,2)",
  "Geissele Super Precision 30mm ring scope mount (DDC)_2x1_(1,1)",
  "Geissele Super Precision 30mm ring scope mount (DDC)_2x1_(2,1)",
  "Geissele Super Precision 30mm ring scope mount_2x1_(1,1)",
  "Geissele Super Precision 30mm ring scope mount_2x1_(2,1)",
  "Geissele Super Precision top ring cap (DDC)_1x1_(1,1)",
  "Geissele Super Precision top ring cap_1x1_(1,1)",
  "Gemtech ONE Direct Thread Mount adapter_1x1_(1,1)",
  "Gemtech ONE multi-caliber sound suppressor_2x1_(1,1)",
  "Gemtech ONE multi-caliber sound suppressor_2x1_(2,1)",
  "Gemtech SFN-57 5.7x28 sound suppressor_2x1_(1,1)",
  "Gemtech SFN-57 5.7x28 sound suppressor_2x1_(2,1)",
  "Glock .45 ACP 13-round magazine_1x1_(1,1)",
  "Glock .45 ACP KRISS G30 MagEx 30-round magazine_1x2_(1,1)",
  "Glock .45 ACP KRISS G30 MagEx 30-round magazine_1x2_(1,2)",
  "Glock 17 9x19 barrel with a compensator_1x1_(1,1)",
  "Glock 17 9x19 barrel_1x1_(1,1)",
  "Glock 17 MOS pistol slide_2x1_(1,1)",
  "Glock 17 MOS pistol slide_2x1_(2,1)",
  "Glock 17 pistol slide_2x1_(1,1)",
  "Glock 17 pistol slide_2x1_(2,1)",
  "Glock 18C 9x19 barrel with a compensator_1x1_(1,1)",
  "Glock 18C pistol slide_2x1_(1,1)",
  "Glock 18C pistol slide_2x1_(2,1)",
  "Glock 19X 9x19 barrel_1x1_(1,1)",
  "Glock 19X front sight_1x1_(1,1)",
  "Glock 19X pistol slide_2x1_(1,1)",
  "Glock 19X pistol slide_2x1_(2,1)",
  "Glock 19X rear sight_1x1_(1,1)",
  "Glock 9x19 'Big Stick' 24-round magazine (Coyote)_1x2_(1,1)",
  "Glock 9x19 'Big Stick' 24-round magazine (Coyote)_1x2_(1,2)",
  "Glock 9x19 'Big Stick' 31-round magazine (Coyote)_1x2_(1,1)",
  "Glock 9x19 'Big Stick' 31-round magazine (Coyote)_1x2_(1,2)",
  "Glock 9x19 'Big Stick' 33-round magazine_1x2_(1,1)",
  "Glock 9x19 'Big Stick' 33-round magazine_1x2_(1,2)",
  "Glock 9x19 17-round magazine_1x1_(1,1)",
  "Glock 9x19 19-round magazine (Coyote)_1x1_(1,1)",
  "Glock 9x19 CARVER Custom 4 Port compensator_1x1_(1,1)",
  "Glock 9x19 CARVER Custom Decelerator 3 Port compensator_1x1_(1,1)",
  "Glock 9x19 Double Diamond flash hider_1x1_(1,1)",
  "Glock 9x19 Double Diamond thread protector_1x1_(1,1)",
  "Glock 9x19 Double Diamond threaded barrel_1x1_(1,1)",
  "Glock 9x19 Fischer Development FD917 sound suppressor_2x1_(1,1)",
  "Glock 9x19 Fischer Development FD917 sound suppressor_2x1_(2,1)",
  "Glock 9x19 Lone Wolf AlphaWolf Bullnose compensator_1x1_(1,1)",
  "Glock 9x19 Lone Wolf AlphaWolf thread protector_1x1_(1,1)",
  "Glock 9x19 Lone Wolf AlphaWolf threaded barrel_1x1_(1,1)",
  "Glock 9x19 Lone Wolf LWD-COMP9 compensator_1x1_(1,1)",
  "Glock 9x19 Magpul PMAG GL9 21-round magazine_1x1_(1,1)",
  "Glock 9x19 SAI threaded barrel_1x1_(1,1)",
  "Glock 9x19 SGM Tactical 50-round drum magazine_1x2_(1,1)",
  "Glock 9x19 SGM Tactical 50-round drum magazine_1x2_(1,2)",
  "Glock 9x19 Strike Industries G4 SlideComp compensator_1x1_(1,1)",
  "Glock Aimtech Tiger Shark sight mount_1x1_(1,1)",
  "Glock Aimtech mount base_1x1_(1,1)",
  "Glock Dead Ringer Snake Eye front sight_1x1_(1,1)",
  "Glock Dead Ringer Snake Eye rear sight_1x1_(1,1)",
  "Glock FAB Defense GLR-17 stock_2x1_(1,1)",
  "Glock FAB Defense GLR-17 stock_2x1_(2,1)",
  "Glock GTL 21 tactical flashlight with laser_1x1_(1,1)",
  "Glock Lone Wolf AlphaWolf Custom pistol slide_2x1_(1,1)",
  "Glock Lone Wolf AlphaWolf Custom pistol slide_2x1_(2,1)",
  "Glock Lone Wolf AlphaWolf pistol slide_2x1_(1,1)",
  "Glock Lone Wolf AlphaWolf pistol slide_2x1_(2,1)",
  "Glock Moto Cut pistol slide_2x1_(1,1)",
  "Glock Moto Cut pistol slide_2x1_(2,1)",
  "Glock Pachmayr Tactical Grip Glove_1x1_(1,1)",
  "Glock Polymer80 PS9 pistol slide_2x1_(1,1)",
  "Glock Polymer80 PS9 pistol slide_2x1_(2,1)",
  "Glock SAI 9x19 thread protector_1x1_(1,1)",
  "Glock TangoDown AAM-01 ACRO mount base_1x1_(1,1)",
  "Glock TruGlo TFX front sight_1x1_(1,1)",
  "Glock TruGlo TFX rear sight_1x1_(1,1)",
  "Glock Viper Cut pistol slide_2x1_(1,1)",
  "Glock Viper Cut pistol slide_2x1_(2,1)",
  "Glock ZEV Tech HEX Gen3 RMR pistol slide_2x1_(1,1)",
  "Glock ZEV Tech HEX Gen3 RMR pistol slide_2x1_(2,1)",
  "Glock ZEV Tech HEX Spartan RMR pistol slide_2x1_(1,1)",
  "Glock ZEV Tech HEX Spartan RMR pistol slide_2x1_(2,1)",
  "Glock ZEV Tech front sight_1x1_(1,1)",
  "Glock ZEV Tech rear sight_1x1_(1,1)",
  "Glock ZEV Tech sight mount cap_1x1_(1,1)",
  "Glock front sight_1x1_(1,1)",
  "Glock rear sight_1x1_(1,1)",
  "Gold skull ring_1x1_(1,1)",
  "Golden 1GPhone smartphone_1x1_(1,1)",
  "Golden Star balm_1x1_(1,1)",
  "Golden egg_1x1_(1,1)",
  "Golden neck chain_1x1_(1,1)",
  "Golden rooster figurine_2x2_(1,1)",
  "Golden rooster figurine_2x2_(1,2)",
  "Golden rooster figurine_2x2_(2,1)",
  "Golden rooster figurine_2x2_(2,2)",
  "Goshan cash register key_1x1_(1,1)",
  "Graphics card_2x1_(1,1)",
  "Graphics card_2x1_(2,1)",
  "GreenBat lithium battery_1x1_(1,1)",
  "Grizzly medical kit_2x2_(1,1)",
  "Grizzly medical kit_2x2_(1,2)",
  "Grizzly medical kit_2x2_(2,1)",
  "Grizzly medical kit_2x2_(2,2)",
  "Gunpowder 'Eagle'_1x2_(1,1)",
  "Gunpowder 'Eagle'_1x2_(1,2)",
  "Gunpowder 'Hawk'_1x2_(1,1)",
  "Gunpowder 'Hawk'_1x2_(1,2)",
  "Gunpowder 'Kite'_1x2_(1,1)",
  "Gunpowder 'Kite'_1x2_(1,2)",
  "HEP station storage room key_1x1_(1,1)",
  "HK 416A5 5.56x45 10.6 inch barrel_2x1_(1,1)",
  "HK 416A5 5.56x45 10.6 inch barrel_2x1_(2,1)",
  "HK 416A5 5.56x45 11 inch barrel_2x1_(1,1)",
  "HK 416A5 5.56x45 11 inch barrel_2x1_(2,1)",
  "HK 416A5 5.56x45 14.5 inch barrel_3x1_(1,1)",
  "HK 416A5 5.56x45 14.5 inch barrel_3x1_(2,1)",
  "HK 416A5 5.56x45 14.5 inch barrel_3x1_(3,1)",
  "HK 416A5 5.56x45 16.5 inch barrel with flip-up front sight_3x1_(1,1)",
  "HK 416A5 5.56x45 16.5 inch barrel with flip-up front sight_3x1_(2,1)",
  "HK 416A5 5.56x45 16.5 inch barrel with flip-up front sight_3x1_(3,1)",
  "HK 416A5 5.56x45 20 inch barrel_4x1_(1,1)",
  "HK 416A5 5.56x45 20 inch barrel_4x1_(2,1)",
  "HK 416A5 5.56x45 20 inch barrel_4x1_(3,1)",
  "HK 416A5 5.56x45 20 inch barrel_4x1_(4,1)",
  "HK 416A5 5.56x45 upper receiver_2x1_(1,1)",
  "HK 416A5 5.56x45 upper receiver_2x1_(2,1)",
  "HK 416A5 MRS KeyMod 14 inch handguard_3x1_(1,1)",
  "HK 416A5 MRS KeyMod 14 inch handguard_3x1_(2,1)",
  "HK 416A5 MRS KeyMod 14 inch handguard_3x1_(3,1)",
  "HK 416A5 Midwest Industries 13.5 inch M-LOK handguard_3x1_(1,1)",
  "HK 416A5 Midwest Industries 13.5 inch M-LOK handguard_3x1_(2,1)",
  "HK 416A5 Midwest Industries 13.5 inch M-LOK handguard_3x1_(3,1)",
  "HK 416A5 Midwest Industries 9 inch M-LOK handguard_2x1_(1,1)",
  "HK 416A5 Midwest Industries 9 inch M-LOK handguard_2x1_(2,1)",
  "HK 416A5 Quad Rail Extended handguard_3x1_(1,1)",
  "HK 416A5 Quad Rail Extended handguard_3x1_(2,1)",
  "HK 416A5 Quad Rail Extended handguard_3x1_(3,1)",
  "HK 416A5 Quad Rail handguard with flip-up front sight_2x1_(1,1)",
  "HK 416A5 Quad Rail handguard with flip-up front sight_2x1_(2,1)",
  "HK 416A5 Quad Rail handguard_2x1_(1,1)",
  "HK 416A5 Quad Rail handguard_2x1_(2,1)",
  "HK 416A5 Strike Industries CRUX 15 inch M-LOK handguard_3x1_(1,1)",
  "HK 416A5 Strike Industries CRUX 15 inch M-LOK handguard_3x1_(2,1)",
  "HK 416A5 Strike Industries CRUX 15 inch M-LOK handguard_3x1_(3,1)",
  "HK 416A5 TROY M-LOK 13 inch handguard_3x1_(1,1)",
  "HK 416A5 TROY M-LOK 13 inch handguard_3x1_(2,1)",
  "HK 416A5 TROY M-LOK 13 inch handguard_3x1_(3,1)",
  "HK 416A5 flip-up rear sight_1x1_(1,1)",
  "HK 416A5 low profile gas block_1x1_(1,1)",
  "HK Adjustable Buttstock cheek rest_1x1_(1,1)",
  "HK Enhanced Tube buffer tube_2x1_(1,1)",
  "HK Enhanced Tube buffer tube_2x1_(2,1)",
  "HK G28 7.62x51 upper receiver_2x1_(1,1)",
  "HK G28 7.62x51 upper receiver_2x1_(2,1)",
  "HK G28 B&T QD 7.62x51 sound suppressor_2x1_(1,1)",
  "HK G28 B&T QD 7.62x51 sound suppressor_2x1_(2,1)",
  "HK G28 buffer tube_2x1_(1,1)",
  "HK G28 buffer tube_2x1_(2,1)",
  "HK G36 2-vent handguard_2x1_(1,1)",
  "HK G36 2-vent handguard_2x1_(2,1)",
  "HK G36 4-vent handguard_2x1_(1,1)",
  "HK G36 4-vent handguard_2x1_(2,1)",
  "HK G36 5.56x45 228mm barrel_2x1_(1,1)",
  "HK G36 5.56x45 228mm barrel_2x1_(2,1)",
  "HK G36 5.56x45 30-round magazine_1x2_(1,1)",
  "HK G36 5.56x45 30-round magazine_1x2_(1,2)",
  "HK G36 5.56x45 318mm barrel_3x1_(1,1)",
  "HK G36 5.56x45 318mm barrel_3x1_(2,1)",
  "HK G36 5.56x45 318mm barrel_3x1_(3,1)",
  "HK G36 5.56x45 4-prong flash hider_1x1_(1,1)",
  "HK G36 5.56x45 480mm barrel_4x1_(1,1)",
  "HK G36 5.56x45 480mm barrel_4x1_(2,1)",
  "HK G36 5.56x45 480mm barrel_4x1_(3,1)",
  "HK G36 5.56x45 480mm barrel_4x1_(4,1)",
  "HK G36 5.56x45 flash hider_1x1_(1,1)",
  "HK G36 6-vent handguard_3x1_(1,1)",
  "HK G36 6-vent handguard_3x1_(2,1)",
  "HK G36 6-vent handguard_3x1_(3,1)",
  "HK G36 Hensoldt HKV 3x carry handle_2x1_(1,1)",
  "HK G36 Hensoldt HKV 3x carry handle_2x1_(2,1)",
  "HK G36 Hensoldt HKV ZF 1.5x carry handle_2x1_(1,1)",
  "HK G36 Hensoldt HKV ZF 1.5x carry handle_2x1_(2,1)",
  "HK G36 KV adjustable stock_2x1_(1,1)",
  "HK G36 KV adjustable stock_2x1_(2,1)",
  "HK G36 STANAG magwell_1x1_(1,1)",
  "HK G36 bipod_2x1_(1,1)",
  "HK G36 bipod_2x1_(2,1)",
  "HK G36 bottom handguard rail_1x1_(1,1)",
  "HK G36 front sight_1x1_(1,1)",
  "HK G36 gas block_1x1_(1,1)",
  "HK G36 hand stop_1x1_(1,1)",
  "HK G36 magwell_1x1_(1,1)",
  "HK G36 optic rail with flip-up sights_2x1_(1,1)",
  "HK G36 optic rail with flip-up sights_2x1_(2,1)",
  "HK G36 polymer stock_2x1_(1,1)",
  "HK G36 polymer stock_2x1_(2,1)",
  "HK G36 rear sight_1x1_(1,1)",
  "HK G36 side handguard rail_1x1_(1,1)",
  "HK G36 sight mount_2x1_(1,1)",
  "HK G36 sight mount_2x1_(2,1)",
  "HK G36C 5.56x45 4-prong flash hider_1x1_(1,1)",
  "HK MP5 3-lug thread protector_1x1_(1,1)",
  "HK MP5 9x19 20-round magazine_1x1_(1,1)",
  "HK MP5 9x19 30-round magazine_1x2_(1,1)",
  "HK MP5 9x19 30-round magazine_1x2_(1,2)",
  "HK MP5 9x19 X Products X-5 50-round drum magazine_1x2_(1,1)",
  "HK MP5 9x19 X Products X-5 50-round drum magazine_1x2_(1,2)",
  "HK MP5 9x19 upper receiver_2x1_(1,1)",
  "HK MP5 9x19 upper receiver_2x1_(2,1)",
  "HK MP5 A2 stock_2x1_(1,1)",
  "HK MP5 A2 stock_2x1_(2,1)",
  "HK MP5 A3 old model stock_2x1_(1,1)",
  "HK MP5 A3 old model stock_2x1_(2,1)",
  "HK MP5 B&T QD 9x19 muzzle brake_1x1_(1,1)",
  "HK MP5 B&T TL-99 aluminum handguard_2x1_(1,1)",
  "HK MP5 B&T TL-99 aluminum handguard_2x1_(2,1)",
  "HK MP5 B&T tri-rail receiver mount_2x1_(1,1)",
  "HK MP5 B&T tri-rail receiver mount_2x1_(2,1)",
  "HK MP5 CAA HX-5 handguard_2x1_(1,1)",
  "HK MP5 CAA HX-5 handguard_2x1_(2,1)",
  "HK MP5 Drum rear sight_1x1_(1,1)",
  "HK MP5 End Cap stock_1x1_(1,1)",
  "HK MP5 MFI HK universal low profile scope mount_1x1_(1,1)",
  "HK MP5 Navy Style 3-lug suppressor adapter_1x1_(1,1)",
  "HK MP5 Noveske-style 9x19 muzzle brake-compensator_1x1_(1,1)",
  "HK MP5 PTR Tri-Rail handguard_2x1_(1,1)",
  "HK MP5 PTR Tri-Rail handguard_2x1_(2,1)",
  "HK MP5 Wide Tropical polymer handguard_2x1_(1,1)",
  "HK MP5 Wide Tropical polymer handguard_2x1_(2,1)",
  "HK MP5 cocking handle_1x1_(1,1)",
  "HK MP5K 9x19 upper receiver_2x1_(1,1)",
  "HK MP5K 9x19 upper receiver_2x1_(2,1)",
  "HK MP5K End Cap stock_1x1_(1,1)",
  "HK MP5K cocking handle_1x1_(1,1)",
  "HK MP5K polymer handguard_1x1_(1,1)",
  "HK MP5SD 9x19 sound suppressor_2x1_(1,1)",
  "HK MP5SD 9x19 sound suppressor_2x1_(2,1)",
  "HK MP5SD 9x19 upper receiver_2x1_(1,1)",
  "HK MP5SD 9x19 upper receiver_2x1_(2,1)",
  "HK MP5SD B&T tri-rail ring mount_1x1_(1,1)",
  "HK MP5SD polymer handguard_2x1_(1,1)",
  "HK MP5SD polymer handguard_2x1_(2,1)",
  "HK MP7 4.6x30 20-round magazine_1x1_(1,1)",
  "HK MP7 4.6x30 30-round magazine_1x2_(1,1)",
  "HK MP7 4.6x30 30-round magazine_1x2_(1,2)",
  "HK MP7 4.6x30 40-round magazine_1x2_(1,1)",
  "HK MP7 4.6x30 40-round magazine_1x2_(1,2)",
  "HK MP7 B&T Rotex 2 4.6x30 sound suppressor_2x1_(1,1)",
  "HK MP7 B&T Rotex 2 4.6x30 sound suppressor_2x1_(2,1)",
  "HK MP7 flip-up front sight_1x1_(1,1)",
  "HK MP7 flip-up rear sight_1x1_(1,1)",
  "HK MP7A1 4.6x30 flash hider_1x1_(1,1)",
  "HK MP7A1 stock_2x1_(1,1)",
  "HK MP7A1 stock_2x1_(2,1)",
  "HK MP7A2 stock_2x1_(1,1)",
  "HK MP7A2 stock_2x1_(2,1)",
  "HK Prolonged 7.62x51 flash hider_1x1_(1,1)",
  "HK Sturmgriff foregrip_1x1_(1,1)",
  "HK UMP .45 ACP 25-round magazine_1x2_(1,1)",
  "HK UMP .45 ACP 25-round magazine_1x2_(1,2)",
  "HK UMP .45 ACP 8 inch barrel_2x1_(1,1)",
  "HK UMP .45 ACP 8 inch barrel_2x1_(2,1)",
  "HK UMP .45 ACP 8 inch threaded barrel_2x1_(1,1)",
  "HK UMP .45 ACP 8 inch threaded barrel_2x1_(2,1)",
  "HK UMP B&T OEM .45 ACP sound suppressor_2x1_(1,1)",
  "HK UMP B&T OEM .45 ACP sound suppressor_2x1_(2,1)",
  "HK UMP bottom handguard rail_1x1_(1,1)",
  "HK UMP polymer stock_2x1_(1,1)",
  "HK UMP polymer stock_2x1_(2,1)",
  "HK UMP side handguard rail_1x1_(1,1)",
  "HK USP .45 ACP 12-round magazine_1x1_(1,1)",
  "HK USP .45 ACP Elite compensator_1x1_(1,1)",
  "HK USP .45 ACP Match compensator_1x1_(1,1)",
  "HK USP .45 ACP barrel_1x1_(1,1)",
  "HK USP .45 ACP pistol slide_2x1_(1,1)",
  "HK USP .45 ACP pistol slide_2x1_(2,1)",
  "HK USP Elite .45 ACP barrel_1x1_(1,1)",
  "HK USP Elite .45 ACP pistol slide_2x1_(1,1)",
  "HK USP Elite .45 ACP pistol slide_2x1_(2,1)",
  "HK USP Expert .45 ACP barrel_1x1_(1,1)",
  "HK USP Expert .45 ACP pistol slide_2x1_(1,1)",
  "HK USP Expert .45 ACP pistol slide_2x1_(2,1)",
  "HK USP Match .45 ACP barrel_1x1_(1,1)",
  "HK USP Match .45 ACP pistol slide_2x1_(1,1)",
  "HK USP Match .45 ACP pistol slide_2x1_(2,1)",
  "HK USP Red Dot sight mount_1x1_(1,1)",
  "HK USP Tactical .45 ACP 12-round magazine_1x1_(1,1)",
  "HK USP Tactical .45 ACP pistol slide_2x1_(1,1)",
  "HK USP Tactical .45 ACP pistol slide_2x1_(2,1)",
  "HK USP Tactical .45 ACP threaded barrel_1x1_(1,1)",
  "HK USP Tactical front sight_1x1_(1,1)",
  "HK USP Tactical rear sight_1x1_(1,1)",
  "HK USP Tactical thread protector_1x1_(1,1)",
  "HK USP front sight_1x1_(1,1)",
  "HK USP hammer_1x1_(1,1)",
  "HK USP rail adapter_1x1_(1,1)",
  "HK USP rear sight_1x1_(1,1)",
  "HK USP slide lock_1x1_(1,1)",
  "HK USP trigger_1x1_(1,1)",
  "HK417 7.62x51 16.5 inch barrel_4x1_(1,1)",
  "HK417 7.62x51 16.5 inch barrel_4x1_(2,1)",
  "HK417 7.62x51 16.5 inch barrel_4x1_(3,1)",
  "HK417 7.62x51 16.5 inch barrel_4x1_(4,1)",
  "HK417 Adjustable Buttstock_2x1_(1,1)",
  "HK417 Adjustable Buttstock_2x1_(2,1)",
  "HK417 E1 extended charging handle_1x1_(1,1)",
  "HK417 E2 buttstock_2x1_(1,1)",
  "HK417 E2 buttstock_2x1_(2,1)",
  "HK417 Extended Free Float handguard with flip-up front sight_3x1_(1,1)",
  "HK417 Extended Free Float handguard with flip-up front sight_3x1_(2,1)",
  "HK417 Extended Free Float handguard with flip-up front sight_3x1_(3,1)",
  "HK417 Patrol handguard with flip-up front sight_2x1_(1,1)",
  "HK417 Patrol handguard with flip-up front sight_2x1_(2,1)",
  "HK417 low profile gas block_1x1_(1,1)",
  "HK417@G28 7.62x51 10-round magazine_1x1_(1,1)",
  "HK417@G28 7.62x51 20-round magazine_1x2_(1,1)",
  "HK417@G28 7.62x51 20-round magazine_1x2_(1,2)",
  "Hand drill_3x2_(1,1)",
  "Hand drill_3x2_(1,2)",
  "Hand drill_3x2_(2,1)",
  "Hand drill_3x2_(2,2)",
  "Hand drill_3x2_(3,1)",
  "Hand drill_3x2_(3,2)",
  "Harris HBR Bipod_2x1_(1,1)",
  "Harris HBR Bipod_2x1_(2,1)",
  "Hartman Lenok 70mm rail_1x1_(1,1)",
  "Health Resort east wing office room 107 key_1x1_(1,1)",
  "Health Resort east wing office room 108 key_1x1_(1,1)",
  "Health Resort east wing room 205 key_1x1_(1,1)",
  "Health Resort east wing room 206 key_1x1_(1,1)",
  "Health Resort east wing room 209 key_1x1_(1,1)",
  "Health Resort east wing room 213 key_1x1_(1,1)",
  "Health Resort east wing room 216 key_1x1_(1,1)",
  "Health Resort east wing room 222 key_1x1_(1,1)",
  "Health Resort east wing room 226 key_1x1_(1,1)",
  "Health Resort east wing room 306 key_1x1_(1,1)",
  "Health Resort east wing room 308 key_1x1_(1,1)",
  "Health Resort east wing room 310 key_1x1_(1,1)",
  "Health Resort east wing room 313 key_1x1_(1,1)",
  "Health Resort east wing room 314 key_1x1_(1,1)",
  "Health Resort east wing room 316 key_1x1_(1,1)",
  "Health Resort east wing room 322 key_1x1_(1,1)",
  "Health Resort east wing room 328 key_1x1_(1,1)",
  "Health Resort management office safe key_1x1_(1,1)",
  "Health Resort management warehouse safe key_1x1_(1,1)",
  "Health Resort office key with a blue tape_1x1_(1,1)",
  "Health Resort universal utility room key_1x1_(1,1)",
  "Health Resort west wing office room 104 key_1x1_(1,1)",
  "Health Resort west wing office room 112 key_1x1_(1,1)",
  "Health Resort west wing room 203 key_1x1_(1,1)",
  "Health Resort west wing room 205 key_1x1_(1,1)",
  "Health Resort west wing room 207 key_1x1_(1,1)",
  "Health Resort west wing room 216 key_1x1_(1,1)",
  "Health Resort west wing room 218 key_1x1_(1,1)",
  "Health Resort west wing room 219 key_1x1_(1,1)",
  "Health Resort west wing room 220 key_1x1_(1,1)",
  "Health Resort west wing room 221 key_1x1_(1,1)",
  "Health Resort west wing room 222 key_1x1_(1,1)",
  "Health Resort west wing room 301 key_1x1_(1,1)",
  "Health Resort west wing room 303 key_1x1_(1,1)",
  "Health Resort west wing room 306 key_1x1_(1,1)",
  "Health Resort west wing room 309 key_1x1_(1,1)",
  "Health Resort west wing room 321 safe key_1x1_(1,1)",
  "Health Resort west wing room 323 key_1x1_(1,1)",
  "Health Resort west wing room 325 key_1x1_(1,1)",
  "Hensoldt FF 4-16x56 34mm riflescope_2x1_(1,1)",
  "Hensoldt FF 4-16x56 34mm riflescope_2x1_(2,1)",
  "Hensoldt RIS top rail_1x1_(1,1)",
  "Hensoldt RV red dot sight_1x1_(1,1)",
  "Hera Arms CQR tactical foregrip_1x1_(1,1)",
  "Hexagon 12K 12ga sound suppressor_2x1_(1,1)",
  "Hexagon 12K 12ga sound suppressor_2x1_(2,1)",
  "Hexagon medium length rail (Anodized Red)_1x1_(1,1)",
  "Hexagon medium length rail_1x1_(1,1)",
  "Hexagon short length rail (Anodized Red)_1x1_(1,1)",
  "Hexagon short length rail_1x1_(1,1)",
  "Hillside house key_1x1_(1,1)",
  "Hogue OverMolded Rubber Grip (Black)_1x1_(1,1)",
  "Hogue OverMolded Rubber Grip (FDE)_1x1_(1,1)",
  "Hogue OverMolded Rubber Grip (Ghillie Earth)_1x1_(1,1)",
  "Hogue OverMolded Rubber Grip (Ghillie Green)_1x1_(1,1)",
  "Hogue OverMolded Rubber Grip (Olive Drab)_1x1_(1,1)",
  "Holosun HS401G5 reflex sight_1x1_(1,1)",
  "Holosun LS321 Tactical device_1x1_(1,1)",
  "Horse figurine_1x2_(1,1)",
  "Horse figurine_1x2_(1,2)",
  "Hunting matches_1x1_(1,1)",
  "IDEA cash register key_1x1_(1,1)",
  "IEA Mil-Optics KH@F 34mm one-piece magmount_2x1_(1,1)",
  "IEA Mil-Optics KH@F 34mm one-piece magmount_2x1_(2,1)",
  "IFAK individual first aid kit_1x1_(1,1)",
  "Ibuprofen painkillers_1x1_(1,1)",
  "Immobilizing splint_1x1_(1,1)",
  "Insight WMX200 tactical flashlight_1x1_(1,1)",
  "Insulating tape_1x1_(1,1)",
  "Intelligence folder_2x1_(1,1)",
  "Intelligence folder_2x1_(2,1)",
  "Interchange plan map_1x1_(1,1)",
  "Iridium military thermal vision module_1x1_(1,1)",
  "Iron gate key_1x1_(1,1)",
  "Iskra ration pack_1x2_(1,1)",
  "Iskra ration pack_1x2_(1,2)",
  "JP Enterprises Flat-Top 30mm ring scope mount_2x1_(1,1)",
  "JP Enterprises Flat-Top 30mm ring scope mount_2x1_(2,1)",
  "Jar of DevilDog mayo_1x1_(1,1)",
  "KAC Folding Micro front sight_1x1_(1,1)",
  "KAC Folding Micro rear sight_1x1_(1,1)",
  "KAC Folding front sight_1x1_(1,1)",
  "KAC Folding rear sight_1x1_(1,1)",
  "KAC PRS@QDC 7.62x51 sound suppressor_2x1_(1,1)",
  "KAC PRS@QDC 7.62x51 sound suppressor_2x1_(2,1)",
  "KAC QDC 5.56x45 3-Prong Flash Eliminator_1x1_(1,1)",
  "KAC QDC 5.56x45 sound suppressor_2x1_(1,1)",
  "KAC QDC 5.56x45 sound suppressor_2x1_(2,1)",
  "KAC QDSS NT-4 5.56x45 sound suppressor (Black)_2x1_(1,1)",
  "KAC QDSS NT-4 5.56x45 sound suppressor (Black)_2x1_(2,1)",
  "KAC QDSS NT-4 5.56x45 sound suppressor (FDE)_2x1_(1,1)",
  "KAC QDSS NT-4 5.56x45 sound suppressor (FDE)_2x1_(2,1)",
  "KAC URX 3 3 inch rail_1x1_(1,1)",
  "KAC URX 3@3.1 long panel (FDE)_1x1_(1,1)",
  "KAC URX 3@3.1 long panel_1x1_(1,1)",
  "KAC URX 3@3.1 short panel (FDE)_1x1_(1,1)",
  "KAC URX 3@3.1 short panel_1x1_(1,1)",
  "KAC URX 3@3.1 stopper panel (FDE)_1x1_(1,1)",
  "KAC URX 3@3.1 stopper panel_1x1_(1,1)",
  "KAC vertical foregrip_1x1_(1,1)",
  "KEKTAPE duct tape_1x1_(1,1)",
  "KMZ 1P59 3-10x riflescope_2x1_(1,1)",
  "KMZ 1P59 3-10x riflescope_2x1_(2,1)",
  "KMZ 1P59 dovetail mount_1x1_(1,1)",
  "KMZ 1P59 scope eyecup_1x1_(1,1)",
  "KMZ 1P69 3-10x riflescope_2x1_(1,1)",
  "KMZ 1P69 3-10x riflescope_2x1_(2,1)",
  "KMZ 1P69 Weaver mount_2x1_(1,1)",
  "KMZ 1P69 Weaver mount_2x1_(2,1)",
  "KP-SR2 sight shade_1x1_(1,1)",
  "KRISS Defiance low profile flip-up front sight_1x1_(1,1)",
  "KRISS Defiance low profile flip-up rear sight_1x1_(1,1)",
  "KRISS Vector .45 ACP 5 inch barrel_2x1_(1,1)",
  "KRISS Vector .45 ACP 5 inch barrel_2x1_(2,1)",
  "KRISS Vector .45 ACP 6 inch barrel_2x1_(1,1)",
  "KRISS Vector .45 ACP 6 inch barrel_2x1_(2,1)",
  "KRISS Vector .45 ACP flash hider_1x1_(1,1)",
  "KRISS Vector .45 ACP thread protection cap_1x1_(1,1)",
  "KRISS Vector 9x19 5 inch barrel_2x1_(1,1)",
  "KRISS Vector 9x19 5 inch barrel_2x1_(2,1)",
  "KRISS Vector 9x19 6 inch barrel_2x1_(1,1)",
  "KRISS Vector 9x19 6 inch barrel_2x1_(2,1)",
  "KRISS Vector 9x19 flash hider_1x1_(1,1)",
  "KRISS Vector 9x19 thread protection cap_1x1_(1,1)",
  "KRISS Vector Gen.2 folding stock_2x1_(1,1)",
  "KRISS Vector Gen.2 folding stock_2x1_(2,1)",
  "KRISS Vector Mk.5 modular rail_1x1_(1,1)",
  "KRISS Vector Pistol Sling adapter_1x1_(1,1)",
  "KRISS Vector bottom rail_1x1_(1,1)",
  "KRISS Vector non-folding stock adapter_1x1_(1,1)",
  "KRISS Vector side rail_1x1_(1,1)",
  "KS-23 23x75 510mm barrel_3x1_(1,1)",
  "KS-23 23x75 510mm barrel_3x1_(2,1)",
  "KS-23 23x75 510mm barrel_3x1_(3,1)",
  "KS-23 23x75 700mm barrel_5x1_(1,1)",
  "KS-23 23x75 700mm barrel_5x1_(2,1)",
  "KS-23 23x75 700mm barrel_5x1_(3,1)",
  "KS-23 23x75 700mm barrel_5x1_(4,1)",
  "KS-23 23x75 700mm barrel_5x1_(5,1)",
  "KS-23 wooden stock_2x1_(1,1)",
  "KS-23 wooden stock_2x1_(2,1)",
  "KS-23M 23x75 3-shell magazine cap_1x1_(1,1)",
  "KS-23M forestock_2x1_(1,1)",
  "KS-23M forestock_2x1_(2,1)",
  "KS-23M pistol grip_1x1_(1,1)",
  "KS-23M wire stock_2x1_(1,1)",
  "KS-23M wire stock_2x1_(2,1)",
  "Keycard with a blue marking_1x1_(1,1)",
  "Kiba Arms 25mm accessory ring mount_1x1_(1,1)",
  "Kiba Arms SPRM rail mount for pump-action shotguns_1x1_(1,1)",
  "Kiba Arms Short Mount sight adapter_1x1_(1,1)",
  "Kiba Arms Short Prism 2.5x scope_2x1_(1,1)",
  "Kiba Arms Short Prism 2.5x scope_2x1_(2,1)",
  "Kiba Arms inner grate door key_1x1_(1,1)",
  "Kiba Arms outer door key_1x1_(1,1)",
  "L1 (Norepinephrine) injector_1x1_(1,1)",
  "LA-5B@PEQ tactical device_1x1_(1,1)",
  "LEDX Skin Transilluminator_1x1_(1,1)",
  "LVNDMARK's rat poison_1x2_(1,1)",
  "LVNDMARK's rat poison_1x2_(1,2)",
  "LaRue LT101 QD Tactical Picatinny Riser mount_1x1_(1,1)",
  "Lantac BMD 7.62x51 Blast Mitigation Device_1x1_(1,1)",
  "Lantac BMD Blast Mitigation Device A3 direct thread adapter_1x1_(1,1)",
  "Leapers UTG 25mm ring scope mount_2x1_(1,1)",
  "Leapers UTG 25mm ring scope mount_2x1_(2,1)",
  "Leapers UTG reflex sight_1x1_(1,1)",
  "Leatherman Multitool_1x1_(1,1)",
  "Leupold DeltaPoint Reflex Sight_1x1_(1,1)",
  "Leupold Mark 4 HAMR 4x24 DeltaPoint hybrid assault scope_2x1_(1,1)",
  "Leupold Mark 4 HAMR 4x24 DeltaPoint hybrid assault scope_2x1_(2,1)",
  "Leupold Mark 4 LR 6.5-20x50 30mm riflescope_2x1_(1,1)",
  "Leupold Mark 4 LR 6.5-20x50 30mm riflescope_2x1_(2,1)",
  "Light bulb_1x1_(1,1)",
  "Lobaev Arms 30mm scope mount_2x1_(1,1)",
  "Lobaev Arms 30mm scope mount_2x1_(2,1)",
  "Loot Lord plushie_2x2_(1,1)",
  "Loot Lord plushie_2x2_(1,2)",
  "Loot Lord plushie_2x2_(2,1)",
  "Loot Lord plushie_2x2_(2,2)",
  "M.U.L.E. stimulant injector_1x1_(1,1)",
  "M14 7.62x51 30-round magazine_1x2_(1,1)",
  "M14 7.62x51 30-round magazine_1x2_(1,2)",
  "M14 7.62x51 X Products X-14 50-round drum magazine_1x2_(1,1)",
  "M14 7.62x51 X Products X-14 50-round drum magazine_1x2_(1,2)",
  "M14 A.R.M.S. #18 scope mount_1x1_(1,1)",
  "M14 Amega Mini Scout Mount system_2x1_(1,1)",
  "M14 Amega Mini Scout Mount system_2x1_(2,1)",
  "M14 JP Enterprises 7.62x51 Tactical Compensator_1x1_(1,1)",
  "M14 Leapers UTG 4-Point Locking Deluxe mount_1x1_(1,1)",
  "M14 SA Enlarged Military Aperture rear sight_1x1_(1,1)",
  "M14 SAGE International DCSB mount_2x1_(1,1)",
  "M14 SAGE International DCSB mount_2x1_(2,1)",
  "M14 SAGE International M14ALCS (MOD-0) pistol grip_1x1_(1,1)",
  "M14 SAGE International M14ALCS (MOD-0) stock_2x1_(1,1)",
  "M14 SAGE International M14ALCS (MOD-0) stock_2x1_(2,1)",
  "M14 SAGE International M14ALCS chassis_3x1_(1,1)",
  "M14 SAGE International M14ALCS chassis_3x1_(2,1)",
  "M14 SAGE International M14ALCS chassis_3x1_(3,1)",
  "M14 Smith Enterprise Good Iron 7.62x51 muzzle brake_1x1_(1,1)",
  "M14 Smith Enterprise Vortex 7.62x51 muzzle brake_1x1_(1,1)",
  "M14 TROY S.A.S.S. Chassis stock_3x1_(1,1)",
  "M14 TROY S.A.S.S. Chassis stock_3x1_(2,1)",
  "M14 TROY S.A.S.S. Chassis stock_3x1_(3,1)",
  "M14 UltiMAK M8 Forward Optic mount_2x1_(1,1)",
  "M14 UltiMAK M8 Forward Optic mount_2x1_(2,1)",
  "M14 Vltor CASV-14 rail system_2x1_(1,1)",
  "M14 Vltor CASV-14 rail system_2x1_(2,1)",
  "M14 Yankee Hill Phantom 7.62x51 flash hider_1x1_(1,1)",
  "M18 smoke grenade (Green)_1x1_(1,1)",
  "M1911 Anarchy Outdoors .45 ACP muzzle brake_1x1_(1,1)",
  "M1911 Kiba Arms Geneburn custom side grips_1x1_(1,1)",
  "M1911 Pachmayr American Legend Grip #423_1x1_(1,1)",
  "M1911A1 .45 ACP 127mm barrel_1x1_(1,1)",
  "M1911A1 .45 ACP 7-round magazine_1x1_(1,1)",
  "M1911A1 .45 ACP Mec-Gar 11-round magazine_1x1_(1,1)",
  "M1911A1 .45 ACP National Match barrel_1x1_(1,1)",
  "M1911A1 .45 ACP Wilson Combat 7-round magazine_1x1_(1,1)",
  "M1911A1 .45 ACP threaded barrel_1x1_(1,1)",
  "M1911A1 Caspian Arms Trik Trigger_1x1_(1,1)",
  "M1911A1 NcSTAR trigger guard mount_1x1_(1,1)",
  "M1911A1 STI HEX hammer_1x1_(1,1)",
  "M1911A1 Weigand Weig-a-tinny rail mount_1x1_(1,1)",
  "M1911A1 Wilson Extended slide stop_1x1_(1,1)",
  "M1911A1 Wilson Retro Commander hammer_1x1_(1,1)",
  "M1911A1 Wilson Ultralight skeletonized hammer_1x1_(1,1)",
  "M1911A1 front sight_1x1_(1,1)",
  "M1911A1 hammer_1x1_(1,1)",
  "M1911A1 pistol slide_2x1_(1,1)",
  "M1911A1 pistol slide_2x1_(2,1)",
  "M1911A1 rear sight_1x1_(1,1)",
  "M1911A1 side grips_1x1_(1,1)",
  "M1911A1 slide stop_1x1_(1,1)",
  "M1911A1 trigger_1x1_(1,1)",
  "M1A 7.62x51 10-round magazine_1x1_(1,1)",
  "M1A 7.62x51 16 inch barrel_4x1_(1,1)",
  "M1A 7.62x51 16 inch barrel_4x1_(2,1)",
  "M1A 7.62x51 16 inch barrel_4x1_(3,1)",
  "M1A 7.62x51 16 inch barrel_4x1_(4,1)",
  "M1A 7.62x51 20-round magazine_1x2_(1,1)",
  "M1A 7.62x51 20-round magazine_1x2_(1,2)",
  "M1A 7.62x51 22 inch barrel_5x1_(1,1)",
  "M1A 7.62x51 22 inch barrel_5x1_(2,1)",
  "M1A 7.62x51 22 inch barrel_5x1_(3,1)",
  "M1A 7.62x51 22 inch barrel_5x1_(4,1)",
  "M1A 7.62x51 22 inch barrel_5x1_(5,1)",
  "M1A National Match 7.62x51 flash suppressor_1x1_(1,1)",
  "M1A ProMag Archangel chassis_3x1_(1,1)",
  "M1A ProMag Archangel chassis_3x1_(2,1)",
  "M1A ProMag Archangel chassis_3x1_(3,1)",
  "M1A SA National Match .062 Blade front sight_1x1_(1,1)",
  "M1A SA XS Post .125 Blade front sight_1x1_(1,1)",
  "M1A SOCOM 16 7.62x51 muzzle brake-compensator_1x1_(1,1)",
  "M1A SOCOM 16 stock_3x1_(1,1)",
  "M1A SOCOM 16 stock_3x1_(2,1)",
  "M1A SOCOM 16 stock_3x1_(3,1)",
  "M1A SOCOM 16 upper part_2x1_(1,1)",
  "M1A SOCOM 16 upper part_2x1_(2,1)",
  "M1A Smith Enterprise SOCOM 16 7.62x51 threaded muzzle brake & gas block_1x1_(1,1)",
  "M203 40mm underbarrel grenade launcher_2x1_(1,1)",
  "M203 40mm underbarrel grenade launcher_2x1_(2,1)",
  "M32A1 40mm cylinder_2x2_(1,1)",
  "M32A1 40mm cylinder_2x2_(1,2)",
  "M32A1 40mm cylinder_2x2_(2,1)",
  "M32A1 40mm cylinder_2x2_(2,2)",
  "M45A1 Mil-Tac GVT G10 side grips_1x1_(1,1)",
  "M45A1 Novak Lomount front sight_1x1_(1,1)",
  "M45A1 Novak Lomount rear sight_1x1_(1,1)",
  "M45A1 hammer_1x1_(1,1)",
  "M45A1 pistol slide_2x1_(1,1)",
  "M45A1 pistol slide_2x1_(2,1)",
  "M45A1 slide lock_1x1_(1,1)",
  "M45A1 trigger_1x1_(1,1)",
  "M4A1 5.56x45 upper receiver_2x1_(1,1)",
  "M4A1 5.56x45 upper receiver_2x1_(2,1)",
  "M4A1 front sight with gas block_1x1_(1,1)",
  "M67 hand grenade_1x1_(1,1)",
  "M700 30mm integral ring scope mount_2x1_(1,1)",
  "M700 30mm integral ring scope mount_2x1_(2,1)",
  "M700 7.62x51 20 inch stainless steel threaded barrel_3x1_(1,1)",
  "M700 7.62x51 20 inch stainless steel threaded barrel_3x1_(2,1)",
  "M700 7.62x51 20 inch stainless steel threaded barrel_3x1_(3,1)",
  "M700 7.62x51 20 inch threaded barrel_3x1_(1,1)",
  "M700 7.62x51 20 inch threaded barrel_3x1_(2,1)",
  "M700 7.62x51 20 inch threaded barrel_3x1_(3,1)",
  "M700 7.62x51 26 inch barrel_4x1_(1,1)",
  "M700 7.62x51 26 inch barrel_4x1_(2,1)",
  "M700 7.62x51 26 inch barrel_4x1_(3,1)",
  "M700 7.62x51 26 inch barrel_4x1_(4,1)",
  "M700 7.62x51 26 inch stainless steel barrel_4x1_(1,1)",
  "M700 7.62x51 26 inch stainless steel barrel_4x1_(2,1)",
  "M700 7.62x51 26 inch stainless steel barrel_4x1_(3,1)",
  "M700 7.62x51 26 inch stainless steel barrel_4x1_(4,1)",
  "M700 7.62x51 AI AICS 10-round magazine_1x2_(1,1)",
  "M700 7.62x51 AI AICS 10-round magazine_1x2_(1,2)",
  "M700 7.62x51 AI AICS 5-round magazine_1x1_(1,1)",
  "M700 7.62x51 MDT AICS 12-round magazine_1x2_(1,1)",
  "M700 7.62x51 MDT AICS 12-round magazine_1x2_(1,2)",
  "M700 7.62x51 Magpul PMAG AC 10-round magazine_1x2_(1,1)",
  "M700 7.62x51 Magpul PMAG AC 10-round magazine_1x2_(1,2)",
  "M700 7.62x51 Magpul PMAG AC 5-round magazine_1x1_(1,1)",
  "M700 7.62x51 ProMag AA-70 10-round magazine_1x1_(1,1)",
  "M700 7.62x51 ProMag AA-70 20-round magazine_1x2_(1,1)",
  "M700 7.62x51 ProMag AA-70 20-round magazine_1x2_(1,2)",
  "M700 7.62x51 Wyatt's Outdoor 10-round magazine_1x2_(1,1)",
  "M700 7.62x51 Wyatt's Outdoor 10-round magazine_1x2_(1,2)",
  "M700 7.62x51 Wyatt's Outdoor 5-round magazine_1x1_(1,1)",
  "M700 AB Arms MODX GEN 3 KeyMod handguard_3x1_(1,1)",
  "M700 AB Arms MODX GEN 3 KeyMod handguard_3x1_(2,1)",
  "M700 AB Arms MODX GEN 3 KeyMod handguard_3x1_(3,1)",
  "M700 AB Arms MODX GEN 3 chassis_2x1_(1,1)",
  "M700 AB Arms MODX GEN 3 chassis_2x1_(2,1)",
  "M700 AB Arms MODX buffer tube side folder adapter_1x1_(1,1)",
  "M700 AB Arms MODX rail mount_2x1_(1,1)",
  "M700 AB Arms MODX rail mount_2x1_(2,1)",
  "M700 AB Arms MOD^X GEN 3 KeyMod handguard_3x1_(1,1)",
  "M700 AB Arms MOD^X GEN 3 KeyMod handguard_3x1_(2,1)",
  "M700 AB Arms MOD^X GEN 3 KeyMod handguard_3x1_(3,1)",
  "M700 AB Arms MOD^X GEN 3 chassis_2x1_(1,1)",
  "M700 AB Arms MOD^X GEN 3 chassis_2x1_(2,1)",
  "M700 AB Arms MOD^X buffer tube side folder adapter_1x1_(1,1)",
  "M700 AB Arms MOD^X rail mount_2x1_(1,1)",
  "M700 AB Arms MOD^X rail mount_2x1_(2,1)",
  "M700 AI AT AICS polymer chassis_4x1_(1,1)",
  "M700 AI AT AICS polymer chassis_4x1_(2,1)",
  "M700 AI AT AICS polymer chassis_4x1_(3,1)",
  "M700 AI AT AICS polymer chassis_4x1_(4,1)",
  "M700 Hogue Overmolded Ghillie stock_3x1_(1,1)",
  "M700 Hogue Overmolded Ghillie stock_3x1_(2,1)",
  "M700 Hogue Overmolded Ghillie stock_3x1_(3,1)",
  "M700 Magpul Pro 700 chassis inline mount_2x1_(1,1)",
  "M700 Magpul Pro 700 chassis inline mount_2x1_(2,1)",
  "M700 Magpul Pro 700 chassis_3x1_(1,1)",
  "M700 Magpul Pro 700 chassis_3x1_(2,1)",
  "M700 Magpul Pro 700 chassis_3x1_(3,1)",
  "M700 Magpul Pro 700 folding stock_2x1_(1,1)",
  "M700 Magpul Pro 700 folding stock_2x1_(2,1)",
  "M700 Magpul Pro 700 pistol grip_1x1_(1,1)",
  "M700 ProMag Archangel chassis_3x1_(1,1)",
  "M700 ProMag Archangel chassis_3x1_(2,1)",
  "M700 ProMag Archangel chassis_3x1_(3,1)",
  "M700 extended multi-slot Weaver rail base_2x1_(1,1)",
  "M700 extended multi-slot Weaver rail base_2x1_(2,1)",
  "M700 thread protection cap (Stainless steel)_1x1_(1,1)",
  "M700 thread protection cap_1x1_(1,1)",
  "M870 12ga 10-shell magazine_3x1_(1,1)",
  "M870 12ga 10-shell magazine_3x1_(2,1)",
  "M870 12ga 10-shell magazine_3x1_(3,1)",
  "M870 12ga 355mm barrel_2x1_(1,1)",
  "M870 12ga 355mm barrel_2x1_(2,1)",
  "M870 12ga 4-shell magazine cap_1x1_(1,1)",
  "M870 12ga 508mm barrel with a fixed sight_3x1_(1,1)",
  "M870 12ga 508mm barrel with a fixed sight_3x1_(2,1)",
  "M870 12ga 508mm barrel with a fixed sight_3x1_(3,1)",
  "M870 12ga 508mm barrel_3x1_(1,1)",
  "M870 12ga 508mm barrel_3x1_(2,1)",
  "M870 12ga 508mm barrel_3x1_(3,1)",
  "M870 12ga 660mm vent rib barrel_4x1_(1,1)",
  "M870 12ga 660mm vent rib barrel_4x1_(2,1)",
  "M870 12ga 660mm vent rib barrel_4x1_(3,1)",
  "M870 12ga 660mm vent rib barrel_4x1_(4,1)",
  "M870 12ga 7-shell magazine_2x1_(1,1)",
  "M870 12ga 7-shell magazine_2x1_(2,1)",
  "M870 12ga sawed-off 325mm barrel_2x1_(1,1)",
  "M870 12ga sawed-off 325mm barrel_2x1_(2,1)",
  "M870 FAB Defense AGR-870 pistol grip_1x1_(1,1)",
  "M870 FAB Defense PR-870 forestock_2x1_(1,1)",
  "M870 FAB Defense PR-870 forestock_2x1_(2,1)",
  "M870 Leapers UTG PRO MTU-028SG rail_2x1_(1,1)",
  "M870 Leapers UTG PRO MTU-028SG rail_2x1_(2,1)",
  "M870 Magpul MOE handguard_2x1_(1,1)",
  "M870 Magpul MOE handguard_2x1_(2,1)",
  "M870 Magpul SGA polymer stock_2x1_(1,1)",
  "M870 Magpul SGA polymer stock_2x1_(2,1)",
  "M870 Mesa Tactical LEO stock adapter_2x1_(1,1)",
  "M870 Mesa Tactical LEO stock adapter_2x1_(2,1)",
  "M870 Mesa Tactical magazine clamp_1x1_(1,1)",
  "M870 SPS polymer stock_2x1_(1,1)",
  "M870 SPS polymer stock_2x1_(2,1)",
  "M870 Shockwave Raptor grip_1x1_(1,1)",
  "M870 SpeedFeed Short handguard_2x1_(1,1)",
  "M870 SpeedFeed Short handguard_2x1_(2,1)",
  "M870 XS SHOTRAIL rail with Ghost Ring rear sight_2x1_(1,1)",
  "M870 XS SHOTRAIL rail with Ghost Ring rear sight_2x1_(2,1)",
  "M9A3 9x19 17-round magazine_1x1_(1,1)",
  "M9A3 9x19 threaded barrel_1x1_(1,1)",
  "M9A3 Sight Mount rear sight rail_1x1_(1,1)",
  "M9A3 front sight_1x1_(1,1)",
  "M9A3 pistol slide_2x1_(1,1)",
  "M9A3 pistol slide_2x1_(2,1)",
  "M9A3 polymer side grips_1x1_(1,1)",
  "M9A3 rear sight_1x1_(1,1)",
  "M9A3 thread protection cap_1x1_(1,1)",
  "MAWL-C1+ tactical device_1x1_(1,1)",
  "MCX .300 BLK 171mm barrel_2x1_(1,1)",
  "MCX .300 BLK 171mm barrel_2x1_(2,1)",
  "MCX .300 BLK 229mm barrel_2x1_(1,1)",
  "MCX .300 BLK 229mm barrel_2x1_(2,1)",
  "MCX GEN1 .300 BLK upper receiver_2x1_(1,1)",
  "MCX GEN1 .300 BLK upper receiver_2x1_(2,1)",
  "MCX GEN1 KeyMod 12 inch handguard_3x1_(1,1)",
  "MCX GEN1 KeyMod 12 inch handguard_3x1_(2,1)",
  "MCX GEN1 KeyMod 12 inch handguard_3x1_(3,1)",
  "MCX GEN1 KeyMod 8 inch handguard_2x1_(1,1)",
  "MCX GEN1 KeyMod 8 inch handguard_2x1_(2,1)",
  "MCX SPEAR 11 inch M-LOK handguard_3x1_(1,1)",
  "MCX SPEAR 11 inch M-LOK handguard_3x1_(2,1)",
  "MCX SPEAR 11 inch M-LOK handguard_3x1_(3,1)",
  "MCX SPEAR 6.8x51 330mm barrel_2x1_(1,1)",
  "MCX SPEAR 6.8x51 330mm barrel_2x1_(2,1)",
  "MCX SPEAR 6.8x51 upper receiver_2x1_(1,1)",
  "MCX SPEAR 6.8x51 upper receiver_2x1_(2,1)",
  "MCX SPEAR Adjustable Gas Piston_1x1_(1,1)",
  "MCX SPEAR Clutch-Lok QD 6.8x51 Shouldered Flash Hider_1x1_(1,1)",
  "MCX SPEAR SLX68-MG-QD 6.8x51 sound suppressor_2x1_(1,1)",
  "MCX SPEAR SLX68-MG-QD 6.8x51 sound suppressor_2x1_(2,1)",
  "MCX SPEAR charging handle_1x1_(1,1)",
  "MCX charging handle_1x1_(1,1)",
  "MCX flip-up front sight_1x1_(1,1)",
  "MCX flip-up rear sight_1x1_(1,1)",
  "MCX gas block_1x1_(1,1)",
  "MCX pistol grip_1x1_(1,1)",
  "MCX@MPX Stock Locking Hinge Assembly_1x1_(1,1)",
  "MCX@MPX folding stock adapter buffer tube (Coyote Tan)_2x1_(1,1)",
  "MCX@MPX folding stock adapter buffer tube (Coyote Tan)_2x1_(2,1)",
  "MDR 5.56x45 16 inch barrel_3x1_(1,1)",
  "MDR 5.56x45 16 inch barrel_3x1_(2,1)",
  "MDR 5.56x45 16 inch barrel_3x1_(3,1)",
  "MDR 7.62x51 16 inch barrel_3x1_(1,1)",
  "MDR 7.62x51 16 inch barrel_3x1_(2,1)",
  "MDR 7.62x51 16 inch barrel_3x1_(3,1)",
  "MDR handguard (Black)_2x1_(1,1)",
  "MDR handguard (Black)_2x1_(2,1)",
  "MDR handguard (FDE)_2x1_(1,1)",
  "MDR handguard (FDE)_2x1_(2,1)",
  "MDR pistol grip (Black)_1x1_(1,1)",
  "MDR pistol grip (FDE)_1x1_(1,1)",
  "ME Cylinder 12ga muzzle adapter_1x1_(1,1)",
  "MP-133 12ga 510mm barrel with rib_3x1_(1,1)",
  "MP-133 12ga 510mm barrel with rib_3x1_(2,1)",
  "MP-133 12ga 510mm barrel with rib_3x1_(3,1)",
  "MP-133 12ga 510mm barrel_3x1_(1,1)",
  "MP-133 12ga 510mm barrel_3x1_(2,1)",
  "MP-133 12ga 510mm barrel_3x1_(3,1)",
  "MP-133 12ga 540mm barrel with rib_3x1_(1,1)",
  "MP-133 12ga 540mm barrel with rib_3x1_(2,1)",
  "MP-133 12ga 540mm barrel with rib_3x1_(3,1)",
  "MP-133 12ga 540mm barrel_3x1_(1,1)",
  "MP-133 12ga 540mm barrel_3x1_(2,1)",
  "MP-133 12ga 540mm barrel_3x1_(3,1)",
  "MP-133 12ga 6-shell magazine_2x1_(1,1)",
  "MP-133 12ga 6-shell magazine_2x1_(2,1)",
  "MP-133 12ga 610mm barrel with rib_4x1_(1,1)",
  "MP-133 12ga 610mm barrel with rib_4x1_(2,1)",
  "MP-133 12ga 610mm barrel with rib_4x1_(3,1)",
  "MP-133 12ga 610mm barrel with rib_4x1_(4,1)",
  "MP-133 12ga 610mm barrel_4x1_(1,1)",
  "MP-133 12ga 610mm barrel_4x1_(2,1)",
  "MP-133 12ga 610mm barrel_4x1_(3,1)",
  "MP-133 12ga 610mm barrel_4x1_(4,1)",
  "MP-133 12ga 660mm barrel with rib_4x1_(1,1)",
  "MP-133 12ga 660mm barrel with rib_4x1_(2,1)",
  "MP-133 12ga 660mm barrel with rib_4x1_(3,1)",
  "MP-133 12ga 660mm barrel with rib_4x1_(4,1)",
  "MP-133 12ga 660mm barrel_4x1_(1,1)",
  "MP-133 12ga 660mm barrel_4x1_(2,1)",
  "MP-133 12ga 660mm barrel_4x1_(3,1)",
  "MP-133 12ga 660mm barrel_4x1_(4,1)",
  "MP-133 12ga 710mm barrel with rib_5x1_(1,1)",
  "MP-133 12ga 710mm barrel with rib_5x1_(2,1)",
  "MP-133 12ga 710mm barrel with rib_5x1_(3,1)",
  "MP-133 12ga 710mm barrel with rib_5x1_(4,1)",
  "MP-133 12ga 710mm barrel with rib_5x1_(5,1)",
  "MP-133 12ga 710mm barrel_5x1_(1,1)",
  "MP-133 12ga 710mm barrel_5x1_(2,1)",
  "MP-133 12ga 710mm barrel_5x1_(3,1)",
  "MP-133 12ga 710mm barrel_5x1_(4,1)",
  "MP-133 12ga 710mm barrel_5x1_(5,1)",
  "MP-133 12ga 750mm barrel with rib_5x1_(1,1)",
  "MP-133 12ga 750mm barrel with rib_5x1_(2,1)",
  "MP-133 12ga 750mm barrel with rib_5x1_(3,1)",
  "MP-133 12ga 750mm barrel with rib_5x1_(4,1)",
  "MP-133 12ga 750mm barrel with rib_5x1_(5,1)",
  "MP-133 12ga 750mm barrel_5x1_(1,1)",
  "MP-133 12ga 750mm barrel_5x1_(2,1)",
  "MP-133 12ga 750mm barrel_5x1_(3,1)",
  "MP-133 12ga 750mm barrel_5x1_(4,1)",
  "MP-133 12ga 750mm barrel_5x1_(5,1)",
  "MP-133 12ga 8-shell magazine_3x1_(1,1)",
  "MP-133 12ga 8-shell magazine_3x1_(2,1)",
  "MP-133 12ga 8-shell magazine_3x1_(3,1)",
  "MP-133 beechwood forestock_2x1_(1,1)",
  "MP-133 beechwood forestock_2x1_(2,1)",
  "MP-133 custom plastic forestock with rails_2x1_(1,1)",
  "MP-133 custom plastic forestock with rails_2x1_(2,1)",
  "MP-133@153 Taktika Tula 12003 stock adapter_1x1_(1,1)",
  "MP-133@153 plastic pistol grip_1x1_(1,1)",
  "MP-133@153 plastic stock_2x1_(1,1)",
  "MP-133@153 plastic stock_2x1_(2,1)",
  "MP-133@153 wooden stock_2x1_(1,1)",
  "MP-133@153 wooden stock_2x1_(2,1)",
  "MP-153 12ga 4-shell magazine cap_1x1_(1,1)",
  "MP-153 12ga 5-shell magazine_1x1_(1,1)",
  "MP-153 12ga 6-shell magazine_2x1_(1,1)",
  "MP-153 12ga 6-shell magazine_2x1_(2,1)",
  "MP-153 12ga 610mm barrel_4x1_(1,1)",
  "MP-153 12ga 610mm barrel_4x1_(2,1)",
  "MP-153 12ga 610mm barrel_4x1_(3,1)",
  "MP-153 12ga 610mm barrel_4x1_(4,1)",
  "MP-153 12ga 660mm barrel_4x1_(1,1)",
  "MP-153 12ga 660mm barrel_4x1_(2,1)",
  "MP-153 12ga 660mm barrel_4x1_(3,1)",
  "MP-153 12ga 660mm barrel_4x1_(4,1)",
  "MP-153 12ga 7-shell magazine_3x1_(1,1)",
  "MP-153 12ga 7-shell magazine_3x1_(2,1)",
  "MP-153 12ga 7-shell magazine_3x1_(3,1)",
  "MP-153 12ga 710mm barrel_5x1_(1,1)",
  "MP-153 12ga 710mm barrel_5x1_(2,1)",
  "MP-153 12ga 710mm barrel_5x1_(3,1)",
  "MP-153 12ga 710mm barrel_5x1_(4,1)",
  "MP-153 12ga 710mm barrel_5x1_(5,1)",
  "MP-153 12ga 750mm barrel_5x1_(1,1)",
  "MP-153 12ga 750mm barrel_5x1_(2,1)",
  "MP-153 12ga 750mm barrel_5x1_(3,1)",
  "MP-153 12ga 750mm barrel_5x1_(4,1)",
  "MP-153 12ga 750mm barrel_5x1_(5,1)",
  "MP-153 12ga 8-shell magazine_3x1_(1,1)",
  "MP-153 12ga 8-shell magazine_3x1_(2,1)",
  "MP-153 12ga 8-shell magazine_3x1_(3,1)",
  "MP-153 polymer forestock_2x1_(1,1)",
  "MP-153 polymer forestock_2x1_(2,1)",
  "MP-155 12ga 510mm barrel_3x1_(1,1)",
  "MP-155 12ga 510mm barrel_3x1_(2,1)",
  "MP-155 12ga 510mm barrel_3x1_(3,1)",
  "MP-155 12ga 6-shell magazine_2x1_(1,1)",
  "MP-155 12ga 6-shell magazine_2x1_(2,1)",
  "MP-155 Ultima large recoil pad_1x1_(1,1)",
  "MP-155 Ultima medium recoil pad_1x1_(1,1)",
  "MP-155 Ultima pistol grip rubber pad_1x1_(1,1)",
  "MP-155 Ultima pistol grip_2x1_(1,1)",
  "MP-155 Ultima pistol grip_2x1_(2,1)",
  "MP-155 Ultima polymer forestock_2x1_(1,1)",
  "MP-155 Ultima polymer forestock_2x1_(2,1)",
  "MP-155 Ultima polymer stock_2x1_(1,1)",
  "MP-155 Ultima polymer stock_2x1_(2,1)",
  "MP-155 Ultima short rail_1x1_(1,1)",
  "MP-155 Ultima thermal camera_1x1_(1,1)",
  "MP-155 Ultima thin recoil pad_1x1_(1,1)",
  "MP-155 Ultima top rail_3x1_(1,1)",
  "MP-155 Ultima top rail_3x1_(2,1)",
  "MP-155 Ultima top rail_3x1_(3,1)",
  "MP-155 Ultima underbarrel mount_1x1_(1,1)",
  "MP-155 walnut forestock_2x1_(1,1)",
  "MP-155 walnut forestock_2x1_(2,1)",
  "MP-155 walnut stock_2x1_(1,1)",
  "MP-155 walnut stock_2x1_(2,1)",
  "MP-18 7.62x54R 600mm barrel_4x1_(1,1)",
  "MP-18 7.62x54R 600mm barrel_4x1_(2,1)",
  "MP-18 7.62x54R 600mm barrel_4x1_(3,1)",
  "MP-18 7.62x54R 600mm barrel_4x1_(4,1)",
  "MP-18 polymer handguard_2x1_(1,1)",
  "MP-18 polymer handguard_2x1_(2,1)",
  "MP-18 polymer stock_2x1_(1,1)",
  "MP-18 polymer stock_2x1_(2,1)",
  "MP-18 sight mount_1x1_(1,1)",
  "MP-18 wooden handguard_2x1_(1,1)",
  "MP-18 wooden handguard_2x1_(2,1)",
  "MP-18 wooden stock_2x1_(1,1)",
  "MP-18 wooden stock_2x1_(2,1)",
  "MP-43 12ga 750mm barrel_5x1_(1,1)",
  "MP-43 12ga 750mm barrel_5x1_(2,1)",
  "MP-43 12ga 750mm barrel_5x1_(3,1)",
  "MP-43 12ga 750mm barrel_5x1_(4,1)",
  "MP-43 12ga 750mm barrel_5x1_(5,1)",
  "MP-43 12ga sawed-off 310mm barrel_2x1_(1,1)",
  "MP-43 12ga sawed-off 310mm barrel_2x1_(2,1)",
  "MP-43-1C 12ga 510mm barrel_3x1_(1,1)",
  "MP-43-1C 12ga 510mm barrel_3x1_(2,1)",
  "MP-43-1C 12ga 510mm barrel_3x1_(3,1)",
  "MP-43-1C 12ga 725mm barrel_5x1_(1,1)",
  "MP-43-1C 12ga 725mm barrel_5x1_(2,1)",
  "MP-43-1C 12ga 725mm barrel_5x1_(3,1)",
  "MP-43-1C 12ga 725mm barrel_5x1_(4,1)",
  "MP-43-1C 12ga 725mm barrel_5x1_(5,1)",
  "MP-43-1C buttpad_1x1_(1,1)",
  "MP-443 'Grach' 9x19 18-round magazine_1x1_(1,1)",
  "MP-443 'Grach' Zenit B-8 mount_1x1_(1,1)",
  "MP-443 'Grach' polymer pistol grip_1x1_(1,1)",
  "MP9 9x19 15-round magazine_1x1_(1,1)",
  "MP9 9x19 20-round magazine_1x1_(1,1)",
  "MP9 9x19 25-round magazine_1x2_(1,1)",
  "MP9 9x19 25-round magazine_1x2_(1,2)",
  "MP9 9x19 30-round magazine_1x2_(1,1)",
  "MP9 9x19 30-round magazine_1x2_(1,2)",
  "MP9 9x19 sound suppressor mount_1x1_(1,1)",
  "MP9 9x19 sound suppressor_2x1_(1,1)",
  "MP9 9x19 sound suppressor_2x1_(2,1)",
  "MP9 9x19 upper receiver_2x1_(1,1)",
  "MP9 9x19 upper receiver_2x1_(2,1)",
  "MP9 bottom rail_1x1_(1,1)",
  "MP9 charging handle_1x1_(1,1)",
  "MP9 rear sight_1x1_(1,1)",
  "MP9 side rail_1x1_(1,1)",
  "MP9 stock_2x1_(1,1)",
  "MP9 stock_2x1_(2,1)",
  "MP9-N 9x19 upper receiver_2x1_(1,1)",
  "MP9-N 9x19 upper receiver_2x1_(2,1)",
  "MP9-N vertical foregrip_1x1_(1,1)",
  "MPX 9x19 10.5 inch barrel_3x1_(1,1)",
  "MPX 9x19 10.5 inch barrel_3x1_(2,1)",
  "MPX 9x19 10.5 inch barrel_3x1_(3,1)",
  "MPX 9x19 14 inch barrel_3x1_(1,1)",
  "MPX 9x19 14 inch barrel_3x1_(2,1)",
  "MPX 9x19 14 inch barrel_3x1_(3,1)",
  "MPX 9x19 20-round magazine_1x1_(1,1)",
  "MPX 9x19 203mm barrel_2x1_(1,1)",
  "MPX 9x19 203mm barrel_2x1_(2,1)",
  "MPX 9x19 30-round magazine_1x2_(1,1)",
  "MPX 9x19 30-round magazine_1x2_(1,2)",
  "MPX 9x19 4.5 inch barrel_1x1_(1,1)",
  "MPX 9x19 6.5 inch barrel_2x1_(1,1)",
  "MPX 9x19 6.5 inch barrel_2x1_(2,1)",
  "MPX 9x19 F5 MFG 50-round drum magazine_1x2_(1,1)",
  "MPX 9x19 F5 MFG 50-round drum magazine_1x2_(1,2)",
  "MPX 9x19 TTI 'Base Pad +11' 41-round magazine_1x2_(1,1)",
  "MPX 9x19 TTI 'Base Pad +11' 41-round magazine_1x2_(1,2)",
  "MPX A2 9x19 flash hider_1x1_(1,1)",
  "MPX GEN1 9x19 upper receiver_2x1_(1,1)",
  "MPX GEN1 9x19 upper receiver_2x1_(2,1)",
  "MPX GEN1 handguard 2 inch rail_1x1_(1,1)",
  "MPX GEN1 handguard 4 inch rail_1x1_(1,1)",
  "MPX GEN1 handguard_2x1_(1,1)",
  "MPX GEN1 handguard_2x1_(2,1)",
  "MPX Geissele SCH charging handle_1x1_(1,1)",
  "MPX Lancer OEM 14 inch M-LOK handguard_2x1_(1,1)",
  "MPX Lancer OEM 14 inch M-LOK handguard_2x1_(2,1)",
  "MPX Midwest Industries 10.5 inch M-LOK handguard_2x1_(1,1)",
  "MPX Midwest Industries 10.5 inch M-LOK handguard_2x1_(2,1)",
  "MPX Midwest Industries 14 inch M-LOK handguard_2x1_(1,1)",
  "MPX Midwest Industries 14 inch M-LOK handguard_2x1_(2,1)",
  "MPX Midwest Industries 4.5 inch M-LOK handguard_1x1_(1,1)",
  "MPX Midwest Industries 6.5 inch M-LOK handguard_2x1_(1,1)",
  "MPX Midwest Industries 6.5 inch M-LOK handguard_2x1_(2,1)",
  "MPX double latch charging handle_1x1_(1,1)",
  "MPX flip-up front sight_1x1_(1,1)",
  "MPX flip-up rear sight_1x1_(1,1)",
  "MPX pistol grip_1x1_(1,1)",
  "MPX single latch charging handle_1x1_(1,1)",
  "MPX-SD 9x19 165mm barrel_2x1_(1,1)",
  "MPX-SD 9x19 165mm barrel_2x1_(2,1)",
  "MPX-SD 9x19 integrated sound suppressor_2x1_(1,1)",
  "MPX-SD 9x19 integrated sound suppressor_2x1_(2,1)",
  "MPX@MCX Maxim Defense CQB telescoping stock_2x1_(1,1)",
  "MPX@MCX Maxim Defense CQB telescoping stock_2x1_(2,1)",
  "MPX@MCX PMM ULSS foldable stock_2x1_(1,1)",
  "MPX@MCX PMM ULSS foldable stock_2x1_(2,1)",
  "MPX@MCX collapsing@telescoping stock_2x1_(1,1)",
  "MPX@MCX collapsing@telescoping stock_2x1_(2,1)",
  "MPX@MCX lightweight stock_2x1_(1,1)",
  "MPX@MCX lightweight stock_2x1_(2,1)",
  "MPX@MCX retractable stock adapter_1x1_(1,1)",
  "MPX@MCX telescoping stock_2x1_(1,1)",
  "MPX@MCX telescoping stock_2x1_(2,1)",
  "MRE ration pack_1x2_(1,1)",
  "MRE ration pack_1x2_(1,2)",
  "MS2000 Marker_1x1_(1,1)",
  "MTs 20-01 stock_4x1_(1,1)",
  "MTs 20-01 stock_4x1_(2,1)",
  "MTs 20-01 stock_4x1_(3,1)",
  "MTs 20-01 stock_4x1_(4,1)",
  "MTs-255-12 12ga 755mm barrel with rib_5x1_(1,1)",
  "MTs-255-12 12ga 755mm barrel with rib_5x1_(2,1)",
  "MTs-255-12 12ga 755mm barrel with rib_5x1_(3,1)",
  "MTs-255-12 12ga 755mm barrel with rib_5x1_(4,1)",
  "MTs-255-12 12ga 755mm barrel with rib_5x1_(5,1)",
  "MTs-255-12 12ga choke_1x1_(1,1)",
  "MTs-255-12 12ga cylinder_1x1_(1,1)",
  "MTs-255-12 beechwood forestock_2x1_(1,1)",
  "MTs-255-12 beechwood forestock_2x1_(2,1)",
  "MTs-255-12 wooden stock_2x1_(1,1)",
  "MTs-255-12 wooden stock_2x1_(2,1)",
  "MVD academy entrance hall key_1x1_(1,1)",
  "Machinery key_1x1_(1,1)",
  "Magnet_1x1_(1,1)",
  "Magpul AFG tactical foregrip (Black)_1x1_(1,1)",
  "Magpul AFG tactical foregrip (FDE)_1x1_(1,1)",
  "Magpul AFG tactical foregrip (Forest Green)_1x1_(1,1)",
  "Magpul AFG tactical foregrip (Olive Drab)_1x1_(1,1)",
  "Magpul M-LOK 2.5 inch rail_1x1_(1,1)",
  "Magpul M-LOK 4.1 inch rail_1x1_(1,1)",
  "Magpul M-LOK AFG tactical foregrip (Black)_1x1_(1,1)",
  "Magpul M-LOK AFG tactical foregrip (FDE)_1x1_(1,1)",
  "Magpul M-LOK AFG tactical foregrip (Olive Drab)_1x1_(1,1)",
  "Magpul M-LOK AFG tactical foregrip (Stealth Grey)_1x1_(1,1)",
  "Magpul M-LOK Cantilever Mount_1x1_(1,1)",
  "Magpul M-LOK Offset Light Mount_1x1_(1,1)",
  "Magpul MBUS Gen2 flip-up front sight (FDE)_1x1_(1,1)",
  "Magpul MBUS Gen2 flip-up front sight_1x1_(1,1)",
  "Magpul MBUS Gen2 flip-up rear sight (FDE)_1x1_(1,1)",
  "Magpul MBUS Gen2 flip-up rear sight_1x1_(1,1)",
  "Magpul MOE Carbine rubber buttpad_1x1_(1,1)",
  "Magpul RVG foregrip (Black)_1x1_(1,1)",
  "Magpul RVG foregrip (FDE)_1x1_(1,1)",
  "Malboro Cigarettes_1x1_(1,1)",
  "March Tactical 3-24x42 FFP 30mm riflescope_2x1_(1,1)",
  "March Tactical 3-24x42 FFP 30mm riflescope_2x1_(2,1)",
  "Medical bloodset_1x1_(1,1)",
  "Medical tools_1x1_(1,1)",
  "Meldonin injector_1x1_(1,1)",
  "Merin car trunk key_1x1_(1,1)",
  "Mesa Tactical Crosshair Hydraulic buffer tube_2x1_(1,1)",
  "Mesa Tactical Crosshair Hydraulic buffer tube_2x1_(2,1)",
  "Metal cutting scissors_1x2_(1,1)",
  "Metal cutting scissors_1x2_(1,2)",
  "Metal fuel tank_2x3_(1,1)",
  "Metal fuel tank_2x3_(1,2)",
  "Metal fuel tank_2x3_(1,3)",
  "Metal fuel tank_2x3_(2,1)",
  "Metal fuel tank_2x3_(2,2)",
  "Metal fuel tank_2x3_(2,3)",
  "Metal spare parts_1x1_(1,1)",
  "Microcontroller board_1x1_(1,1)",
  "Military COFDM Wireless Signal Transmitter_2x1_(1,1)",
  "Military COFDM Wireless Signal Transmitter_2x1_(2,1)",
  "Military cable_2x1_(1,1)",
  "Military cable_2x1_(2,1)",
  "Military checkpoint key_1x1_(1,1)",
  "Military circuit board_1x1_(1,1)",
  "Military corrugated tube_1x1_(1,1)",
  "Military flash drive_1x1_(1,1)",
  "Military gyrotachometer_1x2_(1,1)",
  "Military gyrotachometer_1x2_(1,2)",
  "Military power filter_1x1_(1,1)",
  "Milkor M2A1 grenade launcher reflex sight_1x1_(1,1)",
  "Missam forklift key_1x1_(1,1)",
  "Mk-18 .338 LM 10-round magazine_1x2_(1,1)",
  "Mk-18 .338 LM 10-round magazine_1x2_(1,2)",
  "Mk-18 .338 LM 24 inch barrel_4x1_(1,1)",
  "Mk-18 .338 LM 24 inch barrel_4x1_(2,1)",
  "Mk-18 .338 LM 24 inch barrel_4x1_(3,1)",
  "Mk-18 .338 LM 24 inch barrel_4x1_(4,1)",
  "Mk-18 .338 LM upper receiver_2x1_(1,1)",
  "Mk-18 .338 LM upper receiver_2x1_(2,1)",
  "Mk-18 18 inch handguard_3x1_(1,1)",
  "Mk-18 18 inch handguard_3x1_(2,1)",
  "Mk-18 18 inch handguard_3x1_(3,1)",
  "Mk-18 gas block_1x1_(1,1)",
  "Mk47 254mm barrel_2x1_(1,1)",
  "Mk47 254mm barrel_2x1_(2,1)",
  "Mk47 409mm barrel_3x1_(1,1)",
  "Mk47 409mm barrel_3x1_(2,1)",
  "Mk47 409mm barrel_3x1_(3,1)",
  "Mk47 Resolute 7.62x39 upper receiver_2x1_(1,1)",
  "Mk47 Resolute 7.62x39 upper receiver_2x1_(2,1)",
  "Mk47 ambidextrous charging handle_1x1_(1,1)",
  "Model 7290 Flash Bang grenade_1x1_(1,1)",
  "Molot Arms AKM-type dust cover_2x1_(1,1)",
  "Molot Arms AKM-type dust cover_2x1_(2,1)",
  "Molot Arms AKM-type gas tube_1x1_(1,1)",
  "Monstrum Tactical Compact Prism Scope 2x32_2x1_(1,1)",
  "Monstrum Tactical Compact Prism Scope 2x32_2x1_(2,1)",
  "Monstrum Tactical Vertical Fore Grip KeyMod_1x1_(1,1)",
  "Morphine injector_1x1_(1,1)",
  "Mosin Carbine 7.62x54R 514mm barrel_3x1_(1,1)",
  "Mosin Carbine 7.62x54R 514mm barrel_3x1_(2,1)",
  "Mosin Carbine 7.62x54R 514mm barrel_3x1_(3,1)",
  "Mosin Rifle 7.62x54R 5-round magazine_1x1_(1,1)",
  "Mosin Rifle 7.62x54R 730mm regular barrel_4x1_(1,1)",
  "Mosin Rifle 7.62x54R 730mm regular barrel_4x1_(2,1)",
  "Mosin Rifle 7.62x54R 730mm regular barrel_4x1_(3,1)",
  "Mosin Rifle 7.62x54R 730mm regular barrel_4x1_(4,1)",
  "Mosin Rifle 7.62x54R ProMag Archangel OPFOR 10-round magazine_1x1_(1,1)",
  "Mosin Rifle 7.62x54R sawed-off 200mm barrel_2x1_(1,1)",
  "Mosin Rifle 7.62x54R sawed-off 200mm barrel_2x1_(2,1)",
  "Mosin Rifle 7.62x54R sawed-off 220mm threaded barrel_2x1_(1,1)",
  "Mosin Rifle 7.62x54R sawed-off 220mm threaded barrel_2x1_(2,1)",
  "Mosin Rifle AIM Sports MNG rail mount_1x1_(1,1)",
  "Mosin Rifle AIM Sports Recoil Pad_1x1_(1,1)",
  "Mosin Rifle AIM Sports Tri-Rail mount_2x1_(1,1)",
  "Mosin Rifle AIM Sports Tri-Rail mount_2x1_(2,1)",
  "Mosin Rifle ATI Monte Carlo chassis_4x1_(1,1)",
  "Mosin Rifle ATI Monte Carlo chassis_4x1_(2,1)",
  "Mosin Rifle ATI Monte Carlo chassis_4x1_(3,1)",
  "Mosin Rifle ATI Monte Carlo chassis_4x1_(4,1)",
  "Mosin Rifle Arbalet Patriot K+W rail mount_1x1_(1,1)",
  "Mosin Rifle Bramit 7.62x54R sound suppressor_2x1_(1,1)",
  "Mosin Rifle Bramit 7.62x54R sound suppressor_2x1_(2,1)",
  "Mosin Rifle Infantry stock_4x1_(1,1)",
  "Mosin Rifle Infantry stock_4x1_(2,1)",
  "Mosin Rifle Infantry stock_4x1_(3,1)",
  "Mosin Rifle Infantry stock_4x1_(4,1)",
  "Mosin Rifle Kiba Arms 7.62x54R custom thread adapter_1x1_(1,1)",
  "Mosin Rifle Kochetov mount_1x1_(1,1)",
  "Mosin Rifle ProMag Archangel OPFOR PRS chassis_4x1_(1,1)",
  "Mosin Rifle ProMag Archangel OPFOR PRS chassis_4x1_(2,1)",
  "Mosin Rifle ProMag Archangel OPFOR PRS chassis_4x1_(3,1)",
  "Mosin Rifle ProMag Archangel OPFOR PRS chassis_4x1_(4,1)",
  "Mosin Rifle Tacfire Tanker Style 7.62x54R muzzle brake_1x1_(1,1)",
  "Mosin Rifle Tacfire pistol grip_1x1_(1,1)",
  "Mosin Rifle Texas Precision Products 7.62x54R muzzle brake_1x1_(1,1)",
  "Mosin Rifle Tiger Rock 7.62x51 thread adapter_1x1_(1,1)",
  "Mosin Rifle Weapon Tuning 7.62x39 thread adapter_1x1_(1,1)",
  "Mosin Rifle Witt Machine 7.62x54R muzzle brake_1x1_(1,1)",
  "Mosin Rifle carbine rear sight_1x1_(1,1)",
  "Mosin Rifle carbine stock_3x1_(1,1)",
  "Mosin Rifle carbine stock_3x1_(2,1)",
  "Mosin Rifle carbine stock_3x1_(3,1)",
  "Mosin Rifle front sight_1x1_(1,1)",
  "Mosin Rifle rear sight_1x1_(1,1)",
  "Mosin Rifle sawed-off sniper stock_2x1_(1,1)",
  "Mosin Rifle sawed-off sniper stock_2x1_(2,1)",
  "Mosin Rifle sawed-off stock_2x1_(1,1)",
  "Mosin Rifle sawed-off stock_2x1_(2,1)",
  "Mosin Rifle sniper carbine stock_3x1_(1,1)",
  "Mosin Rifle sniper carbine stock_3x1_(2,1)",
  "Mosin Rifle sniper carbine stock_3x1_(3,1)",
  "Mosin Rifle standard stock_4x1_(1,1)",
  "Mosin Rifle standard stock_4x1_(2,1)",
  "Mosin Rifle standard stock_4x1_(3,1)",
  "Mosin Rifle standard stock_4x1_(4,1)",
  "Mossberg 590 Ghost Ring front sight_1x1_(1,1)",
  "Mossberg 590 Ghost Ring rear sight_1x1_(1,1)",
  "Mossberg 590A1 12ga 20 inch barrel_3x1_(1,1)",
  "Mossberg 590A1 12ga 20 inch barrel_3x1_(2,1)",
  "Mossberg 590A1 12ga 20 inch barrel_3x1_(3,1)",
  "Mossberg 590A1 12ga 8-shell magazine cap_1x1_(1,1)",
  "Mossberg 590A1 Magpul MOE forestock_2x1_(1,1)",
  "Mossberg 590A1 Magpul MOE forestock_2x1_(2,1)",
  "Mossberg 590A1 Magpul SGA stock_2x1_(1,1)",
  "Mossberg 590A1 Magpul SGA stock_2x1_(2,1)",
  "Mossberg 590A1 Mesa Tactical LEO gen.1 stock adapter_1x1_(1,1)",
  "Mossberg 590A1 SpeedFeed short handguard_2x1_(1,1)",
  "Mossberg 590A1 SpeedFeed short handguard_2x1_(2,1)",
  "Mossberg 590A1 Tactical rail_3x1_(1,1)",
  "Mossberg 590A1 Tactical rail_3x1_(2,1)",
  "Mossberg 590A1 Tactical rail_3x1_(3,1)",
  "Mossberg 590A1 polymer stock_2x1_(1,1)",
  "Mossberg 590A1 polymer stock_2x1_(2,1)",
  "Mysterious room marked key_1x1_(1,1)",
  "NIXXOR lens_1x1_(1,1)",
  "NPZ 1P78 scope eyecup_1x1_(1,1)",
  "NPZ 1P78-1 2.8x scope_1x1_(1,1)",
  "NPZ 1P78-1 dovetail mount_1x1_(1,1)",
  "NPZ PK1 'Obzor' dovetail reflex sight_1x1_(1,1)",
  "NPZ USP-1 'Tyulpan' 4x scope_1x1_(1,1)",
  "NPZ USP-1 scope eyecup_1x1_(1,1)",
  "NSPU-M 3.5x dovetail night vision scope_2x1_(1,1)",
  "NSPU-M 3.5x dovetail night vision scope_2x1_(2,1)",
  "NSPU-M scope eyecup_1x1_(1,1)",
  "NcSTAR ADO P4 Sniper 3-9x42 riflescope_2x1_(1,1)",
  "NcSTAR ADO P4 Sniper 3-9x42 riflescope_2x1_(2,1)",
  "NcSTAR MPR45 Backup mount_1x1_(1,1)",
  "NcSTAR Tactical blue laser LAM-module_1x1_(1,1)",
  "NecrusPharm pharmacy key_1x1_(1,1)",
  "Nightforce ATACR 7-35x56 34mm riflescope_2x1_(1,1)",
  "Nightforce ATACR 7-35x56 34mm riflescope_2x1_(2,1)",
  "Nightforce Magmount 30mm ring scope mount_2x1_(1,1)",
  "Nightforce Magmount 30mm ring scope mount_2x1_(2,1)",
  "Nightforce Magmount 34mm ring scope mount with Ruggedized Accessory Platform_2x1_(1,1)",
  "Nightforce Magmount 34mm ring scope mount with Ruggedized Accessory Platform_2x1_(2,1)",
  "Nightforce Magmount 34mm ring scope mount_2x1_(1,1)",
  "Nightforce Magmount 34mm ring scope mount_2x1_(2,1)",
  "Nippers_1x1_(1,1)",
  "Norotos Titanium Advanced Tactical Mount_1x1_(1,1)",
  "OFZ 30x160mm shell_1x2_(1,1)",
  "OFZ 30x160mm shell_1x2_(1,2)",
  "OKP-7 reflex sight (Dovetail)_1x1_(1,1)",
  "OKP-7 reflex sight_1x1_(1,1)",
  "OLI administration office key_1x1_(1,1)",
  "OLI cash register key_1x1_(1,1)",
  "OLI logistics department office key_1x1_(1,1)",
  "OLI outlet utility room key_1x1_(1,1)",
  "OP-SKS 7.62x39 520mm barrel_3x1_(1,1)",
  "OP-SKS 7.62x39 520mm barrel_3x1_(2,1)",
  "OP-SKS 7.62x39 520mm barrel_3x1_(3,1)",
  "OP-SKS dovetail mount_1x1_(1,1)",
  "OP-SKS dust cover_1x1_(1,1)",
  "OP-SKS gas tube cover_1x1_(1,1)",
  "OP-SKS gas tube_1x1_(1,1)",
  "OP-SKS upper band_1x1_(1,1)",
  "OP-SKS wooden stock_4x1_(1,1)",
  "OP-SKS wooden stock_4x1_(2,1)",
  "OP-SKS wooden stock_4x1_(3,1)",
  "OP-SKS wooden stock_4x1_(4,1)",
  "ORSIS T-5000M 7.62x51 5-round magazine_1x1_(1,1)",
  "ORSIS T-5000M 7.62x51 660mm barrel_3x1_(1,1)",
  "ORSIS T-5000M 7.62x51 660mm barrel_3x1_(2,1)",
  "ORSIS T-5000M 7.62x51 660mm barrel_3x1_(3,1)",
  "ORSIS T-5000M 7.62x51 muzzle brake-compensator_1x1_(1,1)",
  "ORSIS T-5000M aluminium body_2x1_(1,1)",
  "ORSIS T-5000M aluminium body_2x1_(2,1)",
  "ORSIS T-5000M handguard_2x1_(1,1)",
  "ORSIS T-5000M handguard_2x1_(2,1)",
  "ORSIS T-5000M long length rail_1x1_(1,1)",
  "ORSIS T-5000M medium length rail_1x1_(1,1)",
  "ORSIS T-5000M padded handguard grip_1x1_(1,1)",
  "ORSIS T-5000M pistol grip_1x1_(1,1)",
  "ORSIS T-5000M scope mount_2x1_(1,1)",
  "ORSIS T-5000M scope mount_2x1_(2,1)",
  "ORSIS T-5000M stock_2x1_(1,1)",
  "ORSIS T-5000M stock_2x1_(2,1)",
  "Object #11SR keycard_1x1_(1,1)",
  "Object #21WS keycard_1x1_(1,1)",
  "Old firesteel_1x1_(1,1)",
  "Olight Baldr Pro tactical flashlight with laser (Tan)_1x1_(1,1)",
  "Olight Baldr Pro tactical flashlight with laser_1x1_(1,1)",
  "Operating room key_1x1_(1,1)",
  "Ophthalmoscope_1x1_(1,1)",
  "Ops-Core Picatinny Rail Adapter mount_1x1_(1,1)",
  "Ops-Core Single Clamp Rail Adapter mount_1x1_(1,1)",
  "Ortodontox toothpaste_1x1_(1,1)",
  "Ox bleach_1x2_(1,1)",
  "Ox bleach_1x2_(1,2)",
  "P22 (Product 22) stimulant injector_1x1_(1,1)",
  "P226 9x19 112mm barrel_1x1_(1,1)",
  "P226 9x19 15-round magazine_1x1_(1,1)",
  "P226 9x19 20-round extended magazine_1x1_(1,1)",
  "P226 9x19 threaded barrel_1x1_(1,1)",
  "P226 Axelson Tactical MK25 pistol grip_1x1_(1,1)",
  "P226 Axelson Tactical MK25 pistol slide_2x1_(1,1)",
  "P226 Axelson Tactical MK25 pistol slide_2x1_(2,1)",
  "P226 Bridge sight mount_1x1_(1,1)",
  "P226 Combat pistol grip (FDE)_1x1_(1,1)",
  "P226 Emperor Scorpion pistol grip_1x1_(1,1)",
  "P226 Emperor Scorpion pistol slide_2x1_(1,1)",
  "P226 Emperor Scorpion pistol slide_2x1_(2,1)",
  "P226 Hogue G10 Chain Link pistol grip_1x1_(1,1)",
  "P226 Hogue Rubberized pistol grip_1x1_(1,1)",
  "P226 Legion full size pistol slide_2x1_(1,1)",
  "P226 Legion full size pistol slide_2x1_(2,1)",
  "P226 Meprolight TRU-DOT Night front sight_1x1_(1,1)",
  "P226 Meprolight TRU-DOT Night rear sight_1x1_(1,1)",
  "P226 Sight Mount 220-239 rear sight bearing_1x1_(1,1)",
  "P226 Stainless Elite Wooden pistol grip_1x1_(1,1)",
  "P226 Stainless Elite pistol slide_2x1_(1,1)",
  "P226 Stainless Elite pistol slide_2x1_(2,1)",
  "P226 TJ's Custom 9x19 compensator_1x1_(1,1)",
  "P226 Trijicon RMR mount_1x1_(1,1)",
  "P226 front sight_1x1_(1,1)",
  "P226 polymer pistol grip (Black)_1x1_(1,1)",
  "P226 rear sight_1x1_(1,1)",
  "P226 thread protection cap_1x1_(1,1)",
  "P226R MK25 pistol slide_2x1_(1,1)",
  "P226R MK25 pistol slide_2x1_(2,1)",
  "PAID AntiRoach spray_1x2_(1,1)",
  "PAID AntiRoach spray_1x2_(1,2)",
  "PB 9x18PM sound suppressor_2x1_(1,1)",
  "PB 9x18PM sound suppressor_2x1_(2,1)",
  "PB bakelite side grips_1x1_(1,1)",
  "PC CPU_1x1_(1,1)",
  "PE teacher's office key_1x1_(1,1)",
  "PK 7.62x54R 100-round box_2x2_(1,1)",
  "PK 7.62x54R 100-round box_2x2_(1,2)",
  "PK 7.62x54R 100-round box_2x2_(2,1)",
  "PK 7.62x54R 100-round box_2x2_(2,2)",
  "PK Zenit B-50 16mm top mount_1x1_(1,1)",
  "PK Zenit B-50 66mm top mount_1x1_(1,1)",
  "PK Zenit B-50 handguard_2x1_(1,1)",
  "PK Zenit B-50 handguard_2x1_(2,1)",
  "PK Zenit DTK-1P 7.62x54R muzzle brake_1x1_(1,1)",
  "PK Zenit PT-2 'Klassika' stock_2x1_(1,1)",
  "PK Zenit PT-2 'Klassika' stock_2x1_(2,1)",
  "PK bipod_2x1_(1,1)",
  "PK bipod_2x1_(2,1)",
  "PK pistol grip (Black)_1x1_(1,1)",
  "PK pistol grip_1x1_(1,1)",
  "PK rear sight_1x1_(1,1)",
  "PKM 7.62x54R 658mm barrel_4x1_(1,1)",
  "PKM 7.62x54R 658mm barrel_4x1_(2,1)",
  "PKM 7.62x54R 658mm barrel_4x1_(3,1)",
  "PKM 7.62x54R 658mm barrel_4x1_(4,1)",
  "PKM 7.62x54R muzzle brake_1x1_(1,1)",
  "PKM 7.62x54R slotted flash hider_1x1_(1,1)",
  "PKM wooden stock_2x1_(1,1)",
  "PKM wooden stock_2x1_(2,1)",
  "PKP 7.62x54R 658mm barrel_4x1_(1,1)",
  "PKP 7.62x54R 658mm barrel_4x1_(2,1)",
  "PKP 7.62x54R 658mm barrel_4x1_(3,1)",
  "PKP 7.62x54R 658mm barrel_4x1_(4,1)",
  "PKP polymer stock_2x1_(1,1)",
  "PKP polymer stock_2x1_(2,1)",
  "PKTM 7.62x54R 250-round belt_2x2_(1,1)",
  "PKTM 7.62x54R 250-round belt_2x2_(1,2)",
  "PKTM 7.62x54R 250-round belt_2x2_(2,1)",
  "PKTM 7.62x54R 250-round belt_2x2_(2,2)",
  "PL-15 9x19 16-round magazine_1x1_(1,1)",
  "PL-15 9x19 barrel_1x1_(1,1)",
  "PL-15 9x19 sound suppressor_2x1_(1,1)",
  "PL-15 9x19 sound suppressor_2x1_(2,1)",
  "PL-15 9x19 threaded barrel_1x1_(1,1)",
  "PL-15 extended front sight_1x1_(1,1)",
  "PL-15 extended rear sight_1x1_(1,1)",
  "PL-15 front sight_1x1_(1,1)",
  "PL-15 pistol slide_2x1_(1,1)",
  "PL-15 pistol slide_2x1_(2,1)",
  "PL-15 rear sight_1x1_(1,1)",
  "PM 9x18PM 84-round makeshift drum magazine_2x2_(1,1)",
  "PM 9x18PM 84-round makeshift drum magazine_2x2_(1,2)",
  "PM 9x18PM 84-round makeshift drum magazine_2x2_(2,1)",
  "PM 9x18PM 84-round makeshift drum magazine_2x2_(2,2)",
  "PM 9x18PM 90-93 8-round magazine_1x1_(1,1)",
  "PM FAB Defense PM-G pistol grip (Olive Drab)_1x1_(1,1)",
  "PM FAB Defense PM-G pistol grip_1x1_(1,1)",
  "PM Tactic Kit pistol grip_2x1_(1,1)",
  "PM Tactic Kit pistol grip_2x1_(2,1)",
  "PM bakelite side grips_1x1_(1,1)",
  "PM pistol slide_2x1_(1,1)",
  "PM pistol slide_2x1_(2,1)",
  "PM rear sight_1x1_(1,1)",
  "PNB (Product 16) stimulant injector_1x1_(1,1)",
  "PNV-10T Night Vision Goggles_2x1_(1,1)",
  "PNV-10T Night Vision Goggles_2x1_(2,1)",
  "PNV-10T dovetail adapter_1x1_(1,1)",
  "PP-19-01 'Vityaz' 9x19 30-round magazine_1x2_(1,1)",
  "PP-19-01 'Vityaz' 9x19 30-round magazine_1x2_(1,2)",
  "PP-19-01 'Vityaz' 9x19 PUFGUN SG-919 20 20-round magazine_1x2_(1,1)",
  "PP-19-01 'Vityaz' 9x19 PUFGUN SG-919 20 20-round magazine_1x2_(1,2)",
  "PP-19-01 'Vityaz' 9x19 PUFGUN SG-919 30 30-round magazine_1x2_(1,1)",
  "PP-19-01 'Vityaz' 9x19 PUFGUN SG-919 30 30-round magazine_1x2_(1,2)",
  "PP-19-01 'Vityaz' 9x19 muzzle brake-compensator_1x1_(1,1)",
  "PP-19-01 'Vityaz' 9x19 sound suppressor_2x1_(1,1)",
  "PP-19-01 'Vityaz' 9x19 sound suppressor_2x1_(2,1)",
  "PP-19-01 'Vityaz' dust cover_2x1_(1,1)",
  "PP-19-01 'Vityaz' dust cover_2x1_(2,1)",
  "PP-19-01 'Vityaz' gas tube_1x1_(1,1)",
  "PP-19-01 'Vityaz' metal skeleton stock_2x1_(1,1)",
  "PP-19-01 'Vityaz' metal skeleton stock_2x1_(2,1)",
  "PP-19-01 'Vityaz' pistol grip_1x1_(1,1)",
  "PP-19-01 'Vityaz' rear sight_1x1_(1,1)",
  "PP-19-01 'Vityaz-SN' dust cover_2x1_(1,1)",
  "PP-19-01 'Vityaz-SN' dust cover_2x1_(2,1)",
  "PP-91 'Kedr' 9x18PM 20-round magazine_1x1_(1,1)",
  "PP-91 'Kedr' 9x18PM 30-round magazine_1x2_(1,1)",
  "PP-91 'Kedr' 9x18PM 30-round magazine_1x2_(1,2)",
  "PP-91 'Kedr' Rotor 43 RIS mount_1x1_(1,1)",
  "PP-91 'Kedr' polymer pistol grip_1x1_(1,1)",
  "PP-91-01 'Kedr-B' 9x18PM sound suppressor_2x1_(1,1)",
  "PP-91-01 'Kedr-B' 9x18PM sound suppressor_2x1_(2,1)",
  "PP-91-01 'Kedr-B' threaded suppressor adapter_1x1_(1,1)",
  "PPSh-41 7.62x25 10.6 inch barrel_2x1_(1,1)",
  "PPSh-41 7.62x25 10.6 inch barrel_2x1_(2,1)",
  "PPSh-41 7.62x25 35-round magazine_1x2_(1,1)",
  "PPSh-41 7.62x25 35-round magazine_1x2_(1,2)",
  "PPSh-41 7.62x25 71-round drum magazine_2x2_(1,1)",
  "PPSh-41 7.62x25 71-round drum magazine_2x2_(1,2)",
  "PPSh-41 7.62x25 71-round drum magazine_2x2_(2,1)",
  "PPSh-41 7.62x25 71-round drum magazine_2x2_(2,2)",
  "PPSh-41 dust cover_2x1_(1,1)",
  "PPSh-41 dust cover_2x1_(2,1)",
  "PPSh-41 stock_2x1_(1,1)",
  "PPSh-41 stock_2x1_(2,1)",
  "PSO scope eyecup_1x1_(1,1)",
  "PU 3.5x riflescope_1x1_(1,1)",
  "PU 3.5x ring scope mount_1x1_(1,1)",
  "Pack of Arseniy buckwheat_1x2_(1,1)",
  "Pack of Arseniy buckwheat_1x2_(1,2)",
  "Pack of Grand juice_1x2_(1,1)",
  "Pack of Grand juice_1x2_(1,2)",
  "Pack of Russian Army pineapple juice_1x2_(1,1)",
  "Pack of Russian Army pineapple juice_1x2_(1,2)",
  "Pack of Tarker dried meat_1x1_(1,1)",
  "Pack of Vita juice_1x2_(1,1)",
  "Pack of Vita juice_1x2_(1,2)",
  "Pack of apple juice_1x2_(1,1)",
  "Pack of apple juice_1x2_(1,2)",
  "Pack of chlorine_2x1_(1,1)",
  "Pack of chlorine_2x1_(2,1)",
  "Pack of instant noodles_1x1_(1,1)",
  "Pack of milk_1x2_(1,1)",
  "Pack of milk_1x2_(1,2)",
  "Pack of nails_1x1_(1,1)",
  "Pack of oat flakes_1x2_(1,1)",
  "Pack of oat flakes_1x2_(1,2)",
  "Pack of screws_1x1_(1,1)",
  "Pack of sodium bicarbonate_1x1_(1,1)",
  "Pack of sugar_1x1_(1,1)",
  "Paracord_2x1_(1,1)",
  "Paracord_2x1_(2,1)",
  "Perfotoran (Blue Blood) stimulant injector_1x1_(1,1)",
  "Phase control relay_1x1_(1,1)",
  "Phased array element_2x2_(1,1)",
  "Phased array element_2x2_(1,2)",
  "Phased array element_2x2_(2,1)",
  "Phased array element_2x2_(2,2)",
  "Physical Bitcoin_1x1_(1,1)",
  "Piece of plexiglass_1x1_(1,1)",
  "Pile of meds_1x1_(1,1)",
  "Pinewood hotel room 206 key_1x1_(1,1)",
  "Pinewood hotel room 215 key_1x1_(1,1)",
  "Pipe grip wrench_1x2_(1,1)",
  "Pipe grip wrench_1x2_(1,2)",
  "Pliers Elite_1x1_(1,1)",
  "Pliers_1x1_(1,1)",
  "Police truck cabin key_1x1_(1,1)",
  "Politician Mutkevich figurine_1x2_(1,1)",
  "Politician Mutkevich figurine_1x2_(1,2)",
  "Portable Powerbank_1x1_(1,1)",
  "Portable bunkhouse key_1x1_(1,1)",
  "Portable cabin key_1x1_(1,1)",
  "Portable defibrillator_1x1_(1,1)",
  "Power cord_2x1_(1,1)",
  "Power cord_2x1_(2,1)",
  "Power substation utility cabin key_1x1_(1,1)",
  "Power supply unit_2x2_(1,1)",
  "Power supply unit_2x2_(1,2)",
  "Power supply unit_2x2_(2,1)",
  "Power supply unit_2x2_(2,2)",
  "Precision Reflex handguard bottom rail mount_1x1_(1,1)",
  "Precision Reflex handguard long top rail mount_2x1_(1,1)",
  "Precision Reflex handguard long top rail mount_2x1_(2,1)",
  "Precision Reflex handguard short side rail mount_1x1_(1,1)",
  "Press pass (issued for NoiceGuy)_1x2_(1,1)",
  "Press pass (issued for NoiceGuy)_1x2_(1,2)",
  "Pressure gauge_2x1_(1,1)",
  "Pressure gauge_2x1_(2,1)",
  "Primorsky 46-48 skybridge key_1x1_(1,1)",
  "Primorsky 48 apartment key_1x1_(1,1)",
  "Primorsky Ave apartment key_1x1_(1,1)",
  "Printed circuit board_1x1_(1,1)",
  "Printer paper_2x1_(1,1)",
  "Printer paper_2x1_(2,1)",
  "Propane tank (5L)_2x2_(1,1)",
  "Propane tank (5L)_2x2_(1,2)",
  "Propane tank (5L)_2x2_(2,1)",
  "Propane tank (5L)_2x2_(2,2)",
  "Propital regenerative stimulant injector_1x1_(1,1)",
  "Pumping station back door key_1x1_(1,1)",
  "Pumping station front door key_1x1_(1,1)",
  "R11 RSASS handguard_2x1_(1,1)",
  "R11 RSASS handguard_2x1_(2,1)",
  "RAM_1x1_(1,1)",
  "RB-AK key_1x1_(1,1)",
  "RB-AM key_1x1_(1,1)",
  "RB-AO key_1x1_(1,1)",
  "RB-BK marked key_1x1_(1,1)",
  "RB-GN key_1x1_(1,1)",
  "RB-KORL key_1x1_(1,1)",
  "RB-KPRL key_1x1_(1,1)",
  "RB-KSM key_1x1_(1,1)",
  "RB-MP11 key_1x1_(1,1)",
  "RB-MP12 key_1x1_(1,1)",
  "RB-MP13 key_1x1_(1,1)",
  "RB-MP21 key_1x1_(1,1)",
  "RB-MP22 key_1x1_(1,1)",
  "RB-OB key_1x1_(1,1)",
  "RB-OP key_1x1_(1,1)",
  "RB-ORB1 key_1x1_(1,1)",
  "RB-ORB2 key_1x1_(1,1)",
  "RB-ORB3 key_1x1_(1,1)",
  "RB-PKPM marked key_1x1_(1,1)",
  "RB-PP key_1x1_(1,1)",
  "RB-PSP1 key_1x1_(1,1)",
  "RB-PSP2 key_1x1_(1,1)",
  "RB-PSV1 key_1x1_(1,1)",
  "RB-PSV2 key_1x1_(1,1)",
  "RB-RH key_1x1_(1,1)",
  "RB-RLSA key_1x1_(1,1)",
  "RB-RS key_1x1_(1,1)",
  "RB-SMP key_1x1_(1,1)",
  "RB-ST key_1x1_(1,1)",
  "RB-TB key_1x1_(1,1)",
  "RB-VO marked key_1x1_(1,1)",
  "RD-704 SLR ION Lite + Railed Gas Tube handguard & gas tube combo_2x1_(1,1)",
  "RD-704 SLR ION Lite + Railed Gas Tube handguard & gas tube combo_2x1_(2,1)",
  "RD-704 dust cover_2x1_(1,1)",
  "RD-704 dust cover_2x1_(2,1)",
  "RDG-2B smoke grenade_1x2_(1,1)",
  "RDG-2B smoke grenade_1x2_(1,2)",
  "RFB 7.62x51 18 inch barrel_3x1_(1,1)",
  "RFB 7.62x51 18 inch barrel_3x1_(2,1)",
  "RFB 7.62x51 18 inch barrel_3x1_(3,1)",
  "RFB 7.62x51 flash hider_1x1_(1,1)",
  "RFB handguard rail mount_1x1_(1,1)",
  "RFB handguard_2x1_(1,1)",
  "RFB handguard_2x1_(2,1)",
  "RFB scope rail mount_2x1_(1,1)",
  "RFB scope rail mount_2x1_(2,1)",
  "RFB thread protection cap_1x1_(1,1)",
  "RFB thread spacer_1x1_(1,1)",
  "RGD-5 hand grenade_1x1_(1,1)",
  "RGN hand grenade_1x1_(1,1)",
  "RGO hand grenade_1x1_(1,1)",
  "RPD 7.62x39 'Buben' 100-round box_2x2_(1,1)",
  "RPD 7.62x39 'Buben' 100-round box_2x2_(1,2)",
  "RPD 7.62x39 'Buben' 100-round box_2x2_(2,1)",
  "RPD 7.62x39 'Buben' 100-round box_2x2_(2,2)",
  "RPD 7.62x39 520mm barrel_4x1_(1,1)",
  "RPD 7.62x39 520mm barrel_4x1_(2,1)",
  "RPD 7.62x39 520mm barrel_4x1_(3,1)",
  "RPD 7.62x39 520mm barrel_4x1_(4,1)",
  "RPD 7.62x39 sawed-off 350mm barrel_3x1_(1,1)",
  "RPD 7.62x39 sawed-off 350mm barrel_3x1_(2,1)",
  "RPD 7.62x39 sawed-off 350mm barrel_3x1_(3,1)",
  "RPD barrel thread protector_1x1_(1,1)",
  "RPD bipod_2x1_(1,1)",
  "RPD bipod_2x1_(2,1)",
  "RPD rear sight_1x1_(1,1)",
  "RPD wooden handguard_1x1_(1,1)",
  "RPD wooden pistol grip_1x1_(1,1)",
  "RPD wooden stock_2x1_(1,1)",
  "RPD wooden stock_2x1_(2,1)",
  "RPK-16 5.45x39 15 inch barrel_2x1_(1,1)",
  "RPK-16 5.45x39 15 inch barrel_2x1_(2,1)",
  "RPK-16 5.45x39 22 inch barrel_3x1_(1,1)",
  "RPK-16 5.45x39 22 inch barrel_3x1_(2,1)",
  "RPK-16 5.45x39 22 inch barrel_3x1_(3,1)",
  "RPK-16 5.45x39 95-round drum magazine_2x2_(1,1)",
  "RPK-16 5.45x39 95-round drum magazine_2x2_(1,2)",
  "RPK-16 5.45x39 95-round drum magazine_2x2_(2,1)",
  "RPK-16 5.45x39 95-round drum magazine_2x2_(2,2)",
  "RPK-16 5.45x39 muzzle brake-compensator_1x1_(1,1)",
  "RPK-16 buffer tube_2x1_(1,1)",
  "RPK-16 buffer tube_2x1_(2,1)",
  "RPK-16 dust cover_2x1_(1,1)",
  "RPK-16 dust cover_2x1_(2,1)",
  "RPK-16 handguard rail_1x1_(1,1)",
  "RPK-16 handguard_2x1_(1,1)",
  "RPK-16 handguard_2x1_(2,1)",
  "RPK-16 rear sight base_1x1_(1,1)",
  "RPK-16 rear sight_1x1_(1,1)",
  "RSh-12 12.7x55 5-round cylinder_1x1_(1,1)",
  "RSh-12 pistol grip_1x1_(1,1)",
  "RTM Osovets P-2 tactical foregrip (Khaki)_1x1_(1,1)",
  "RTM Osovets P-2 tactical foregrip_1x1_(1,1)",
  "RTM Pillau P-2 tactical foregrip (Red)_1x1_(1,1)",
  "RTM Pillau tactical foregrip (Sand)_1x1_(1,1)",
  "RTM Pillau tactical foregrip_1x1_(1,1)",
  "Radar station commandant room key_1x1_(1,1)",
  "Radiator helix_1x1_(1,1)",
  "Ratchet wrench_1x2_(1,1)",
  "Ratchet wrench_1x2_(1,2)",
  "Raven figurine_1x2_(1,1)",
  "Raven figurine_1x2_(1,2)",
  "Real estate agency office room key_1x1_(1,1)",
  "Rechargeable battery_1x1_(1,1)",
  "Recknagel Era-Tac 30mm ring scope mount_2x1_(1,1)",
  "Recknagel Era-Tac 30mm ring scope mount_2x1_(2,1)",
  "Recknagel Era-Tac 34mm ring scope mount_2x1_(1,1)",
  "Recknagel Era-Tac 34mm ring scope mount_2x1_(2,1)",
  "Recknagel Era-Tac Sunshade mount for Aimpoint T-1 sights_1x1_(1,1)",
  "Relaxation room key_1x1_(1,1)",
  "Remington RAHG 2 inch rail_1x1_(1,1)",
  "Remington RAHG 4 inch rail_1x1_(1,1)",
  "Remington Tactical Choke 12ga_1x1_(1,1)",
  "Repellent_1x2_(1,1)",
  "Repellent_1x2_(1,2)",
  "Reptilia ROF-90 RMR mount for Geissele scope mounts (DDC)_1x1_(1,1)",
  "Reptilia ROF-90 RMR mount for Geissele scope mounts_1x1_(1,1)",
  "Ripstop fabric_2x1_(1,1)",
  "Ripstop fabric_2x1_(2,1)",
  "Rogue USEC barrack key_1x1_(1,1)",
  "Rogue USEC stash key_1x1_(1,1)",
  "Rogue USEC workshop key_1x1_(1,1)",
  "Roler Submariner gold wrist watch_1x1_(1,1)",
  "Rotor 43 .366 TKM muzzle brake-compensator_2x1_(1,1)",
  "Rotor 43 .366 TKM muzzle brake-compensator_2x1_(2,1)",
  "Rotor 43 5.56x45 muzzle brake-compensator_2x1_(1,1)",
  "Rotor 43 5.56x45 muzzle brake-compensator_2x1_(2,1)",
  "Rotor 43 7.62x39 muzzle brake-compensator_2x1_(1,1)",
  "Rotor 43 7.62x39 muzzle brake-compensator_2x1_(2,1)",
  "Rotor 43 7.62x54R muzzle brake-compensator_2x1_(1,1)",
  "Rotor 43 7.62x54R muzzle brake-compensator_2x1_(2,1)",
  "Rotor 43 9x19 muzzle brake-compensator_2x1_(1,1)",
  "Rotor 43 9x19 muzzle brake-compensator_2x1_(2,1)",
  "Round pliers_1x1_(1,1)",
  "Rusted bloody key_1x1_(1,1)",
  "Rye croutons_1x1_(1,1)",
  "Ryzhy figurine_1x2_(1,1)",
  "Ryzhy figurine_1x2_(1,2)",
  "SA-58 3-Prong Trident 7.62x51 flash hider_1x1_(1,1)",
  "SA-58 7.62x51 11 inch barrel_2x1_(1,1)",
  "SA-58 7.62x51 11 inch barrel_2x1_(2,1)",
  "SA-58 7.62x51 16 inch barrel_3x1_(1,1)",
  "SA-58 7.62x51 16 inch barrel_3x1_(2,1)",
  "SA-58 7.62x51 16 inch barrel_3x1_(3,1)",
  "SA-58 7.62x51 21 inch barrel_4x1_(1,1)",
  "SA-58 7.62x51 21 inch barrel_4x1_(2,1)",
  "SA-58 7.62x51 21 inch barrel_4x1_(3,1)",
  "SA-58 7.62x51 21 inch barrel_4x1_(4,1)",
  "SA-58 Austrian Style 7.62x51 muzzle brake_1x1_(1,1)",
  "SA-58 BRS stock_2x1_(1,1)",
  "SA-58 BRS stock_2x1_(2,1)",
  "SA-58 Extreme Duty dust cover_2x1_(1,1)",
  "SA-58 Extreme Duty dust cover_2x1_(2,1)",
  "SA-58 FAB Defense AG-FAL pistol grip_1x1_(1,1)",
  "SA-58 Holland Type rear sight_1x1_(1,1)",
  "SA-58 SAW-style pistol grip (Black)_1x1_(1,1)",
  "SA-58 SPR stock_2x1_(1,1)",
  "SA-58 SPR stock_2x1_(2,1)",
  "SA-58 buffer tube adapter_1x1_(1,1)",
  "SA-58 folding stock_2x1_(1,1)",
  "SA-58 folding stock_2x1_(2,1)",
  "SA-58 humpback polymer stock_2x1_(1,1)",
  "SA-58 humpback polymer stock_2x1_(2,1)",
  "SA-58 pistol grip_1x1_(1,1)",
  "SA-58 quad rail full length handguard_2x1_(1,1)",
  "SA-58 quad rail full length handguard_2x1_(2,1)",
  "SA-58 quad rail handguard_2x1_(1,1)",
  "SA-58 quad rail handguard_2x1_(2,1)",
  "SA-58@FAL 7.62x51 10-round magazine_1x1_(1,1)",
  "SA-58@FAL 7.62x51 20-round magazine_1x2_(1,1)",
  "SA-58@FAL 7.62x51 20-round magazine_1x2_(1,2)",
  "SA-58@FAL 7.62x51 30-round magazine_1x2_(1,1)",
  "SA-58@FAL 7.62x51 30-round magazine_1x2_(1,2)",
  "SA-58@FAL 7.62x51 MMW polymer 20-round magazine_1x2_(1,1)",
  "SA-58@FAL 7.62x51 MMW polymer 20-round magazine_1x2_(1,2)",
  "SA-58@FAL 7.62x51 X Products X-FAL 50-round drum magazine_1x2_(1,1)",
  "SA-58@FAL 7.62x51 X Products X-FAL 50-round drum magazine_1x2_(1,2)",
  "SA-58@FAL AIM Sports Universal KeyMod handguard_2x1_(1,1)",
  "SA-58@FAL AIM Sports Universal KeyMod handguard_2x1_(2,1)",
  "SA-58@FAL AIM Sports Universal M-LOK handguard_2x1_(1,1)",
  "SA-58@FAL AIM Sports Universal M-LOK handguard_2x1_(2,1)",
  "SA-58@FAL Belgian Style handguard_2x1_(1,1)",
  "SA-58@FAL Belgian Style handguard_2x1_(2,1)",
  "SA-58@FAL Magpul PRS 2 polymer stock_2x1_(1,1)",
  "SA-58@FAL Magpul PRS 2 polymer stock_2x1_(2,1)",
  "SA-58@FAL Vltor CASV-FAL handguard_2x1_(1,1)",
  "SA-58@FAL Vltor CASV-FAL handguard_2x1_(2,1)",
  "SA-58@FAL Vltor CASV-FAS handguard_2x1_(1,1)",
  "SA-58@FAL Vltor CASV-FAS handguard_2x1_(2,1)",
  "SAG AK dovetail sidemount_1x1_(1,1)",
  "SAG Bit low profile dovetail sidemount_1x1_(1,1)",
  "SAS drive_1x1_(1,1)",
  "SIG ALPHA4 30mm ring scope mount_2x1_(1,1)",
  "SIG ALPHA4 30mm ring scope mount_2x1_(2,1)",
  "SIG Sauer 7.62x51 3-prong flash hider_1x1_(1,1)",
  "SIG Sauer BRAVO4 4x30 scope_2x1_(1,1)",
  "SIG Sauer BRAVO4 4x30 scope_2x1_(2,1)",
  "SIG Sauer ECHO1 1-2x30mm 30Hz thermal reflex scope_1x1_(1,1)",
  "SIG Sauer Micro Brake 7.62x51 muzzle brake_1x1_(1,1)",
  "SIG Sauer ROMEO sight mount_1x1_(1,1)",
  "SIG Sauer ROMEO4 reflex sight_1x1_(1,1)",
  "SIG Sauer ROMEO7 1x30 reflex sight (Prototype)_2x1_(1,1)",
  "SIG Sauer ROMEO7 1x30 reflex sight (Prototype)_2x1_(2,1)",
  "SIG Sauer ROMEO8T reflex sight_1x1_(1,1)",
  "SIG Sauer SRD762-QD 7.62x51 sound suppressor_2x1_(1,1)",
  "SIG Sauer SRD762-QD 7.62x51 sound suppressor_2x1_(2,1)",
  "SIG Sauer SRD762Ti 7.62x51 sound suppressor_2x1_(1,1)",
  "SIG Sauer SRD762Ti 7.62x51 sound suppressor_2x1_(2,1)",
  "SIG Sauer SRD9 9x19 sound suppressor_2x1_(1,1)",
  "SIG Sauer SRD9 9x19 sound suppressor_2x1_(2,1)",
  "SIG Sauer Taper-LOK 7.62x51@.300 BLK muzzle adapter_1x1_(1,1)",
  "SIG Sauer Two Port Brake 7.62x51 muzzle brake_1x1_(1,1)",
  "SIG Sauer Vertical Foregrip KeyMod (Black)_1x1_(1,1)",
  "SIG TANGO6T 1-6x24 30mm riflescope_2x1_(1,1)",
  "SIG TANGO6T 1-6x24 30mm riflescope_2x1_(2,1)",
  "SJ1 TGLabs combat stimulant injector_1x1_(1,1)",
  "SJ12 TGLabs combat stimulant injector_1x1_(1,1)",
  "SJ6 TGLabs combat stimulant injector_1x1_(1,1)",
  "SJ9 TGLabs combat stimulant injector_1x1_(1,1)",
  "SKS 7.62x39 10-round internal box magazine_1x1_(1,1)",
  "SKS 7.62x39 520mm barrel_3x1_(1,1)",
  "SKS 7.62x39 520mm barrel_3x1_(2,1)",
  "SKS 7.62x39 520mm barrel_3x1_(3,1)",
  "SKS 7.62x39 KCI 75-round drum magazine_2x2_(1,1)",
  "SKS 7.62x39 KCI 75-round drum magazine_2x2_(1,2)",
  "SKS 7.62x39 KCI 75-round drum magazine_2x2_(2,1)",
  "SKS 7.62x39 KCI 75-round drum magazine_2x2_(2,2)",
  "SKS 7.62x39 ProMag AALVX 35-round magazine_1x2_(1,1)",
  "SKS 7.62x39 ProMag AALVX 35-round magazine_1x2_(1,2)",
  "SKS 7.62x39 TAPCO 6610 20-round magazine_1x2_(1,1)",
  "SKS 7.62x39 TAPCO 6610 20-round magazine_1x2_(1,2)",
  "SKS ATI Monte Carlo chassis_4x1_(1,1)",
  "SKS ATI Monte Carlo chassis_4x1_(2,1)",
  "SKS ATI Monte Carlo chassis_4x1_(3,1)",
  "SKS ATI Monte Carlo chassis_4x1_(4,1)",
  "SKS ATI Monte Carlo gas tube cover_1x1_(1,1)",
  "SKS CHOATE scope mount_1x1_(1,1)",
  "SKS FAB Defense UAS buttstock_2x1_(1,1)",
  "SKS FAB Defense UAS buttstock_2x1_(2,1)",
  "SKS FAB Defense UAS chassis_3x1_(1,1)",
  "SKS FAB Defense UAS chassis_3x1_(2,1)",
  "SKS FAB Defense UAS chassis_3x1_(3,1)",
  "SKS FAB Defense UAS gas tube cover_1x1_(1,1)",
  "SKS Hexagon 7.62x39 sound suppressor_2x1_(1,1)",
  "SKS Hexagon 7.62x39 sound suppressor_2x1_(2,1)",
  "SKS Leapers UTG PRO MTU017 receiver cover mount_2x1_(1,1)",
  "SKS Leapers UTG PRO MTU017 receiver cover mount_2x1_(2,1)",
  "SKS Leapers UTG SOCOM rail mount_2x1_(1,1)",
  "SKS Leapers UTG SOCOM rail mount_2x1_(2,1)",
  "SKS TAPCO Intrafuse SAW-Style pistol grip_1x1_(1,1)",
  "SKS TAPCO Intrafuse buffer tube_2x1_(1,1)",
  "SKS TAPCO Intrafuse buffer tube_2x1_(2,1)",
  "SKS TAPCO Intrafuse chassis lower rail_1x1_(1,1)",
  "SKS TAPCO Intrafuse chassis_3x1_(1,1)",
  "SKS TAPCO Intrafuse chassis_3x1_(2,1)",
  "SKS TAPCO Intrafuse chassis_3x1_(3,1)",
  "SKS TAPCO railed gas tube cover_1x1_(1,1)",
  "SKS TOZ wooden stock (56-A-231 Sb.5)_4x1_(1,1)",
  "SKS TOZ wooden stock (56-A-231 Sb.5)_4x1_(2,1)",
  "SKS TOZ wooden stock (56-A-231 Sb.5)_4x1_(3,1)",
  "SKS TOZ wooden stock (56-A-231 Sb.5)_4x1_(4,1)",
  "SKS Weapon Tuning 7.62x39 thread adapter_1x1_(1,1)",
  "SKS dust cover_1x1_(1,1)",
  "SKS gas tube cover_1x1_(1,1)",
  "SKS gas tube_1x1_(1,1)",
  "SKS rear sight_1x1_(1,1)",
  "SKS upper band_1x1_(1,1)",
  "SKS@VZ-58 FAB Defense AG-58 pistol grip_1x1_(1,1)",
  "SLR-106@AK 5.56x45 Circle 10 30-round magazine_1x2_(1,1)",
  "SLR-106@AK 5.56x45 Circle 10 30-round magazine_1x2_(1,2)",
  "SMW car key_1x1_(1,1)",
  "SOK-12 12@76 MaxRounds Powermag 20-round magazine_2x2_(1,1)",
  "SOK-12 12@76 MaxRounds Powermag 20-round magazine_2x2_(1,2)",
  "SOK-12 12@76 MaxRounds Powermag 20-round magazine_2x2_(2,1)",
  "SOK-12 12@76 MaxRounds Powermag 20-round magazine_2x2_(2,2)",
  "SOK-12 12@76 SAI-02 10-round magazine_1x3_(1,1)",
  "SOK-12 12@76 SAI-02 10-round magazine_1x3_(1,2)",
  "SOK-12 12@76 SAI-02 10-round magazine_1x3_(1,3)",
  "SOK-12 12@76 sb.5 5-round magazine_1x2_(1,1)",
  "SOK-12 12@76 sb.5 5-round magazine_1x2_(1,2)",
  "SOK-12 AK-style stock_2x1_(1,1)",
  "SOK-12 AK-style stock_2x1_(2,1)",
  "SOK-12 Bravo-18 aluminium handguard_2x1_(1,1)",
  "SOK-12 Bravo-18 aluminium handguard_2x1_(2,1)",
  "SOK-12 CSS rear sight rail mount_1x1_(1,1)",
  "SOK-12 Custom Guns Type-340 handguard_2x1_(1,1)",
  "SOK-12 Custom Guns Type-340 handguard_2x1_(2,1)",
  "SOK-12 Leapers UTG PRO MTU002 Long Top aluminum handguard_2x1_(1,1)",
  "SOK-12 Leapers UTG PRO MTU002 Long Top aluminum handguard_2x1_(2,1)",
  "SOK-12 Leapers UTG PRO MTU002 Short Top aluminum handguard_2x1_(1,1)",
  "SOK-12 Leapers UTG PRO MTU002 Short Top aluminum handguard_2x1_(2,1)",
  "SOK-12 dust cover (Sb.0-2)_2x1_(1,1)",
  "SOK-12 dust cover (Sb.0-2)_2x1_(2,1)",
  "SOK-12 polymer handguard (Sb.7-1)_1x1_(1,1)",
  "SOK-12 rear sight_1x1_(1,1)",
  "SOK-12 thread protection tube_1x1_(1,1)",
  "SR-1MP 9x21 18-round magazine_1x1_(1,1)",
  "SR-1MP 9x21 sound suppressor_2x1_(1,1)",
  "SR-1MP 9x21 sound suppressor_2x1_(2,1)",
  "SR-1MP quad rail mount_1x1_(1,1)",
  "SR-1MP single rail mount_1x1_(1,1)",
  "SR-1MP sound suppressor mount_1x1_(1,1)",
  "SR-25 7.62x51 16 inch barrel_3x1_(1,1)",
  "SR-25 7.62x51 16 inch barrel_3x1_(2,1)",
  "SR-25 7.62x51 16 inch barrel_3x1_(3,1)",
  "SR-25 7.62x51 20 inch barrel_4x1_(1,1)",
  "SR-25 7.62x51 20 inch barrel_4x1_(2,1)",
  "SR-25 7.62x51 20 inch barrel_4x1_(3,1)",
  "SR-25 7.62x51 20 inch barrel_4x1_(4,1)",
  "SR-25 7.62x51 upper receiver_2x1_(1,1)",
  "SR-25 7.62x51 upper receiver_2x1_(2,1)",
  "SR-2M 9x21 20-round magazine_1x1_(1,1)",
  "SR-2M 9x21 30-round magazine_1x2_(1,1)",
  "SR-2M 9x21 30-round magazine_1x2_(1,2)",
  "SR-2M 9x21 hand stopper_1x1_(1,1)",
  "SR-2M 9x21 sound suppressor (SV-1381)_2x1_(1,1)",
  "SR-2M 9x21 sound suppressor (SV-1381)_2x1_(2,1)",
  "SR-2M KP-SR2 reflex sight_1x1_(1,1)",
  "SR-2M Zenit B-17 mount_1x1_(1,1)",
  "SR-2M dust cover_2x1_(1,1)",
  "SR-2M dust cover_2x1_(2,1)",
  "SR-2M pistol grip (Black)_1x1_(1,1)",
  "SR-2M pistol grip_1x1_(1,1)",
  "SR-2M polymer handguard (Black)_2x1_(1,1)",
  "SR-2M polymer handguard (Black)_2x1_(2,1)",
  "SR-2M polymer handguard_2x1_(1,1)",
  "SR-2M polymer handguard_2x1_(2,1)",
  "SR-2M short side rail_1x1_(1,1)",
  "SR-2M side rail_1x1_(1,1)",
  "SR-2M top-folding stock_2x1_(1,1)",
  "SR-2M top-folding stock_2x1_(2,1)",
  "SR-2MP top-folding stock_2x1_(1,1)",
  "SR-2MP top-folding stock_2x1_(2,1)",
  "SSD drive_1x1_(1,1)",
  "STM-9 9x19 10.5 inch barrel_2x1_(1,1)",
  "STM-9 9x19 10.5 inch barrel_2x1_(2,1)",
  "STM-9 9x19 12 inch barrel_2x1_(1,1)",
  "STM-9 9x19 12 inch barrel_2x1_(2,1)",
  "STM-9 9x19 14 inch barrel_3x1_(1,1)",
  "STM-9 9x19 14 inch barrel_3x1_(2,1)",
  "STM-9 9x19 14 inch barrel_3x1_(3,1)",
  "STM-9 9x19 16 inch barrel_3x1_(1,1)",
  "STM-9 9x19 16 inch barrel_3x1_(2,1)",
  "STM-9 9x19 16 inch barrel_3x1_(3,1)",
  "STM-9 9x19 muzzle brake_1x1_(1,1)",
  "STM-9 9x19 upper receiver_2x1_(1,1)",
  "STM-9 9x19 upper receiver_2x1_(2,1)",
  "STM-9 magwell (Grey)_1x1_(1,1)",
  "STM-9 magwell_1x1_(1,1)",
  "SV-98 7.62x54R 10-round magazine_1x1_(1,1)",
  "SV-98 7.62x54R muzzle device_1x1_(1,1)",
  "SV-98 7.62x54R sound suppressor_2x1_(1,1)",
  "SV-98 7.62x54R sound suppressor_2x1_(2,1)",
  "SV-98 7.62x54R thread adapter_1x1_(1,1)",
  "SV-98 CNC Guns OV-SV98 KeyMod handguard_2x1_(1,1)",
  "SV-98 CNC Guns OV-SV98 KeyMod handguard_2x1_(2,1)",
  "SV-98 CNC Guns OV-SV98 chassis_3x1_(1,1)",
  "SV-98 CNC Guns OV-SV98 chassis_3x1_(2,1)",
  "SV-98 CNC Guns OV-SV98 chassis_3x1_(3,1)",
  "SV-98 SRVV Mk.2.0 7.62x54R muzzle brake-compensator_1x1_(1,1)",
  "SV-98 anti-heat ribbon_1x1_(1,1)",
  "SV-98 bipod_2x1_(1,1)",
  "SV-98 bipod_2x1_(2,1)",
  "SV-98 rear sight_1x1_(1,1)",
  "SV-98 sound suppressor heat shield_1x1_(1,1)",
  "SV-98 wooden stock_4x1_(1,1)",
  "SV-98 wooden stock_4x1_(2,1)",
  "SV-98 wooden stock_4x1_(3,1)",
  "SV-98 wooden stock_4x1_(4,1)",
  "SVD 7.62x54R 10-round magazine_1x1_(1,1)",
  "SVD 7.62x54R 20-round magazine_1x2_(1,1)",
  "SVD 7.62x54R 20-round magazine_1x2_(1,2)",
  "SVD CAA DRG L-1 barrel mount rail_1x1_(1,1)",
  "SVD CAA XD RGL receiver mount_1x1_(1,1)",
  "SVD CAA XRS-DRG handguard_2x1_(1,1)",
  "SVD CAA XRS-DRG handguard_2x1_(2,1)",
  "SVD SAG MK1 chassis_3x1_(1,1)",
  "SVD SAG MK1 chassis_3x1_(2,1)",
  "SVD SAG MK1 chassis_3x1_(3,1)",
  "SVD SAG low profile sidemount_1x1_(1,1)",
  "SVD modernization kit handguard_2x1_(1,1)",
  "SVD modernization kit handguard_2x1_(2,1)",
  "SVD modernization kit top rail_1x1_(1,1)",
  "SVDS 7.62x54R 22 inch barrel_4x1_(1,1)",
  "SVDS 7.62x54R 22 inch barrel_4x1_(2,1)",
  "SVDS 7.62x54R 22 inch barrel_4x1_(3,1)",
  "SVDS 7.62x54R 22 inch barrel_4x1_(4,1)",
  "SVDS 7.62x54R muzzle brake-compensator_1x1_(1,1)",
  "SVDS Lynx Arms AK-series pistol grip adapter_1x1_(1,1)",
  "SVDS Lynx Arms Hinge buffer tube adapter_1x1_(1,1)",
  "SVDS Rotor 43 thread adapter_1x1_(1,1)",
  "SVDS custom cut dust cover_2x1_(1,1)",
  "SVDS custom cut dust cover_2x1_(2,1)",
  "SVDS dust cover_2x1_(1,1)",
  "SVDS dust cover_2x1_(2,1)",
  "SVDS front sight_1x1_(1,1)",
  "SVDS gas tube_1x1_(1,1)",
  "SVDS pistol grip_1x1_(1,1)",
  "SVDS polymer handguard_1x1_(1,1)",
  "SVDS polymer stock_2x1_(1,1)",
  "SVDS polymer stock_2x1_(2,1)",
  "SVDS rear sight_1x1_(1,1)",
  "SVDS upper band_1x1_(1,1)",
  "SVT dust cover _1x1_(1,1)",
  "SVT-40 7.62x54R 10-round magazine_1x1_(1,1)",
  "SVT-40 7.62x54R 625mm barrel_3x1_(1,1)",
  "SVT-40 7.62x54R 625mm barrel_3x1_(2,1)",
  "SVT-40 7.62x54R 625mm barrel_3x1_(3,1)",
  "SVT-40 7.62x54R muzzle brake_2x1_(1,1)",
  "SVT-40 7.62x54R muzzle brake_2x1_(2,1)",
  "SVT-40 Tokarev PU mount_1x1_(1,1)",
  "SVT-40 front sight_1x1_(1,1)",
  "SVT-40 rear sight_1x1_(1,1)",
  "Saiga-9 9x19 muzzle brake-compensator_1x1_(1,1)",
  "Saiga-9 9x19 sb.7 10-round magazine_1x2_(1,1)",
  "Saiga-9 9x19 sb.7 10-round magazine_1x2_(1,2)",
  "Saiga-9 Hartman Gyurza handguard_2x1_(1,1)",
  "Saiga-9 Hartman Gyurza handguard_2x1_(2,1)",
  "Salewa first aid kit_1x2_(1,1)",
  "Salewa first aid kit_1x2_(1,2)",
  "Salty Dog beef sausage_2x1_(1,1)",
  "Salty Dog beef sausage_2x1_(2,1)",
  "Scav figurine_1x2_(1,1)",
  "Scav figurine_1x2_(1,2)",
  "Schaman shampoo_1x2_(1,1)",
  "Schaman shampoo_1x2_(1,2)",
  "Schmidt & Bender PM II 1-8x24 30mm riflescope_2x1_(1,1)",
  "Schmidt & Bender PM II 1-8x24 30mm riflescope_2x1_(2,1)",
  "Schmidt & Bender PM II 3-12x50 34mm riflescope_2x1_(1,1)",
  "Schmidt & Bender PM II 3-12x50 34mm riflescope_2x1_(2,1)",
  "Schmidt & Bender PM II 5-25x56 34mm riflescope_2x1_(1,1)",
  "Schmidt & Bender PM II 5-25x56 34mm riflescope_2x1_(2,1)",
  "Screw nuts_1x1_(1,1)",
  "Screwdriver_1x1_(1,1)",
  "Secure Flash drive_1x1_(1,1)",
  "Secure magnetic tape cassette_1x1_(1,1)",
  "Set of files 'Master'_2x2_(1,1)",
  "Set of files 'Master'_2x2_(1,2)",
  "Set of files 'Master'_2x2_(2,1)",
  "Set of files 'Master'_2x2_(2,2)",
  "Sewing kit_1x1_(1,1)",
  "Shared bedroom marked key_1x1_(1,1)",
  "Shoreline Health Resort plan map_1x1_(1,1)",
  "Shoreline plan map_1x1_(1,1)",
  "Shturman's stash key_1x1_(1,1)",
  "Shustrilo sealing foam_1x3_(1,1)",
  "Shustrilo sealing foam_1x3_(1,2)",
  "Shustrilo sealing foam_1x3_(1,3)",
  "Signal Jammer_1x1_(1,1)",
  "SilencerCo AC-858 ASR .338 LM muzzle brake_1x1_(1,1)",
  "SilencerCo Hybrid 46 Direct Thread Mount adapter_1x1_(1,1)",
  "SilencerCo Hybrid 46 multi-caliber sound suppressor_2x1_(1,1)",
  "SilencerCo Hybrid 46 multi-caliber sound suppressor_2x1_(2,1)",
  "SilencerCo Omega 45k .45 ACP sound suppressor_2x1_(1,1)",
  "SilencerCo Omega 45k .45 ACP sound suppressor_2x1_(2,1)",
  "SilencerCo Omega 45k Direct Thread Mount adapter_1x1_(1,1)",
  "SilencerCo Omega 45k Piston Mount adapter_1x1_(1,1)",
  "SilencerCo Osprey 9 9x19 sound suppressor_2x1_(1,1)",
  "SilencerCo Osprey 9 9x19 sound suppressor_2x1_(2,1)",
  "SilencerCo Saker ASR 556 5.56x45 sound suppressor_2x1_(1,1)",
  "SilencerCo Saker ASR 556 5.56x45 sound suppressor_2x1_(2,1)",
  "SilencerCo Salvo 12 12ga sound suppressor_2x1_(1,1)",
  "SilencerCo Salvo 12 12ga sound suppressor_2x1_(2,1)",
  "SilencerCo Salvo 12 choke adapter_1x1_(1,1)",
  "SilencerCo Salvo 12 thread adapter_1x1_(1,1)",
  "Silicone tube_1x1_(1,1)",
  "Silver Badge_1x1_(1,1)",
  "Simple wallet_1x1_(1,1)",
  "Slickers chocolate bar_1x1_(1,1)",
  "Slim diary_1x1_(1,1)",
  "Smoked Chimney drain cleaner_1x2_(1,1)",
  "Smoked Chimney drain cleaner_1x2_(1,2)",
  "Soap_1x1_(1,1)",
  "Spark plug_1x1_(1,1)",
  "Stair landing key_1x1_(1,1)",
  "Stark SE-5 Express Forward foregrip (FDE)_1x1_(1,1)",
  "Stark SE-5 Express Forward foregrip_1x1_(1,1)",
  "Steiner DBAL-PL tactical device_1x1_(1,1)",
  "Steiner LAS@TAC 2 tactical flashlight_1x1_(1,1)",
  "Steyr AUG 5.56x45 10-round magazine_1x1_(1,1)",
  "Steyr AUG 5.56x45 30-round magazine_1x2_(1,1)",
  "Steyr AUG 5.56x45 30-round magazine_1x2_(1,2)",
  "Steyr AUG 5.56x45 42-round magazine_1x3_(1,1)",
  "Steyr AUG 5.56x45 42-round magazine_1x3_(1,2)",
  "Steyr AUG 5.56x45 42-round magazine_1x3_(1,3)",
  "Steyr AUG A1 5.56x45 16 inch barrel_3x1_(1,1)",
  "Steyr AUG A1 5.56x45 16 inch barrel_3x1_(2,1)",
  "Steyr AUG A1 5.56x45 16 inch barrel_3x1_(3,1)",
  "Steyr AUG A1 5.56x45 20 inch barrel_4x1_(1,1)",
  "Steyr AUG A1 5.56x45 20 inch barrel_4x1_(2,1)",
  "Steyr AUG A1 5.56x45 20 inch barrel_4x1_(3,1)",
  "Steyr AUG A1 5.56x45 20 inch barrel_4x1_(4,1)",
  "Steyr AUG A1 5.56x45 closed flash hider_1x1_(1,1)",
  "Steyr AUG A1 STG77 1.5x optic sight_2x1_(1,1)",
  "Steyr AUG A1 STG77 1.5x optic sight_2x1_(2,1)",
  "Steyr AUG A1 charging handle_1x1_(1,1)",
  "Steyr AUG A3 5.56x45 16 inch barrel_3x1_(1,1)",
  "Steyr AUG A3 5.56x45 16 inch barrel_3x1_(2,1)",
  "Steyr AUG A3 5.56x45 16 inch barrel_3x1_(3,1)",
  "Steyr AUG A3 5.56x45 closed flash hider_1x1_(1,1)",
  "Steyr AUG A3 5.56x45 muzzle brake_1x1_(1,1)",
  "Steyr AUG A3 5.56x45 receiver_2x1_(1,1)",
  "Steyr AUG A3 5.56x45 receiver_2x1_(2,1)",
  "Steyr AUG A3 M1 1.5x optic sight_2x1_(1,1)",
  "Steyr AUG A3 M1 1.5x optic sight_2x1_(2,1)",
  "Steyr AUG A3 M1 high sight mount_2x1_(1,1)",
  "Steyr AUG A3 M1 high sight mount_2x1_(2,1)",
  "Steyr AUG A3 M1 low sight mount_1x1_(1,1)",
  "Steyr AUG A3 charging handle_1x1_(1,1)",
  "Steyr AUG Ase Utra S Series SL7i 5.56x45 sound suppressor_2x1_(1,1)",
  "Steyr AUG Ase Utra S Series SL7i 5.56x45 sound suppressor_2x1_(2,1)",
  "Steyr AUG RAT Worx 5.56x45 muzzle device adapter_1x1_(1,1)",
  "Steyr AUG Relfex T4AUG Ranger 5.56x45 sound suppressor_2x1_(1,1)",
  "Steyr AUG Relfex T4AUG Ranger 5.56x45 sound suppressor_2x1_(2,1)",
  "Steyr AUG vertical foregrip_1x1_(1,1)",
  "Store manager's key_1x1_(1,1)",
  "Store safe key_1x1_(1,1)",
  "Strike Cigarettes_1x1_(1,1)",
  "Strike Industries Cobra Tactical foregrip (FDE)_1x1_(1,1)",
  "Strike Industries Cobra Tactical foregrip_1x1_(1,1)",
  "Strike Industries KeyMod 4 inch rail_1x1_(1,1)",
  "Strike Industries KeyMod 6 inch rail_1x1_(1,1)",
  "Strike Industries TRAX bridge rail_2x1_(1,1)",
  "Strike Industries TRAX bridge rail_2x1_(2,1)",
  "Supply department director's office key_1x1_(1,1)",
  "SureFire SOCOM556-MINI MONSTER 5.56x45 sound suppressor_2x1_(1,1)",
  "SureFire SOCOM556-MINI MONSTER 5.56x45 sound suppressor_2x1_(2,1)",
  "SureFire SOCOM556-MONSTER 5.56x45 sound suppressor_2x1_(1,1)",
  "SureFire SOCOM556-MONSTER 5.56x45 sound suppressor_2x1_(2,1)",
  "SureFire SOCOM556-RC2 5.56x45 sound suppressor_2x1_(1,1)",
  "SureFire SOCOM556-RC2 5.56x45 sound suppressor_2x1_(2,1)",
  "SureFire X400 Ultra tactical flashlight with laser_1x1_(1,1)",
  "SureFire XC1 tactical flashlight_1x1_(1,1)",
  "Surv12 field surgical kit_3x1_(1,1)",
  "Surv12 field surgical kit_3x1_(2,1)",
  "Surv12 field surgical kit_3x1_(3,1)",
  "SurvL Survivor Lighter_1x1_(1,1)",
  "SwampFox Trihawk Prism Scope 3x30_2x1_(1,1)",
  "SwampFox Trihawk Prism Scope 3x30_2x1_(2,1)",
  "T-7 Thermal Goggles with a Night Vision mount_2x1_(1,1)",
  "T-7 Thermal Goggles with a Night Vision mount_2x1_(2,1)",
  "T-Shaped plug_1x1_(1,1)",
  "TACCOM Carbine Brake multi-caliber muzzle brake_1x1_(1,1)",
  "TOZ-106 002 pistol grip_1x1_(1,1)",
  "TOZ-106 20ga MTs 20-01 Sb.3 2-shot magazine_1x1_(1,1)",
  "TOZ-106 20ga MTs 20-01 Sb.3 4-shot magazine_1x1_(1,1)",
  "TOZ-106 20ga MTs 20-01 Sb.3 5-shot magazine_1x1_(1,1)",
  "TOZ-106 FAB Defense GPCP cheek rest_1x1_(1,1)",
  "TOZ-106 custom cut Mosin stock_4x1_(1,1)",
  "TOZ-106 custom cut Mosin stock_4x1_(2,1)",
  "TOZ-106 custom cut Mosin stock_4x1_(3,1)",
  "TOZ-106 custom cut Mosin stock_4x1_(4,1)",
  "TOZ-106 dovetail mount_1x1_(1,1)",
  "TOZ-106 rail scope mount_1x1_(1,1)",
  "TOZ-106 stock_4x1_(1,1)",
  "TOZ-106 stock_4x1_(2,1)",
  "TOZ-106 stock_4x1_(3,1)",
  "TOZ-106 stock_4x1_(4,1)",
  "TP-200 TNT brick_1x1_(1,1)",
  "TROY QARS 3.2 inch rail_1x1_(1,1)",
  "TROY QARS 4.2 inch rail_1x1_(1,1)",
  "TT 7.62x25 116mm barrel_1x1_(1,1)",
  "TT 7.62x25 116mm gilded barrel_1x1_(1,1)",
  "TT 7.62x25 121mm homespun threaded barrel_1x1_(1,1)",
  "TT 7.62x25 makeshift sound suppressor_2x1_(1,1)",
  "TT 7.62x25 makeshift sound suppressor_2x1_(2,1)",
  "TT 7.62x25 tt-105 8-round magazine_1x1_(1,1)",
  "TT DLP Tactical Precision LAM-module_1x1_(1,1)",
  "TT Hogue-like rubber grip_1x1_(1,1)",
  "TT PM-Laser DTK-TT muzzle brake-compensator_1x1_(1,1)",
  "TT PM-Laser TT-206 side grips with laser sight_1x1_(1,1)",
  "TT Razor Arms rubber grip_1x1_(1,1)",
  "TT ornated side grips_1x1_(1,1)",
  "TT side grips_1x1_(1,1)",
  "TX-15 5.56x45 Lightweight upper receiver_2x1_(1,1)",
  "TX-15 5.56x45 Lightweight upper receiver_2x1_(2,1)",
  "Tactical Dynamics Skeletonized Foregrip_1x1_(1,1)",
  "TangoDown Stubby BGV-MK46K foregrip (Black)_1x1_(1,1)",
  "TangoDown Stubby BGV-MK46K foregrip (FDE)_1x1_(1,1)",
  "TangoDown Stubby BGV-MK46K foregrip (Stealth Grey)_1x1_(1,1)",
  "Tarbank cash register department key_1x1_(1,1)",
  "Tarcone Director's office key_1x1_(1,1)",
  "Tech manual_1x2_(1,1)",
  "Tech manual_1x2_(1,2)",
  "TerraGroup Labs access keycard_1x1_(1,1)",
  "TerraGroup Labs arsenal storage room key_1x1_(1,1)",
  "TerraGroup Labs keycard (Black)_1x1_(1,1)",
  "TerraGroup Labs keycard (Blue)_1x1_(1,1)",
  "TerraGroup Labs keycard (Green)_1x1_(1,1)",
  "TerraGroup Labs keycard (Red)_1x1_(1,1)",
  "TerraGroup Labs keycard (Violet)_1x1_(1,1)",
  "TerraGroup Labs keycard (Yellow)_1x1_(1,1)",
  "TerraGroup Labs manager's office room key_1x1_(1,1)",
  "TerraGroup Labs weapon testing area key_1x1_(1,1)",
  "TerraGroup meeting room key_1x1_(1,1)",
  "TerraGroup science office key_1x1_(1,1)",
  "TerraGroup security armory key_1x1_(1,1)",
  "Tetriz portable game console_1x2_(1,1)",
  "Tetriz portable game console_1x2_(1,2)",
  "Thunder Beast Arms Ultra 5 sound suppressor_2x1_(1,1)",
  "Thunder Beast Arms Ultra 5 sound suppressor_2x1_(2,1)",
  "Toilet paper_1x1_(1,1)",
  "Toolset_2x2_(1,1)",
  "Toolset_2x2_(1,2)",
  "Toolset_2x2_(2,1)",
  "Toolset_2x2_(2,2)",
  "Toothpaste_1x1_(1,1)",
  "Topographic survey maps_2x1_(1,1)",
  "Topographic survey maps_2x1_(2,1)",
  "Torrey Pines Logic T12W 30Hz thermal reflex sight_1x1_(1,1)",
  "Trailer park portable cabin key_1x1_(1,1)",
  "Trijicon ACOG TA01NSN 4x32 scope (Black)_2x1_(1,1)",
  "Trijicon ACOG TA01NSN 4x32 scope (Black)_2x1_(2,1)",
  "Trijicon ACOG TA01NSN 4x32 scope (Tan)_2x1_(1,1)",
  "Trijicon ACOG TA01NSN 4x32 scope (Tan)_2x1_(2,1)",
  "Trijicon ACOG TA11D 3.5x35 scope_2x1_(1,1)",
  "Trijicon ACOG TA11D 3.5x35 scope_2x1_(2,1)",
  "Trijicon ACOG backup rear sight_1x1_(1,1)",
  "Trijicon REAP-IR scope eyecup_1x1_(1,1)",
  "Trijicon REAP-IR thermal scope_2x1_(1,1)",
  "Trijicon REAP-IR thermal scope_2x1_(2,1)",
  "Trijicon RMR high profile mount_1x1_(1,1)",
  "Trijicon RMR low profile mount_1x1_(1,1)",
  "Trijicon RMR mount for ACOG scopes_1x1_(1,1)",
  "Trijicon RMR reflex sight_1x1_(1,1)",
  "Trijicon SRS-02 reflex sight_1x1_(1,1)",
  "Trijicon TA51 sight mount_1x1_(1,1)",
  "Trimadol stimulant injector_1x1_(1,1)",
  "Tromix Monster Claw 12ga muzzle brake_1x1_(1,1)",
  "Tube of Poxeram cold welding_1x1_(1,1)",
  "UHF RFID Reader_1x1_(1,1)",
  "ULTRA medical storage key_1x1_(1,1)",
  "UM Tactical UM3 pistol sight mount_1x1_(1,1)",
  "UNV DLOC-IRD sight mount_1x1_(1,1)",
  "USB Adapter_1x1_(1,1)",
  "USEC first safe key_1x1_(1,1)",
  "USEC operative figurine_1x2_(1,1)",
  "USEC operative figurine_1x2_(1,2)",
  "USEC second safe key_1x1_(1,1)",
  "USEC stash key_1x1_(1,1)",
  "UZRGM grenade fuze_1x1_(1,1)",
  "Ultrafire WF-501B flashlight_1x1_(1,1)",
  "Ultraviolet lamp_1x1_(1,1)",
  "Underground parking utility room key_1x1_(1,1)",
  "Unity Credit Bank cash register key_1x1_(1,1)",
  "Unknown key_1x1_(1,1)",
  "VAZ car key_1x1_(1,1)",
  "VOG-17 Khattabka improvised hand grenade_1x1_(1,1)",
  "VOG-25 Khattabka improvised hand grenade_1x1_(1,1)",
  "VOMZ Pilad 043-02 dovetail mount_1x1_(1,1)",
  "VOMZ Pilad 4x32 24.5mm riflescope_2x1_(1,1)",
  "VOMZ Pilad 4x32 24.5mm riflescope_2x1_(2,1)",
  "VOMZ Pilad P1x42 Weaver reflex sight_1x1_(1,1)",
  "VOMZ Pilad TargetRing reflex sight_1x1_(1,1)",
  "VPO-101 'Vepr-Hunter' 7.62x51 10-round magazine_1x1_(1,1)",
  "VPO-101 'Vepr-Hunter' 7.62x51 5-round magazine_1x1_(1,1)",
  "VPO-101 'Vepr-Hunter' Rotor 43 7.62x51 muzzle brake-compensator_2x1_(1,1)",
  "VPO-101 'Vepr-Hunter' Rotor 43 7.62x51 muzzle brake-compensator_2x1_(2,1)",
  "VPO-101 'Vepr-Hunter' SVD-style stock_4x1_(1,1)",
  "VPO-101 'Vepr-Hunter' SVD-style stock_4x1_(2,1)",
  "VPO-101 'Vepr-Hunter' SVD-style stock_4x1_(3,1)",
  "VPO-101 'Vepr-Hunter' SVD-style stock_4x1_(4,1)",
  "VPO-101 'Vepr-Hunter' dust cover_2x1_(1,1)",
  "VPO-101 'Vepr-Hunter' dust cover_2x1_(2,1)",
  "VPO-101 'Vepr-Hunter' gas tube_1x1_(1,1)",
  "VPO-101 'Vepr-Hunter' rear sight_1x1_(1,1)",
  "VPO-101 'Vepr-Hunter' stock_4x1_(1,1)",
  "VPO-101 'Vepr-Hunter' stock_4x1_(2,1)",
  "VPO-101 'Vepr-Hunter' stock_4x1_(3,1)",
  "VPO-101 'Vepr-Hunter' stock_4x1_(4,1)",
  "VPO-102 Arbalet mount_1x1_(1,1)",
  "VPO-136 'Vepr-KM' 7.62x39 muzzle brake-compensator_1x1_(1,1)",
  "VPO-136 'Vepr-KM' wooden handguard_1x1_(1,1)",
  "VPO-136 'Vepr-KM' wooden stock_2x1_(1,1)",
  "VPO-136 'Vepr-KM' wooden stock_2x1_(2,1)",
  "VPO-209 rear sight_1x1_(1,1)",
  "VPO-209 thread protector_1x1_(1,1)",
  "VPO-209 wooden handguard_1x1_(1,1)",
  "VPO-209 wooden stock_2x1_(1,1)",
  "VPO-209 wooden stock_2x1_(2,1)",
  "VPO-215 'Gornostay' .366 TKM 4-round magazine_1x1_(1,1)",
  "VPO-215 'Gornostay' .366TKM 23 inch barrel_3x1_(1,1)",
  "VPO-215 'Gornostay' .366TKM 23 inch barrel_3x1_(2,1)",
  "VPO-215 'Gornostay' .366TKM 23 inch barrel_3x1_(3,1)",
  "VPO-215 'Gornostay' scope rail mount_2x1_(1,1)",
  "VPO-215 'Gornostay' scope rail mount_2x1_(2,1)",
  "VPO-215 'Gornostay' stock_4x1_(1,1)",
  "VPO-215 'Gornostay' stock_4x1_(2,1)",
  "VPO-215 'Gornostay' stock_4x1_(3,1)",
  "VPO-215 'Gornostay' stock_4x1_(4,1)",
  "VPO-215 'Gornostay' thread protection cap_1x1_(1,1)",
  "VPX Flash Storage Module_1x1_(1,1)",
  "VSK-94 9x39 barrel_2x1_(1,1)",
  "VSK-94 9x39 barrel_2x1_(2,1)",
  "VSK-94 handguard_1x1_(1,1)",
  "VSK-94 stock_2x1_(1,1)",
  "VSK-94 stock_2x1_(2,1)",
  "VSS 9x39 6L24 10-round magazine_1x1_(1,1)",
  "VSS 9x39 6L25 20-round magazine_1x2_(1,1)",
  "VSS 9x39 6L25 20-round magazine_1x2_(1,2)",
  "VSS 9x39 integral barrel-suppressor_2x1_(1,1)",
  "VSS 9x39 integral barrel-suppressor_2x1_(2,1)",
  "VSS dust cover_2x1_(1,1)",
  "VSS dust cover_2x1_(2,1)",
  "VSS rear sight_1x1_(1,1)",
  "VSS wooden stock_2x1_(1,1)",
  "VSS wooden stock_2x1_(2,1)",
  "VSS@VAL TOZ 6P29M mount_1x1_(1,1)",
  "VSS@VAL Zenit B-3 mount combo_1x1_(1,1)",
  "VSS@VAL Zenit B-3 ring mount_1x1_(1,1)",
  "Valday Krechet reflex sight_1x1_(1,1)",
  "Valday PK-120 (1P87) holographic sight_1x1_(1,1)",
  "Valday PS-320 1@6x scope_2x1_(1,1)",
  "Valday PS-320 1@6x scope_2x1_(2,1)",
  "Vaseline balm_1x1_(1,1)",
  "Veritas guitar pick_1x1_(1,1)",
  "Video cassette with the Cyborg Killer movie_2x1_(1,1)",
  "Video cassette with the Cyborg Killer movie_2x1_(2,1)",
  "Viking Tactics UVG tactical foregrip_1x1_(1,1)",
  "Virtex programmable processor_1x1_(1,1)",
  "Vltor CASV 2 inch rail_1x1_(1,1)",
  "Vltor CASV 4 inch rail_1x1_(1,1)",
  "Vltor CASV 5 inch rail_1x1_(1,1)",
  "Vltor CASV KeyMod 2 inch rail_1x1_(1,1)",
  "Vltor CASV KeyMod 4 inch rail_1x1_(1,1)",
  "Vltor CASV KeyMod 6 inch rail_1x1_(1,1)",
  "Vortex Razor AMG UH-1 holographic sight_1x1_(1,1)",
  "Vortex Razor HD Gen.2 1-6x24 30mm riflescope_2x1_(1,1)",
  "Vortex Razor HD Gen.2 1-6x24 30mm riflescope_2x1_(2,1)",
  "WASR-10@63 CAF wooden foregrip_1x1_(1,1)",
  "WD-40 (100ml)_1x1_(1,1)",
  "WD-40 (400ml)_1x2_(1,1)",
  "WD-40 (400ml)_1x2_(1,2)",
  "WI-FI Camera_1x1_(1,1)",
  "Walther MRS reflex sight_1x1_(1,1)",
  "Water filter_1x2_(1,1)",
  "Water filter_1x2_(1,2)",
  "Water treatment plant storage room key_1x1_(1,1)",
  "Weapon parts_2x1_(1,1)",
  "Weapon parts_2x1_(2,1)",
  "Weapon safe key_1x1_(1,1)",
  "Weather station safe key_1x1_(1,1)",
  "Wilcox BOSS Xe reflex sight_1x1_(1,1)",
  "Wilcox Interface for PVS-7_1x1_(1,1)",
  "Wilcox RAPTAR ES Tactical Rangefinder_1x1_(1,1)",
  "Wilston cigarettes_1x1_(1,1)",
  "Wooden clock_2x2_(1,1)",
  "Wooden clock_2x2_(1,2)",
  "Wooden clock_2x2_(2,1)",
  "Wooden clock_2x2_(2,2)",
  "Woods plan map_1x1_(1,1)",
  "Working LCD_1x2_(1,1)",
  "Working LCD_1x2_(1,2)",
  "Wrench_1x1_(1,1)",
  "X-ray room key_1x1_(1,1)",
  "Xenomorph sealing foam_1x3_(1,1)",
  "Xenomorph sealing foam_1x3_(1,2)",
  "Xenomorph sealing foam_1x3_(1,3)",
  "Yankee Hill Annihilator multi-caliber flash hider_1x1_(1,1)",
  "Yotota car key_1x1_(1,1)",
  "ZB-014 key_1x1_(1,1)",
  "Zagustin hemostatic drug injector_1x1_(1,1)",
  "Zenit 2D flashlight_1x1_(1,1)",
  "Zenit B-13 'Klassika' dovetail rail platform_1x1_(1,1)",
  "Zenit B-13V 'Klassika' dovetail rail platform_1x1_(1,1)",
  "Zenit KR-2 old gen mount_1x1_(1,1)",
  "Zenit Klesch-2IKS IR illuminator with laser_1x1_(1,1)",
  "Zenit Klesch-2P flashlight with laser_1x1_(1,1)",
  "Zenit Klesch-2U tactical flashlight_1x1_(1,1)",
  "Zenit Perst-3 tactical device_1x1_(1,1)",
  "Zenit RK-0 tactical foregrip_1x1_(1,1)",
  "Zenit RK-1 tactical foregrip on B-25U mount_1x1_(1,1)",
  "Zenit RK-1 tactical foregrip_1x1_(1,1)",
  "Zenit RK-2 tactical foregrip_1x1_(1,1)",
  "Zenit RK-4 tactical foregrip_1x1_(1,1)",
  "Zenit RK-5 tactical foregrip_1x1_(1,1)",
  "Zenit RK-6 tactical foregrip_1x1_(1,1)",
  "Zibbo lighter_1x1_(1,1)",
  "Zmeisky 3 apartment 8 key_1x1_(1,1)",
  "Zmeisky 5 apartment 20 key_1x1_(1,1)",
  "eTG-change regenerative stimulant injector_1x1_(1,1)",
  "xTG-12 antidote injector_1x1_(1,1)",
];
export default classes;
