import React from "react";
import GridImage from "./GridImage";
const DynamicGrid = (props) => {
  const itemsImageArray = props.searchDict[props.id].itemsImageArray;

  if (!itemsImageArray || itemsImageArray.length === 0) {
    return null; // or some default content or loading indicator
  }
  // console.log("length of itemsImageArray");
  // console.log(itemsImageArray.length);
  // console.log("length of itemsImageArray[0]");
  // console.log(itemsImageArray[0].length);

  return (
    <div className="flex flex-col bg-gray">
      {itemsImageArray.map((row, rowIndex) => (
        <div key={rowIndex} className="flex flex-row">
          {row.map((cell, columnIndex) => {
            return (
              <div key={columnIndex}>
                <GridImage
                  cell={cell}
                  rowIndex={rowIndex}
                  columnIndex={columnIndex}
                  runPredictions={props.runPredictions}
                  id={props.id}
                  searchDict={props.searchDict}
                  setSearchDict={props.setSearchDict}
                  setSelectedItem={props.setSelectedItem}
                />
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default DynamicGrid;
