import { reverseFilterStringExceptions } from "./utils";
// not needed for now, but in case of the future it is possible to filter the strings going in the API request

async function getItemData(itemsArray) {
  // console.log("ITEMS ARRAY", itemsArray);
  try {
    const graphqlEndpoint = "https://api.tarkov.dev/graphql";
    const graphqlQuery = {
      query: `
              query {
                items(names: [${itemsArray
                  .map((name) => `"${reverseFilterStringExceptions(name)}"`)
                  .join(", ")}]) {
                  id
                  name
                  gridImageLink
                  avg24hPrice
                  historicalPrices{
                    price
                    priceMin
                    timestamp
                  }
                  usedInTasks {
                    id	
                    name
                    trader{
                      imageLink
                    }
                    objectives{
                      id
                      type
                      description
                      ... on TaskObjectiveItem{
                        item{
                          id
                        }
                        count
                        foundInRaid
                      }
                    }
                  }
                  craftsFor{
                    station{
                      name
                    }
                    level
                    requiredItems{
                      item{
                        name
                      }
                      count
                      attributes {
                        value
                      }
                    }
                  }
                }
              }
            `,
    };
    // console.log("REQUESTING DATA", itemsArray);
    const response = await fetch(graphqlEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(graphqlQuery),
    });

    const result = await response.json();
    console.log("RESULT", result);
    return result.data.items;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

export default getItemData;
