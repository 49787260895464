function filterStringExceptions(string) {
  //THIS ONE NOT CURRENTLY NEEDED BECAUSE API RECOGNIZES FireKlean gun lube WITHOUT THE #
  //   console.log("STRING", string);
  //The following are strings that had to be modified in order to be used as file names
  string = string === "FireKlean gun lube" ? "#FireKlean gun lube" : string;
  //app uses FireKlean gun lube, while the API returns #FireKlean gun lube
  //   console.log("FILTERED STRING", string);
  return string;
}

function reverseFilterStringExceptions(sanitizedFileName) {
  //   console.log("STRING", string);
  //The following are strings that had to be modified in order to be used as file names
  const originalFileName = sanitizedFileName
    .replace(/;/g, "*")
    .replace(/'/g, '"')
    .replace(/@/g, "/");

  //app uses FireKlean gun lube, while the API returns #FireKlean gun lube
  //   console.log("FILTERED STRING", string);
  return originalFileName;
}
function isLocalHost() {
  return window.location.hostname === "localhost";
}
export { filterStringExceptions, reverseFilterStringExceptions, isLocalHost };
