import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

const PriceChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!chartRef.current) {
      console.log("chart not initialized");
      return;
    }
    if (!data || data.length === 0) {
      console.log("data invalid");
      return;
    }
    const timeLabels = data.map((entry) => {
      const millisecondsTimestamp = Number(entry.timestamp);
      const date = new Date(millisecondsTimestamp);
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    });
    const prices = data.map((entry) => entry.price);
    const priceMin = data.map((entry) => entry.priceMin);
    // console.log("timeLabels", timeLabels);
    // console.log("prices", prices);

    const ctx = chartRef.current.getContext("2d");

    const chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: timeLabels,
        datasets: [
          {
            label: "price",
            data: prices,
            borderWidth: 1,
          },
          {
            label: "priceMin",
            data: priceMin,
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <canvas ref={chartRef} className="bg-black p-4 rounded-lg" />;
};

export default PriceChart;
