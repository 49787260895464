import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

function ImageDropzone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    props.handleImageDrop(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={`${
        isDragActive
          ? "border-dashed border-2 border-secondary"
          : "border-dashed border-2 border-primary"
      } 
      ${
        props.readyStatus ? "" : "pointer-events-none"
      } w-1/2 p-4 rounded-lg text-center cursor-pointer text-primary text-3xl`}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p>Drag 'n' drop or click to select image</p>
      )}
    </div>
  );
}
export default ImageDropzone;
