import React from "react";
import ImageDropzone from "./ImageDropzone";
// import { useDispatch } from "react-redux";

const ImageInput = (props) => {
  // const dispatch = useDispatch();
  function generateRandomId() {
    const timestamp = Date.now().toString(36); // Convert current timestamp to base36
    const randomNumber = Math.random().toString(36).substr(2, 5); // Random number, excluding "0."

    return timestamp + randomNumber;
  }
  function createNewSearch(img) {
    const newSearch = {
      originalImage: img,
      itemsImageArray: [],
      itemsPredictArray: [],
      queryResult: "",
    };
    const newId = generateRandomId();
    props.setSearchDict((prev) => ({ ...prev, [newId]: newSearch }));
  }
  const pasteImg = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      const blobOutput = await clipboardItems[0].getType("image/png");
      const data = URL.createObjectURL(blobOutput);
      const img = new Image();
      img.src = data;
      img.onload = () => {
        //each cell is about 64px x 64px
        createNewSearch(img);
      };
    } catch (e) {
      console.log(e);
    }
  };

  const handleImageDrop = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        // console.log(img.width, img.height);
        //each cell is about 64px x 64px
        createNewSearch(img);
      };
    };
    if (file) {
      //this is needed
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="ImageInput flex flex-row font-bender font-bold space-x-7">
      <button
        onClick={pasteImg}
        className="w-1/2 bg-primary rounded-lg p-2 text-white text-3xl"
        disabled={!props.readyStatus}
      >
        Paste from clipboard
      </button>

      {/* works but is ugly */}
      {/* <input
        className="border-dashed border-2 border-white"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        disabled={!props.readyStatus}
      /> */}
      <ImageDropzone
        handleImageDrop={handleImageDrop}
        readyStatus={props.readyStatus}
      />
    </div>
  );
};

export default ImageInput;
