import React, { useEffect, useState } from "react";
// import ItemCard from "./ItemCard";
import getItemData from "../API/API";
import caretUpSolid from "../images/caret-up-solid.svg";
import caretDownSolid from "../images/caret-down-solid.svg";
import { reverseFilterStringExceptions } from "../API/utils";

const ItemList = (props) => {
  const [uniqueItemsArray, setUniqueItemsArray] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  //API DATA FETCHING
  useEffect(() => {
    // useEffect for fetching data

    if (!props.searchDict[props.id].itemsPredictArray) {
      // console.log("itemsPredictArray not found");
      return;
    }
    if (props.searchDict[props.id].itemsPredictArray.length === 0) {
      // console.log("itemsPredictArray empty");
      return;
    }
    if (
      props.searchDict[props.id].itemsPredictArray.some((row) =>
        row.some((cell) => !(typeof cell === "string"))
      )
    ) {
      // console.log("itemsPredictArray not all strings");
      return;
    }
    const uniqueItems = new Set(
      props.searchDict[props.id].itemsPredictArray.flat()
    );
    console.log("uniqueItems", uniqueItems);
    const itemsArray = Array.from(uniqueItems).map((item) => item);

    const itemsPromise = getItemData(itemsArray);
    setUniqueItemsArray(itemsArray);

    // Fetch data
    const itemsDict = {};

    itemsPromise.then((items) => {
      items.forEach((item) => {
        itemsDict[reverseFilterStringExceptions(item.name)] = {
          avg24hPrice: item.avg24hPrice,
          icon: item.gridImageLink,
          historicalPrices: item.historicalPrices,
          usedInTasks: item.usedInTasks.map((task) => {
            return {
              name: task.name,
              count: task.objectives.reduce((savedValue, objective) => {
                if (!objective.item) {
                  return savedValue;
                }
                if (objective.item.id === item.id && objective.foundInRaid) {
                  return objective.count;
                }
                return savedValue;
              }, 0),
            };
          }),
          craftsFor: item.craftsFor.map((craft) => {
            return {
              station: craft.station.name,
              level: craft.level,
              requiredItems: craft.requiredItems.map((item) => {
                return {
                  name: item.item.name,
                  count: item.count,
                  isTool: item.attributes.length === 0 ? false : true,
                };
              }),
            };
          }),
        };
      });

      console.log("itemsDict", itemsDict);

      // Assuming props.setSearchDict is an asynchronous function
      props.setSearchDict((prev) => ({
        ...prev,
        [props.id]: {
          ...prev[props.id],
          itemsDict: itemsDict,
        },
      }));

      // Assuming you want to set the selected item to the first item in the array
      props.setSelectedItem(Object.keys(itemsDict)[0]);
    });
  }, [props.searchDict[props.id].itemsPredictArray]);
  //END OF API DATA FETCHING

  //SORTING BEHAVIOUR
  const toggleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedItemsArray = uniqueItemsArray.sort((a, b) => {
    if (sortColumn === "itemName") {
      return sortDirection === "asc" ? a.localeCompare(b) : b.localeCompare(a);
    } else if (sortColumn === "price") {
      const priceA = props.searchDict[props.id].itemsDict[a]?.avg24hPrice || 0;
      const priceB = props.searchDict[props.id].itemsDict[b]?.avg24hPrice || 0;
      return sortDirection === "asc" ? priceA - priceB : priceB - priceA;
    } else if (sortColumn === "FIRrequirement") {
      const firA =
        props.searchDict[props.id].itemsDict[a]?.usedInTasks
          .map((task) => task.count)
          .reduce((acc, count) => acc + count, 0) || 0;
      const firB =
        props.searchDict[props.id].itemsDict[b]?.usedInTasks
          .map((task) => task.count)
          .reduce((acc, count) => acc + count, 0) || 0;
      return sortDirection === "asc" ? firA - firB : firB - firA;
    } else if (sortColumn === "Craftable") {
      const craftA =
        props.searchDict[props.id].itemsDict[a]?.craftsFor.length || 0;
      const craftB =
        props.searchDict[props.id].itemsDict[b]?.craftsFor.length || 0;
      return sortDirection === "asc" ? craftA - craftB : craftB - craftA;
    }

    return 0;
  });
  //END OF SORTING BEHAVIOUR

  return (
    <div className="flex flex-col max-h-0 font-bender">
      <div className="sticky top-0 grid grid-cols-4 bg-background text-primary border">
        {/* START OF ItemName HEADER */}
        <div
          className="flex items-center cursor-pointer p-2"
          onClick={() => toggleSort("itemName")}
        >
          <img
            className="w-4 h-4 ml-2"
            src={
              sortColumn === "itemName"
                ? sortDirection === "asc"
                  ? caretUpSolid
                  : caretDownSolid
                : null
            }
            alt="Sort"
            style={{
              visibility: sortColumn === "itemName" ? "visible" : "hidden",
            }}
          />
          <div>Item Name</div>
        </div>
        {/* END OF ItemName HEADER */}

        {/* START OF Price HEADER */}
        <div
          className="flex items-center cursor-pointer p-2"
          onClick={() => toggleSort("price")}
        >
          <img
            className="w-4 h-4 ml-2"
            src={
              sortColumn === "price"
                ? sortDirection === "asc"
                  ? caretUpSolid
                  : caretDownSolid
                : null
            }
            alt="Sort"
            style={{
              visibility: sortColumn === "price" ? "visible" : "hidden",
            }}
          />
          <div>Price</div>
        </div>
        {/* END OF Price HEADER */}

        {/* START OF FIR Requirement HEADER */}
        <div
          className="flex items-center cursor-pointer p-2"
          onClick={() => toggleSort("FIRrequirement")}
        >
          <img
            className="w-4 h-4 ml-2"
            src={
              sortColumn === "FIRrequirement"
                ? sortDirection === "asc"
                  ? caretUpSolid
                  : caretDownSolid
                : null
            }
            alt="Sort"
            style={{
              visibility:
                sortColumn === "FIRrequirement" ? "visible" : "hidden",
            }}
          />
          <div>FIR Requirement</div>
        </div>
        {/* END OF FIR Requirement HEADER */}

        {/* START OF Craftable HEADER */}
        <div
          className="flex items-center cursor-pointer p-2"
          onClick={() => toggleSort("Craftable")}
        >
          <img
            className="w-4 h-4 ml-2"
            src={
              sortColumn === "Craftable"
                ? sortDirection === "asc"
                  ? caretUpSolid
                  : caretDownSolid
                : null
            }
            alt="Sort"
            style={{
              visibility: sortColumn === "Craftable" ? "visible" : "hidden",
            }}
          />
          <div>Craftable</div>
        </div>
        {/* END OF FIR Requirement HEADER */}

        {/* Add more headers as needed */}
      </div>
      {sortedItemsArray.map((itemName, index) => (
        <div
          key={index}
          className="flex items-center bg-background y text-secondary p-4 border border-gray-700 hover:bg-gray-700 cursor-pointer"
          onClick={() => {
            props.setSelectedItem(itemName);
          }}
        >
          <div className="w-1/2 flex flex-row">
            <img
              className="w-12 h-12 mr-4"
              src={props.searchDict[props.id].itemsDict[itemName]?.icon}
              alt={itemName}
            />
            <div>
              <h1 className="text-xl text-tertiary font-bold">{itemName}</h1>
              <div className="w-full grid grid-cols-3">
                <p className="text-gray-300">
                  ₽{" "}
                  {props.searchDict[props.id].itemsDict[
                    itemName
                  ]?.avg24hPrice?.toLocaleString()}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-around w-1/2">
            <p className="text-gray-300">
              {props.searchDict[props.id].itemsDict[itemName]?.usedInTasks
                .map((task) => task.count)
                .reduce((acc, count) => acc + count, 0) > 0 &&
                props.searchDict[props.id].itemsDict[itemName]?.usedInTasks
                  .map((task) => task.count)
                  .reduce((acc, count) => acc + count, 0)}
            </p>
            <p className="text-gray-300">
              {props.searchDict[props.id].itemsDict[itemName]?.craftsFor
                .length > 0
                ? "Yes"
                : ""}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ItemList;
