import React, { useState } from "react";
import ImagecellGenerator from "./ImageTileGenerator";
import DynamicGrid from "./DynamicGrid";
import ItemList from "./ItemList";
import ItemDetail from "./ItemDetail";
const Search = (props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  return (
    <div className="flex flex-row w-screen">
      <div className="w-1/3">
        <ImagecellGenerator
          id={props.id}
          searchDict={props.searchDict}
          setSearchDict={props.setSearchDict}
        />
        <DynamicGrid
          id={props.id}
          runPredictions={props.runPredictions}
          searchDict={props.searchDict}
          setSearchDict={props.setSearchDict}
          setSelectedItem={setSelectedItem}
        />
      </div>

      <div className="w-1/3 flex-1 overflow-y-scroll">
        <ItemList
          id={props.id}
          searchDict={props.searchDict}
          setSearchDict={props.setSearchDict}
          setSelectedItem={setSelectedItem}
        />
      </div>

      <div className="w-1/3">
        <ItemDetail
          id={props.id}
          searchDict={props.searchDict}
          selectedItem={selectedItem}
        />
      </div>
    </div>
  );
};

export default Search;
